<script >
import Swiper from "swiper";

export default {
  data(){
    return{
      keyTitle: "",
      isDialogVisible1: false, // 控制弹框的显示与隐藏
      dynamicUrl: '', // 存储动态拼接的链接
      showsearch: false,
      dialogNet:false,
      search: [
        {
          link: "gptTranslate",
          title: "工艺类GPTs",
          contend: "工艺类GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "解释类GPTs",
          contend: "解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "工艺类GPTs",
          contend: "工艺类GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "解释类GPTs",
          contend: "解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "工艺类GPTs",
          contend: "工艺类GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "解释类GPTs",
          contend: "解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持安全稳定开发"
        },
      ],
      keyWords:''
      
    }
  },
  mounted() {
    this.initSwiper();
    this.getSearch()
  },
  methods:{
    anxun(){
      window.open("http://www.oilgasgpts.com");
    },
    // 点击搜索图标时，显示弹框
    handleSearchClick() {
      console.log('点击了搜索图标');
      if (this.keyTitle.trim()) {
        this.dynamicUrl = `https://www.oilgasinfoai.com/AiPop?question=${this.keyTitle}`;
        this.isDialogVisible1 = true; // 显示弹框
      } else {
        this.$message.error('Please enter search content');
      }
    },
    // 跳转到动态链接
    redirectToDynamicUrl() {
      window.location.href = this.dynamicUrl; // 跳转到动态链接
    },
    // 关闭弹框
    closeDialog() {
      this.isDialogVisible1 = false;
    },
    upkefu(){
      window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3589007934247681&webPageTitle=在线咨询")
    },
    showNet(){
      window.open("/netFeedback")
    },
    hideNet() {
      localStorage.setItem("skip", 0)
      this.dialogNet = false;
    },
    focusScrop(){
      this.showsearch=true
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 这个属性可以让滚动有平滑的效果，如果不需要平滑效果可以去掉这个属性
      });
    },
    getSearch() {
      console.log( this.search.length)
      this.$axios
          .get("api/search/getThirdByName_IntroduceEn?keyword=" + this.keyWords, {
            // .get("http://192.168.200.50:16542/search/getThirdByName_Introduce?keyword=" + this.keyTitle, {
            headers: {
              Authorization: "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjUyNjZiN2QxLTNhOWUtNDBiOC05MGJlLTNjMTU1OGM3YmI3YiJ9.OsIDjD4UL8AZHu5j5KO-26IQwJu6uVvu59KNM18vuLmjzXMJFl-lX2vyyw_4L1AXC6wot6lpxhv_JZUVe1LokQ",
            },
          })
          .then((res) => {
            console.log("search", res.data)
            this.search = []
            var searchdata = res.data.data.thirdPageList

            searchdata.forEach((item, index) => {
              console.log(res.data.data.keywordList[0]!=="")
              if(res.data.data.keywordList[0]!==""){
                item.leighttitle = this.highlightText(item.title,  res.data.data.keywordList);
                item.leightcontend = this.highlightText(item.introduce,  res.data.data.keywordList);
                this.$set(this.search, index, {
                  link: item.url,
                  title: item.leighttitle,
                  contend: item.leightcontend,
                });
              }
              else{
                this.$set(this.search, index, {
                  link: item.url,
                  title: item.title,
                  contend: item.introduce,
                });
              }


            })
            console.log("成都", this.search.length)
          })
          .catch((err) => {
            console.log(err);
          });
    },
    highlightText(text, keywords) {
      const regex = this.createHighlightRegex(keywords);
      return text.replace(regex, (match) => `<span style="color: #eb7c31">${match}</span>`);
    },
    createHighlightRegex(keywords) {
      const escapedKeywords = keywords.map(keyword => keyword.replace(/[/\\^$*+?.()|[\]{}]/g, '\\$&'));
      const regexPattern = escapedKeywords.join('|');
      return new RegExp(regexPattern, 'gi');
    },
    routerSkip(link) {
      window.open(link, "_blank");
    },
    initSwiper() {
      this.swiper = new Swiper(".swiper-container01", {
        autoplay: 3000,
        disableOnInteraction: true,
        slidesPerView: 1,
        spaceBetween: 10,
        loop: false,
        pagination: ".swiper-pagination",
        paginationType: "bullets",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        paginationClickable: true,
        pauseOnMouseEnter: true, // 鼠标悬停时暂停自动播放

      });
    },
    recog(){
      this.$emit("recog",true)
    },
    fibernew(){
      this.$emit("fibernew",true)
    },
    eur(){
      this.$emit("eur",true)
    },

  }

}
</script>

<template>
<div class="FreeModelContainer">
  <div v-if="showsearch" class="searchnew">
        <!-- <div style="float: right;margin: 5px"><i class="el-icon-circle-close" style="font-size: 20px;font-weight:bold;color: #163eee"
                                                 @click="showsearch=false"></i></div> -->
        <!-- <div class="search">
          <el-input v-model="keyWords" style="border: none" placeholder="Please enter the GPT or content keyword you are looking for" @input="getSearch"></el-input>

          <i class="el-icon-search"
             style="margin-right: 15px;z-index: 999999999;font-size: 16px;color:gray;font-weight: bold;height: 45px;display: flex;align-items: center;justify-content: center"></i>
        </div> -->

        <!-- <div v-if="search.length>0" class="searchBack">
          <li v-for="item in search" :key="item">
            <div class="searchContent" @click="routerSkip(item.link)">
              <div class="title1" v-html="item.title"></div>
              <div class="title2" v-html="item.contend"></div>
            </div>

          </li>

        </div>
        <div v-else class="searchBackno">
          The information you are looking for about <span style="color: #eb7c31;margin: 0 0.5vw">“{{ keyWords }}”</span>has not been retrieved yet, <br>Try changing the keywords！
        </div> -->
      </div>
  <div class="title">
    Free Artificial Intelligence Model
  </div>
  <div class="title2">
    Oil & Gas Knowledge GPT
  </div>
  <div class="title3">
    <!-- <span>
      Utilize artificial intelligence technology to accurately explore solutions to complex geological and engineering problems, and develop oil and gas reservoirs efficiently.<br>
    </span> -->

    <!-- Acquire the Latest Industry Knowledge to Support Your Development -->
    Acquire Industry Expertise to Drive Your Development.
  </div>
        <!-- <div slot="reference" class="search"  >
          <div style=" display: flex;">
            <el-input v-model="keyTitle" style="border: none" placeholder="I want to quickly find the relevant services." @focus="showsearch=true"></el-input>
          <i class="el-icon-search"
             style="font-size: 14px;color:gray;font-weight: bold;height:45px;display: flex;align-items: center"></i>
      </div>
    </div> -->
  <div class="imgDesign" >
    <img src="@/assets/image.png">
    <!-- <div class="imgButton">
      <span>Experience Now</span>
    </div> -->
    <div slot="reference" class="search"  >
          <div style=" display: flex;">
            <el-input v-model="keyTitle" style="border: none" placeholder="Ask or enter the oil and gas knowledge you want to learn about." clearable="true" @focus="showsearch=true"></el-input>
          <i class="el-icon-search"
          @click="handleSearchClick"
             style="font-size: 14px;color:gray;font-weight: bold;height:45px;display: flex;align-items: center"></i>
      </div>
                   <!-- 弹出框部分 -->
                   <el-dialog :visible.sync="isDialogVisible1" width="400px" class="purple-dialog" :append-to-body="true">
                <template #title>
                  <div style="display: flex; align-items: center; justify-content: center">
          <!-- 使用警告感叹号图标 -->
          <i class="el-icon-warning" style="font-size: 8vw;  "></i>
             </div>
                  <i  @click="closeDialog"
                    style="position: absolute; top: 5px; right: 5px; cursor: pointer;"></i>
                </template>
                <div class="dialog-content">
                  <p>You are about to leave the Geological Cloud Platform and go to the Oil and Gas Large Model</p>
                  <p>Please click the link below or continue to visit：</p>
                  <a :href="dynamicUrl" target="_blank">{{ dynamicUrl }}</a>
                </div>
                <div slot="footer" class="dialog-footer" style="text-align: center;">
                  <el-button @click="redirectToDynamicUrl" >Continue to visit</el-button>
                </div>
              </el-dialog>
    </div>
    <div class="titlenew" @click="anxun()">Tech by ANVISION</div>
  </div>
  <div class="title2">
    Oil and Gas Knowledge AI Model
  </div>
  <div class="title3">
    <!-- <span>
      Unlock the Secrets of Massive Data with AI Large Models<br>
    </span> -->
    Leverage Artificial Intelligence to Maximize Data Value and Optimize Oil and Gas Reservoir Development
  </div>
  <div class="swiper-container01" style="position: relative">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
        <div class="grand1">
          <div class="imgDesign"  @click="recog" >
            <img src="../../assets/image (1).png">
            <!-- <div class="imgButton">
              <span>Experience Now</span>
            </div> -->
          </div>
          <span style="font-size: 4vw;color: rgba(11,113,214,1);" @click="upkefu">Customize Your Exclusive AI Model</span>
        </div>
      </div>
      <div class="swiper-slide">
        <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
        <div class="grand1">
          <div class="imgDesign"  @click="fibernew">
            <img src="@/assets/image (2).png">
            <!-- <div class="imgButton">
              <span>Experience Now</span>
            </div> -->
          </div>
          <span style="font-size: 4vw;color: rgba(11,113,214,1);" @click="upkefu">Customize Your Exclusive AI Model</span>
        </div>
      </div>
      <div class="swiper-slide" >
        <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
        <div class="grand1">
          <div class="imgDesign" @click="eur">
            <img src="@/assets/image (3).png">
            <!-- <div class="imgButton">
              <span>Experience Now</span>
            </div> -->
          </div>
          <span style="font-size: 4vw;color: rgba(11,113,214,1);" @click="upkefu">Customize Your Exclusive AI Model</span>
        </div>
      </div>

<!--      <div class="swiper-slide" @click="fibernew">-->
<!--        &lt;!&ndash;        <img src="@a/Q.jpeg" alt="图片2" />&ndash;&gt;-->
<!--        <div class="grand1">-->
<!--          <div class="back">-->
<!--            <div class="photo">-->
<!--              <img src="@/assets/groundHome2.png">-->
<!--              <div class="photolist">02</div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="back1">-->
<!--            <div class="title1"> 光纤漏点解释GPTS</div>-->
<!--            <div class="title2">-->
<!--              了解更多-->

<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="swiper-slide" @click="eur">-->
<!--        <div class="grand1">-->
<!--          <div class="back">-->
<!--            <div class="photo">-->
<!--              <img src="@/assets/groundHome3.png">-->
<!--              <div class="photolist">03</div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="back1">-->
<!--            <div class="title1">压裂方案参数设计优化GPTs</div>-->
<!--            <div class="title2">-->
<!--              了解更多-->

<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="swiper-slide" @click="plug">-->
<!--        &lt;!&ndash;        <img src="@a/Q.jpeg" alt="图片2" />&ndash;&gt;-->
<!--        <div class="grand1">-->
<!--          <div class="back">-->
<!--            <div class="photo">-->
<!--              <img src="@/assets/groundHome4.png">-->
<!--              <div class="photolist">04</div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="back1">-->
<!--            <div class="title1"> 智能砂堵预警GPTS</div>-->
<!--            <div class="title2">-->
<!--              了解更多-->

<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
    <div class="swiper-pagination"></div>
  </div>
</div>
</template>
<style>
/* 自定义紫色背景 */
.purple-dialog .el-dialog {
  /* background-color: #6a4c9c; */
  background-color: rgb(8 ,78 ,138,1);
  color: white; 
  border-radius: 15px ; 
}
.dialog-content {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.dialog-content p {
  margin: 10px 0;
  font-size: 16px;
  font-weight: 400;
  color:white;
}

.dialog-content a {
  display: inline-block;
  margin-top: 10px;
  color:orange ;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 400;
}

/* .purple-dialog .el-dialog__wrapper {
  background-color: rgba(106, 76, 156, 0.8) ; 
} */
</style>
<style scoped lang="scss">
.FreeModelContainer{
  .searchnew {
    background-color: #d7d7d7;
    position: absolute;
    top:0;
    left:0;
    width: 100vw;
    height: fit-content;
    z-index: 99;

    .searchBack {
      //background-color: rgba(102, 102, 102, 0.2);
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      padding: 0 15px;
      row-gap: 15px;
      list-style-type: none;
    }

    .searchBackno {
      padding: 15px 25px;
      font-weight: bold;
      text-align: center;
    }

    .searchContent {
      cursor: pointer;
      //background: #e5ebfa;
      border: white 1px solid;
      background-color: #f2f2f2;
      //box-shadow: 5px 0 14px 0px #e5ebfa;
      padding: 10px;
      height: 100%;
      border-radius: 9px;
      //color: white;

      .title1 {
        font-size: 14px;
        font-weight: bold;
        line-height: 22px;
      }

      .title2 {
        font-size: 12px;
        margin-top: 5px;
        line-height: 17px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
        overflow: hidden;
        text-overflow: ellipsis;
        height: 40px;
      }
    }

    .search {
      margin: 35px 5vw 25px 5vw;
      display: flex;
      border-radius: 9px;
      width: 90vw;
      background-color: white;

      ::v-deep .el-input {
        border-radius: 9px;
        width: 80vw;

        font-size: 14px;
      }

      ::v-deep .el-input__inner {
        border-radius: 9px;
        height: 45px !important;
        border: unset !important;
        background-color: transparent !important;
      }

    }
  }
  

  .title{
    font-size: 6vw;
    color: #084E8A;
    // letter-spacing: 1vw;
    padding: 5vh 0 0;
  }
  .title2{
    font-size: 4vw;
    color: #084E8A;
    padding: 1vh 0;
  }
  .title3{
    font-size: 3.5vw;
    line-height: 7vw;
    span{
      font-size: 5vw;
      // letter-spacing: 0.5vw;
      //font-weight: bolder;
    }
  }
  .imgDesign{
    margin: 2vh 0;
    position: relative;
    width: 100%;
    height: 37vw;
    .search {
    position: absolute;
    bottom:30px;
    z-index: 2;
    // left:50%;
    //transform: translateX(-50%);
    border-radius: 20px;
    width: 80%;
    margin-left: 10%;
    margin-top: 2vh;
    height: 45px;
    // background-color: #e9ecf4;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4); /* 添加阴影 */
    .down{
      margin: 10px auto;
      height: 35px;
      width: 35px;
      animation: up 2s infinite;

    }
    ::v-deep .el-input {
      border-radius: 9px;
      height:45px;
      width: 88%;
      font-size: 14px;
    }
    ::v-deep .el-input__inner {
      border-radius: 9px;
      height:45px !important;
      //border: white 1px solid !important;
      //background-color: transparent !important;
      background-color: transparent !important;
    }

  }
  .titlenew{
    position:absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 85%; /* 距离底部 10% */
    text-align: center;
  // color: white; /* 文字颜色，可以根据需求调整 */
  // color: #084E8A;
  // color: black;
  font-size: 15px; /* 文字大小，根据需要调整 */
  font-weight: bold; /* 文字加粗，可以根据需求调整 */
  }
    img{
      object-fit: contain;
    }
    .imgButton{
      position: absolute;
      display: flex;
      left: 0;
      right: 0;
      bottom: 2vh;
      span{
        margin: auto;
        color: white;
        padding: 0.3vh 6vw;
        border-radius: 15px;
        background-color: #123456;
      }
    }

  }
  ::v-deep .swiper-button-prev{
    background-image:url("../../assets/Group 32.png");
    background-size: contain;
    width: 7vw;
    height: 7vw;
  }
  ::v-deep .swiper-button-next{
    background-image:url("../../assets/Group 31.png");
    background-size: contain;
    width: 7vw;
    height: 7vw;
  }


}
</style>
