<template>
     <div class="serviceUs">
        <div class="image-text_1">
          <img class="image_8" referrerpolicy="no-referrer" src="@/components/home/pc/ourservice/service.png" />
          <div class="text-group_1 flex-col">
            <span class="text_8">我们的服务</span>
            <span class="text_9">OUR SERVICES </span>
          </div>
        </div>
        <div class="serviceStyle">
          <div class="text-content">
            <div class="title-top" style="text-align: left">Fine Research Services of Reservoir Geology
            </div>
            <div class="title-bottom" style="text-align: left">
              Focusing on the overall situation while zeroing in on in - depth and detailed research for individual wells. Characterized by the integration of geology and engineering, it provides customers with comprehensive geological research and evaluation, as well as the service of formulating development plans.
            </div>
            <!-- Discover More 按钮 -->
            <div class="discover-more">
              <button class="discover-btn" @click="upkefu">Learn More ></button>
            </div>
          </div>
          <div class="pro2" style="margin-top: 4vh">
            <div class="grand1" @click="comment">
              <div class="back">
                <div class="photo">
                  <img src="@/components/home/pc/ourservice/service4.png">
                  <!-- <div class="photohover"></div> -->
                  <div class="title6" > Reservoir Geological <br>Evaluation Service</div>
                </div>
              </div>
              <div class="back1">
                <!-- <div class="title6" @click="comment"> 油气藏综合地质评价服务</div> -->

              </div>
            </div>
            <div class="grand1"  @click="exploit">
              <div class="back">
                <div class="photo">
                  <img src="@/components/home/pc/ourservice/service1.png">
                  <!-- <div class="photohover"></div> -->
                  <div class="title6"> Development Plan <br>Design Service</div>
                </div>
              </div>
              <div class="back1">
                <!-- <div class="title3" @click="exploit"> 开发方案设计服务</div> -->
              </div>
            </div>
            <div class="grand1"  @click="openNew('https://www.ogdri.com/naturalfrac')">
              <div class="back">
                <div class="photo">
                  <img src="@/components/home/pc/ourservice/service2.png">
                  <!-- <div class="photohover"></div> -->
                  <div class="title6" > Natural Fracture <br> Prediction Technical</div>
                </div>
              </div>
              <div class="back1">
                <!-- <div class="title4" @click="naturalfrac"> 天然裂缝预测技术服务</div> -->
              </div>
            </div>
            <div class="grand1"  @click="openNew('https://www.ogdri.com/geomechanics')">
              <div class="back">
                <div class="photo">
                  <img src="@/components/home/pc/ourservice/service3.png">
                  <!-- <div class="photohover"></div> -->
                  <div class="title6">Geomechanics Research <br> Technical Service</div>
                </div>
              </div>
              <div class="back1">
                <!-- <div class="title5" @click="geomechanics">地质力学研究技术服务</div> -->
              </div>
            </div>
            <div class="grand1" @click="thinreservoir">
              <div class="back">
                <div class="photo">
                  <img src="@/components/home/pc/ourservice/service5.png">
                  <!-- <div class="photohover"></div> -->
                  <div class="title6" >3D Geomechanics <br> Modeling Service</div>
                </div>
              </div>
              <div class="back1">
                <!-- <div class="title5" @click="thinreservoir">三维地质力学建模服务</div> -->
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex;gap:2%">
          <div class="serviceStyle gap1">
            <div class="text-content" >
              <div class="title-top"  style="text-align: left">Integrated production enhance<br>service & tech support
              </div>
              <div class="title-bottom" style="text-align: left">
                You can achieve the goal of increasing production by implementing precise engineering technologies throughout the entire process of project research and engineering implementation, thereby obtaining the highest return on investment.
                <!-- <span  @click="clickConsult('地质工程云平台')" style="color: #15257b; cursor: pointer;">点击咨询</span>。 -->
              </div>
              <!-- Discover More 按钮 -->
              <div class="discover-more">
                <button class="discover-btn" @click="upkefu">Learn More ></button>
              </div>
            </div>
            <div class="imgStyle" style="cursor: pointer">
             <img src="@/components/home/pc/ourservice/service6.png" @click="yitihua()">
              <div class="titleStyle">
                <div class="title-top"  style="color:white">Integrated Oilfield Service
                </div>
                <div class="title-bottom" style="color:white;text-align: left">
                  Jointly innovate with customers. Leverage precise engineering and lean management for integrated solutions, delivering Progress for customers.
                  <!-- <span  @click="clickConsult('地质工程云平台')" style="color: #15257b; cursor: pointer;">点击咨询</span>。 -->
                </div>

              </div>
                </div>
          </div>
          <div class="gap2">
            <div class="serviceStyle" style="margin-top: 4vh">
              <div class="title-top"  style="text-align: left">Technical Service Integrating Geology and Engineering
              </div>
              <div class="title-bottom" style="text-align: left">
                You can obtain tailor-made engineering geological technical services. By combining engineering services with geology, the achievements of geological research are fully applied to guide the implementation of engineering plans.
                <!-- <span  @click="clickConsult('地质工程云平台')" style="color: #15257b; cursor: pointer;">点击咨询</span>。 -->
              </div>
              <div class="discover-more">
                <button class="discover-btn" @click="upkefu">Learn More ></button>
              </div>
              <div style="display: flex">
                <div class="photocontent1" @click="intelligent">
                  <span  class="title6">Intelligent Engineering Iterative <br>Optimization Technology</span>
                  <img src="@/components/home/pc/ourservice/service7.png">
                </div>
                <div class="photocontent1" @click="connect" style="margin-left: 1vw;">
                  <span  class="title6">Geology - engineering integrated old <br>well potential - tapping tech series</span>
                  <img src="@/components/home/pc/ourservice/service8.png">
                </div>

              </div>
             </div>
            <div class="serviceStyle" style="margin-top: 4vh">
              <div class="text-content">
                <div class="title-top" style="text-align: left">Oil - gas reservoir production - enhancement technical service
                </div>
                <div class="title-bottom" style="text-align: left">
                  You can obtain integrated production enhancement services and production enhancement risk services, which center around the integrated design of geology and engineering solutions and the integration of characteristic product technologies.
                  <!-- <span  @click="clickConsult('地质工程云平台')" style="color: #15257b; cursor: pointer;">点击咨询</span>。 -->
                </div>
                <!-- Discover More 按钮 -->
                <div class="discover-more">
                  <button class="discover-btn" @click="upkefu">Learn More ></button>
                </div>
              </div>
              <div style="display: flex">
                <div class="photocontent1" @click="single" >
                  <span class="title6">Integrated production - enhancement <br>service & tech support</span>
                  <img src="@/components/home/pc/ourservice/service9.png">
                </div>
                <div class="photocontent1"  style="margin-left: 1vw;" @click="integration">
                  <span  class="title6">Characteristic Production <br> Enhancement Products</span>
                  <img src="@/components/home/pc/ourservice/service10.png">
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="serviceStyle gap2" style="margin-top: 4vh;width: 100%">
          <div class="text-content">
            <div class="title-top" style="text-align: left">Dynamic Monitoring Service for Oil and Gas Field Development</div>
            <div class="title-bottom" style="text-align: left">
              You can get fiber - optic - featured reservoir geological evaluation and development engineering monitoring services covering drilling, completion and production. These services enable fine evaluation and precise engineering control in oil and gas development.
            </div>
            <div class="discover-more">
              <button class="discover-btn" @click="upkefu">Learn More ></button>
            </div>
          </div>
          <div style="display: flex;gap:1vw">
            <div class="photocontent1 "  @click="distributevue">
              <span  class="title6">Distributed Fiber Optic Real-time <br>Monitoring Technology</span>
              <img src="@/components/home/pc/ourservice/service11.png">
              <!-- <img @click="distributevue" src="@/assets/new/Group3.png"> -->
            </div>
            <div class="photocontent1" @click="gasreservoir">
              <span  class="title6">Oil and Gas Reservoir Logging Technology</span>
              <img src="@/components/home/pc/ourservice/service12.png">
            </div>
            <div class="photocontent1" @click="number">
              <span  class="title6">Digital Rock and Rock Cuttings</span>
              <img src="@/components/home/pc/ourservice/service13.png">
              <!-- <img @click="number"  src="@/assets/new/Group4.png"> -->
            </div>
          </div>


        </div>
      </div>
</template>

<script>
export default {
    name: 'OurServices',
    data(){
        return{

        }
    },
methods:{
    comment(){
      this.$emit("comment",true)
    },
    upkefu(){
      this.$emit("upkefu",true)
    },
    exploit(){
      this.$emit("exploit",true)
    },
    naturalfrac(){
      this.$emit("naturalfrac",true)
    },
    geomechanics(){
      this.$emit("geomechanics",true)
    },
    // thinreservoir(){
    //   this.$emit("ethinreservoir",true)
    // },
    intelligent(){
      this.$emit("intelligent",true)
    },
    // single(){
    //   this.$emit("singlet",true)
    // },
    integration(){
      this.$emit("integration",true)
    },
    distributevue(){
      this.$emit("distributevue",true)
    },
    gasreservoir(){
      this.$emit("gasreservoir",true)
    },
    number(){
      this.$emit("number",true)
    },
    connect(){
      this.$emit("connect",true)
    },
    yitihua(){
      window.open("https://www.ogdri.com/increase")
    },
    openNew(item){
      window.open(item, "_blank");
    },
    single() {
      window.open("/integratedstimulation", "_blank");
    },
    thinreservoir(){
      window.open("/thinreservoir", "_blank");
    },

}
}
</script>

<style lang="scss" scoped>
  .image-text_1 {
    display: flex;
    margin-bottom: 3vh;
    .image_8 {
      width: 3.5vw;
      height: 3.5vw;
    }

    .text-group_1 {
      position: relative;
      width: fit-content;
      height: 4vw;
      margin-left: 0.5vw;
    }

    .text_8 {
      height:4vw;
      overflow-wrap: break-word;
      color: rgba(118, 118, 118, 0.2);
      font-size: 1.8vw;
      text-transform: uppercase;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 700;
      text-align: center;
      white-space: nowrap;
      line-height: 5vw;

    }

    .text_9 {

      position: absolute;
      left: 0;
      top: 0;
      height: 4vw;
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 1.8vw;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 600;
      text-align: center;
      white-space: nowrap;
    }
  }
  .title-top{
    font-size: 1.5vw;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #333333;
    text-align: center;
    font-style: normal;
    text-transform: none;
    white-space: nowrap;
  }
  .title-bottom{
    font-size: 1.2vw;
    margin-top: 2vh;
    line-height: 4vh;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #909399;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .serviceUs {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 5vh 10vw 0 10vw;
    background-position: center;
    background-size: cover;
    background-color: white;
    .intro2{
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 1.5vw;
      color: #333333;
      line-height: 2vh;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
    .serviceStyle {
      margin-top: 5vh;
      padding:5vh 2vw 3vh 2vw;
      column-gap: 0.5vw;
      // overflow: hidden;
      width:100%;
      background: #F5F5F5;
      //border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
      .discover-more {
        margin-top: 2vh;

        .discover-btn {
          background: none;
          border: none;
          color: #3366F2;
          // color: #084E8A;
          font-size: 1.1vw;
          font-weight: 500;
          padding: 0;
          cursor: pointer;
          transition: color 0.3s;

          &:hover {
            color: darken(#3366F2, 20%);
          }
        }
      }
      .text-content {
        text-align: left;

      }

      .imgStyle{
        width: 100%;
        height: fit-content;
        margin:auto auto 0 auto;
        position: relative;
        .titleStyle{
          position: absolute;
          top:0;
          color:white;
          padding: 5vh 2vw;

        }
      }
      .pro2 {
        width:100%;
        height: fit-content;
        overflow-x: hidden;
        display: flex;
        column-gap: 1vw;

        .grand1 {
          cursor: pointer;
          width: 100%;
          opacity: 1;
          position: relative;
          height: fit-content;
          .back {
            width: 100%;
            margin:0 auto 0 0;
            height:fit-content;
            // border-radius:6px;
            // display: flex;
          }

          .back1 {
            width: 100%;
            height:fit-content;
            // margin-top:2vh;
            border-top: none;
            // border-radius:6px;
            padding: 1vh 0;
            justify-content: space-between;
          }

          .photo {
            //background-image: url("../assets/pro1.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            display: flex;
            // display: inline-block;
            height: fit-content;
            // border-top-right-radius: 9px;
            // border-top-left-radius: 9px;
            position: relative;
          }
          .photo img{
            width: 100%; /* 设置图片的宽度为父容器的宽度，也可以调整为固定宽度 */
            height: auto; /* 保持图片比例 */
          }
          .photohover{
            position: absolute;
            bottom: 0;
            left: 0; /* 初始位置在容器左侧外面 */
            width: 0%; /* 初始宽度为0 */
            height: 2vh;
            background-color: #2f6dfd;
            transition: width 0.5s ease; /* 平滑过渡效果 */
          }
          //.photolist {
          //  font-size: 5vw;
          //  color: #e3e8ed;
          //  margin:auto 1vw 0 2vw;
          //  height: 100%;
          //  width: fit-content;
          //  font-weight: bold;
          //}

          .title1 {
            text-align: left;
            font-size: 1.5vw;
            font-family: Roboto, sans-serif;
            font-weight: bold;
            overflow: hidden;
            height: fit-content;
            //line-height: 35px;
            margin-top:-3vh;
          }


        }
        .grand1:hover{
          .photohover {
            width: 100%; /* 鼠标悬浮时宽度变为100% */
          }
        }
      }

    }
    .title6 {
      position: absolute;
      color: #FFFFFF;
      text-shadow: 0px 1px 2px rgba(0,0,0,0.5);
      font-style: normal;
      transform: translateX(-50%);
      left:50%;
      bottom:2vh; /* 距离图片底部20px */
      text-align: center;
      font-size: 1.1vw;
      font-family: Roboto, sans-serif;
      font-weight: 400;
      overflow: hidden;
      height: fit-content;
      //line-height: 35px;
      white-space: nowrap; /* 防止文字换行 */
      cursor: pointer; /* 鼠标悬停时显示为手指形状 */
      // transition: color 0.3s ease, transform 0.3s ease; /* 平滑的颜色变化和变换效果 */
    }
    /* 鼠标悬浮时的效果 */
    .title6:hover {
      font-weight: 600;
      /* 悬浮时的颜色 */
      // transform: scale(1.25);
      /* 轻微放大效果 */
    }
    .gap1{
      width: 35%;
      margin-top: 4vh;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .text-content {
        width:100%;

      }
    }
    .gap2{
      width: 63%;
      .photocontent{
        display: flex;
        border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
        border-radius: 9px;
      }
      .photocontent1{
        width: 100%;
        cursor: pointer;
        position: relative;
        text-align: center; /* 让文字居中 */
        margin-top:3vh;
      }
      .photocontent1 img{
        // width: 22vw;
        // height: 33vh;
        width: 100%;
        height: fit-content;
      }



    }

    .pro3 {
      //margin-top: -4vh;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 2vh;

      .grand2 {
        cursor: pointer;
        width: 100%;

        height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          position: relative;
          height: 40vh;
          background: #d4e4ff;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          //border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          opacity: 1.25;
          margin: auto;
          background-color: white;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 30vh;
        }


        .title1 {

          opacity: 4;
          margin-top: 3vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.2vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          color: #2168DB;
          line-height: 3.5vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.9vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.9vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }

      }


    }



  }
</style>