<template>
        <div class="contactUs">
        <div class="image-text_1">
          <img class="image_8" referrerpolicy="no-referrer" src="@/components/home/pc/ourservice/contact.png" />
          <div class="text-group_1 flex-col">
            <span class="text_8">联系我们</span>
            <span class="text_9">CONTACT US </span>
          </div>
        </div>
        <div style="display: grid;gap:2vw; grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);">
          <div class="group_14" @click="upkefu2" style="cursor: pointer">
            <div class="image-text_20">
              <img
                  class="image_13"
                  referrerpolicy="no-referrer"
                  src="@/components/home/pc/ourservice/contact2.png"
              />
              <div class="text-group_23 flex-col justify-between">
                <span class="text_66">Online Customer Service</span>
                <div class="text-wrapper_26">
                  <span class="paragraph_10"
                  >If you need assistance, click to contact our online human customer service,which will respond quickly within 10 seconds and provide answers for you.</span>
                </div>
              </div>
            </div>
          </div>
          <div class="group_14">
            <div class="image-text_20">
              <img
                  class="image_13"
                  referrerpolicy="no-referrer"
                  src="@/components/home/pc/ourservice/contact3.png"
              />
              <div class="text-group_23 flex-col justify-between">
                <span class="text_66">Email Address</span>
                <div class="text-wrapper_26">
                  <span class="paragraph_10"
                  >OGDRI@antonoil.com</span
                  >
                    </div>
              </div>
            </div>
          </div>

        </div>
      </div>
</template>

<script>
export default {
    name: "Contactus",
    methods:{
        upkefu2() {
      // window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3452726083343114&webPageTitle=在线咨询")
    window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3588905289569767&webPageTitle=在线咨询")
    },
    }
}
</script>

<style lang="scss" scoped>
  .image-text_1 {
    display: flex;
    margin-bottom: 3vh;
    .image_8 {
      width: 3.5vw;
      height: 3.5vw;
    }

    .text-group_1 {
      position: relative;
      width: fit-content;
      height: 4vw;
      margin-left: 0.5vw;
    }

    .text_8 {
      height:4vw;
      overflow-wrap: break-word;
      color: rgba(118, 118, 118, 0.2);
      font-size: 1.8vw;
      text-transform: uppercase;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 700;
      text-align: center;
      white-space: nowrap;
      line-height: 5vw;

    }

    .text_9 {

      position: absolute;
      left: 0;
      top: 0;
      height: 4vw;
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 1.8vw;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 600;
      text-align: center;
      white-space: nowrap;
    }
  }
  .title-top{
    font-size: 1.5vw;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #333333;
    text-align: center;
    font-style: normal;
    text-transform: none;
    white-space: nowrap;
  }
  .title-bottom{
    font-size: 1.2vw;
    margin-top: 2vh;
    line-height: 4vh;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #909399;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .contactUs {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 5vh 10vw 5vh 10vw;
    background-position: center;
    background-size: cover;
    background-color: white;
    .group_14 {
      margin-top: 1vh;
      background-color: rgba(244, 248, 251, 1);
      border-radius: 2px;
      width: 100%;
      padding: 4vh 1vw;
    }

    .image-text_20 {
      width: 100%;
      display: flex;
    }

    .image_13 {
      width: 3vw;
      height:3vw;
      margin-right: 2vw;
    }

    .text_66 {
      overflow-wrap: break-word;
      color: rgba(51, 102, 242, 1);
      font-size: 1.2vw;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      white-space: nowrap;
      line-height: 3vh;
    }

    .text-wrapper_26 {
      overflow-wrap: break-word;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      line-height: 3vh;
      margin-top: 3vh;
    }

    .paragraph_10 {
      overflow-wrap: break-word;
      color: rgba(96, 98, 102, 1);
      font-size: 1vw;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      line-height: 4vh;
    }

    .text_67 {
      overflow-wrap: break-word;
      color: rgba(51, 102, 242, 1);
      font-size: 1vw;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      line-height: 4vh;
    }

    .paragraph_11 {
      width: 224px;
      height: 90px;
      overflow-wrap: break-word;
      color: rgba(96, 98, 102, 1);
      font-size: 1vw;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      line-height: 4vh;
    }

    .text_68 {
      overflow-wrap: break-word;
      color: rgba(51, 102, 242, 1);
      font-size: 1vw;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      line-height: 4vh;
    }

  }
</style>