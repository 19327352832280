
<template>
  <div class="research-section">
    <div class="title">Our Research</div>
    <div class="titlenew">
      <!-- Deep integration of engineering geology, directly addressing the challenges of oil and gas field development, with precise strategies for increasing production and efficiency. -->
      In-depth Integration of Engineering Geology to Address Oil and Gas Development Challenges.
    </div>
    <div class="swiper-container-research">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in researchList" :key="item.id">
          <div class="research-content">
            <div class="image-container">
              <img :src="item.image" :alt="item.title">
            </div>
            <div class="text-content">
              <div class="research-title">{{ item.title }}</div>
              <div class="research-desc">{{ item.description }}</div>
              <!-- <div class="more-link" @click="goToDetail(item.link)"> -->
                <div class="more-link" @click="upkefu">
                <!-- Learn More > -->
                Customize your exclusive research topic
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="research-button-prev">
        <img src="@/assets/home/research/prev-arrow.png" alt="previous">
      </div>
      <div class="research-button-next">
        <img src="@/assets/home/research/next-arrow.png" alt="next">
      </div>

      <div class="swiper-pagination-research"></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.css'
import unconventionalStudyImg from '../../../assets/home/research/unconventional-study.png'
import wellRecoveryImg from '../../../assets/home/research/well-recovery.png'
import quartziteTransformImg from '../../../assets/home/research/quartzite-transform.png'

export default {
  name: 'ResearchSection',

  data() {
    return {
      researchList: [
        {
          id: 1,
          title: "Fine geological study of unconventional oil",
          // description: "Deepen reservoir understanding in a multi-dimensional and holistic manner, and form an overall solution for reservoir development that is interlinked and dynamically integrated with 'points, lines, surfaces and bodies', so as to realise high-efficiency development of the reservoir.",
          image: unconventionalStudyImg,
          link: "/about"
        },
        {
          id: 2,
          title: "Technologies for the recovery and increase of production in old wells",
          // description: "With the aim of enhancing production, we accompany our customers in the later stages of the life of their oil, gas and water injection wells.",
          image: wellRecoveryImg,
          link: "/about"
        },
        {
          id: 3,
          title: "High-efficiency transformation of deep-seated quartzite",
          // description: "Overcome the difficulties of complex deep shale gas structure and difficult construction, maximise the control of engineering risks and enhance the transformation effect.",
          image: quartziteTransformImg,
          link: "/about"
        }
      ],
      researchSwiper: null
    }
  },

  methods: {
    upkefu(){
      window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3589007934247681&webPageTitle=在线咨询")
    },
    initSwiper() {
  this.$nextTick(() => {
    this.researchSwiper = new Swiper(".swiper-container-research", {
      autoplay: 4000,
      disableOnInteraction: true,
      slidesPerView: 1,
      spaceBetween: 10,
      loop: true,
      speed: 1000,
      // effect: 'fade',
      // fadeEffect: {
      //   crossFade: true
      // },
      pagination: ".swiper-pagination-research",
      paginationType: "bullets",
      paginationClickable: true,
      pauseOnMouseEnter: true,
      // 修改导航按钮配置
      nextButton: '.research-button-next',  
      prevButton: '.research-button-prev'   
    });
  });
},

    goToDetail(link) {
      this.$router.push(link);
    }
  },

  mounted() {
    this.initSwiper();
  },

  beforeDestroy() {
    if (this.researchSwiper) {
      this.researchSwiper.destroy(true, true);
    }
  }
}
</script>

<style lang="scss" scoped>
.research-section {
  width: 100vw;
  padding:50px 0px 60px;
  background-image: url('~@/assets/home/research/research-bg.png');
  background-size: cover;
  background-position: center;
  font-family: Sarial, sans-serif, "Microsoft Yahei" !important;
  .title {
    padding-left: 10px;
    font-size: 22px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 20px;
    // text-align: center;
  }
  .titlenew{
  color: white;
    font-size: 4vw;
    padding: 0 10px 4vh 10px;
}
  .swiper-container-research {
    position: relative;
    width: 100%;

    .swiper-slide {
      height: auto;
    }

    .research-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      border: 1px solid #E1E1E1;
      background: #FFF;
      .image-container {
        width: 100%;
        // border-radius: 8px 8px 0 0;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .text-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 15px;
        // background: rgba(255, 255, 255, 0.9);
        // border-radius: 0 0 8px 8px;

        .research-title {
          font-size: 18px;
          // font-weight: bold;
          color: #555;
          line-height: 22px;
          margin-bottom: 15px;
          height: 44px;
          // display: -webkit-box;
          // -webkit-box-orient: vertical;
          // -webkit-line-clamp: 2;
          // overflow: hidden;
        }

        .research-desc {
          flex: 1;
          color: #898989;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; 
          margin-bottom: 10px;
          // display: -webkit-box;
          // -webkit-box-orient: vertical;
          // -webkit-line-clamp: 3;
          // overflow: hidden;
        }

        .more-link {
          color:#0B71D6;
          font-size: 16px;
          margin-top: auto;
          margin-bottom: 15px;
        }
      }
    }

    .research-button-prev,
    .research-button-next {
      position: absolute;
      top: calc(50% - 28%); 
      transform: translateY(-50%);
      width: 28px;
      height: 28px;
      // background: rgba(0, 0, 0, 0.5);
      // border-radius: 50%;
      z-index: 999;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      cursor: pointer;

      img {
        width: 100%; 
        height: 100%;
        object-fit: contain;
      }

      // &:hover {
      //   background: rgba(0, 0, 0, 0.7);
      // }
    }

    .research-button-prev {
      left: 15px;
    }

    .research-button-next {
      right: 15px;
    }

   .swiper-pagination-research {
      position: absolute;
      bottom: -25px;  
      width: 100%;
      text-align: center;

      .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        background-color: #ffffff;
        opacity: 0.5;
        margin: 0 4px;

        &-active {
          opacity: 1;
          background: #FFFFFF !important;
        }
      }
    }
  }
}

</style>
<style lang="scss">
 //写出来真的不容易！真的别写全局的样式！！！
.swiper-container-research {
  .swiper-pagination-research {
    .swiper-pagination-bullet {
      width: 8px !important;
      height: 8px !important;
      background-color: #ffffff !important;
      opacity: 0.5 !important;
      margin: 0 4px !important;
    }

    .swiper-pagination-bullet-active {
      opacity: 1 !important;
      background-color: #FFFFFF !important;
    }
  }
}
</style>