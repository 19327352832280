<template>
  <div>
    <div v-if="model===false">
      <div ref="total" class="total">
        <!-- <kefu @uptop="uptop"  @showProduct="showProduct" ></kefu> -->
        <kefuPc @uptop="uptop" @showProduct="showProduct"></kefuPc>
        <div class="screen1">
          <video id="myVideo" ref="myVideo" autoplay height="100%" loop muted playsinline
            style="object-fit: cover;position:absolute;top:0;left:0; z-index: -1" width="100%">
            <source src="@/video/zuixin1.mp4" type="video/mp4">
          </video>

          <div class="topback">
            <headtip @showDialog="showPartner" @showskip="scrollskip"></headtip>
          </div>

          <!--      logo  you气通GPT社区 合作伙伴  登录-->
          <!-- <div v-if="scrollTrue===false" class="up" @click="uptop"></div> -->
          <transition appear name="run">
            <div class="layer2" style="z-index: 999999999999">
              <!-- <div class="title1">Geological Engineering<br> Cloud Platform
                <div class="title2">
                  Real time cross regional and cross professional linkage of relevant personnel through cloud services
                </div>
              </div> -->
              <div>
                <!-- 标题 -->
                <div class="title1">Cloud-Powered Research <br> Institute at Your Service
                  <!-- <div class="titlenew1">Embark on the Journey of Oil and Gas Development for Increased Production and Efficiency</div> -->
                  <!-- <div class="titlenew"> Precision &nbsp;/ &nbsp;   Accuracy &nbsp;/ &nbsp;    Intelligence</div> -->
                </div>
                <!-- 图标与文字 -->
                <!-- <div class="titlenew-content" @click="position6"> -->
                <!-- <div class="titlenew-content" >
              <div class="content-item" style="margin-left: 16vw;" @click="position6">
              <div class="Img"><img src="@/assets/new/new3.png"/></div>
              <div class="Img-title">Global Online Expert Support Services</div>
           </div>
           <div class="vertical-line"></div>
              <div class="content-item1" style="margin-right: 16vw;" @click="scrollToModule" >
              <div class="Img"><img src="@/assets/new/new4.png"/></div>
              <div class="Img-title" >Precise Engineering Technical Services</div>
            </div>
            </div> -->
                <!-- 合作咨询按钮 -->
                <div class="hezuo">
                  <div class="titlenew1" @click="dialogVisible=true;">
                    Collaboration Advisory 
                  </div>
                  <!-- <div class="titlenew2" @click="about" style="margin-top:3vw;margin-right:13vw;color:white;font-family: Roboto, sans-serif;
        text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.7); font-weight: bold;font-size: 1.2vw">
              Know More
              </div> -->
                </div>
              </div>

              <!-- <div class="title31" @click="position6">
                <div class="hoverImg"><img src="@/assets/img02.png"/></div>
                <div class="hoverTitleup">Global Online Expert Support Services</div>
                             <div class="hoverTitleup2">  Integrated shared cloud service for global service outlets and back-end technical staff of china unicom.
                             </div>

              </div>
              <div class="title31" style="margin-top: 0" @click="position2">
                <div class="hoverImg"><img src="@/assets/img01.png"/></div>
                <div v-sliden-in="{duration:800}" v-zoom-in="{duration:800}" class="hoverTitleup">Geological Engineering
                  GPTs
                </div>
                             <div class="hoverTitleup2">  Free online artificial intelligence application models for reservoir geology and development engineering for users.
                             </div>
              </div>
              <div class="title31" style="margin-top: 0" @click="position7">
                <div class="hoverImg"><img src="@/assets/img03.png"/></div>
                <div class="hoverTitleup">Oil & Gas Field Development Technical</div>
                             <div class="hoverTitleup2">    Global Oil & Gas development customers for the future of sustainable development, Anton provides a full range of solutions.
                             </div>
              </div> -->
            </div>
          </transition>
          <!--      平台大图-->
          <div class="layer3">
          </div>
        </div>
             <!-- 关于我们的模块 以下是重新写的-->
             <div class="aboutUs">
        <div class="image-text_1">
          <img class="image_8" referrerpolicy="no-referrer" src="../assets/home/us.png" />
          <div class="text-group_1 flex-col">
            <span class="text_8">关于&nbsp;我们</span>
            <span class="text_9">About Us</span>
          </div>
        </div>
        <div class="intro1">
          <div class="intro_text_1">
            <div class="intro_text_2">One-Stop Solutions for Reservoir Geological Research and Oil & Gas Development, Exceed Your Vision</div>
            <div class="intro_text_3">
              <div class="image-text_2 flex-row justify-between">
                <img
                    class="label_5"
                    referrerpolicy="no-referrer"
                    src="../assets/home/img1.png"
                />
                <span class="text-group_2">Integrate with <br>Geological Engineer</span>
              </div>
              <div class="image-text_2 flex-row justify-between">
                <img
                    class="label_5"
                    referrerpolicy="no-referrer"
                    src="../assets/home/img3.png"
                />
                <span class="text-group_2">Precision Geological <br>Research</span>
              </div>
              <div class="image-text_2 flex-row justify-between">
                <img
                    class="label_5"
                    referrerpolicy="no-referrer"
                    src="../assets/home/img3.png"
                />
                <span class="text-group_2">Profession Exploration<br>and Develop Team</span>
              </div>

              <div class="image-text_2 flex-row justify-between">
                <img
                    class="label_5"
                    referrerpolicy="no-referrer"
                    src="../assets/home/img4.png"
                />
                <span class="text-group_2">Large Artificial <br>Intelligence Models</span>
              </div>
              <div class="image-text_2 flex-row justify-between">
                <img
                    class="label_5"
                    referrerpolicy="no-referrer"
                    src="../assets/home/img5.png"
                />
                <span class="text-group_2">Global Regional <br> Research Center</span>
              </div>
              <div class="image-text_2 flex-row justify-between">
                <img
                    class="label_5"
                    referrerpolicy="no-referrer"
                    src="../assets/home/img5.png"
                />
                <span class="text-group_2">Precision Engineering <br> Technology</span>
              </div>
            </div>
          </div>
          <div class="video-container">
            <video id="myVideo" ref="myVideo" width="99%" height="90%" controls>
              <source src="@/video/Newyanjiu.mp4" type="video/mp4">
            </video>
          </div>
        </div>
        <div class="intro2">
          <div class="block_3 flex-col1" >
            <span class="text_11">Proven Success Cases </span>
            <div class="box_10 flex-row">
              <div class="text-wrapper_3">
                <span class="text_12">10</span> <span class="text_13">K<sup>+</sup>   </span>
              </div>

            </div>
          </div>
          <div class="block_3 flex-col2">
            <span class="text_11">Senior Expert</span>
            <div class="box_10 flex-row">
              <div class="text-wrapper_3">
                <span class="text_12">500</span> <span class="text_13">+  </span>
              </div>

            </div>
          </div>
          <div class="block_3 flex-col3">
            <span class="text_11">Countries and Regions</span>
            <div class="box_10 flex-row">
              <div class="text-wrapper_3">
                <span class="text_12">25</span> <span class="text_13">+  </span>
              </div>

            </div>
          </div>
          <div class="block_3 flex-col4">
            <span class="text_11">Proprietary Technologies</span>
            <div class="box_10 flex-row">
              <div class="text-wrapper_3">
                <span class="text_12">100</span> <span class="text_13">+   </span>
              </div>

            </div>
          </div>
        </div>
      </div>
             <!-- <div class="screennews">
          <div class="icon-photo">
            <img src="@/assets/new/icon1.png" style="width:7%;height:7%;margin-left: 42.5vw;margin-bottom: 3vh;"
              class="icon">
          </div>
          <div class="title-container">
            <h2 style="color: white;" v-sliden-in="{ duration: 800 }" v-zoom-in="{ duration: 800 }" class="title">
              About Us
            </h2>
          </div>
              
                  <div class="content-container" style="margin-bottom: 4vh;">
       
            <div class="text-content" style="margin-top: 10vh;">
              <div class="text-title">Research Institute Introduction</div>
              <div class="main-description">With reservoir geology and engineering technology as the core, combining
                geological engineering and production enhancement features, and utilizing digital and visualization
                methods, we provide oilfield clients with
                production enhancement and efficiency optimization research solutions, aiming to improve engineering
                development efficiency
              </div>
            </div>
       
            <div class="video-container">
              <video id="myVideo" ref="myVideo" width="99%" height="90%" controls>
                <source src="@/video/Newyanjiu.mp4" type="video/mp4">
              </video>
            </div>
          </div>
          <div class="zong-content">
            <div class="sub-content1">
              <div class="photo-111">
                <img src="@/assets/new/Vector.png" style="width:25%;height:25%;margin-left: 4vw;margin-top: 4vh;">
              </div>
              <div class="photo-222">
                <h3 class="item4-title" style="width:100%;height: 100%;"><span class="large-number"
                    style="margin-left: 2vw;">100</span></h3>
                <p class="item4-text" style="width: 100%;height:100%;">We have 100 reservoir databases</p>
              </div>
            </div>
            <div class="sub-content2">
              <div class="photo-111">
                <img src="@/assets/new/Capa_1.png" style="width:25%;height:25%;margin-left: 5vw;margin-top: 4vh;">
              </div>
              <div class="photo-555">
                <h3 class="item4-title" style="width: 100%;height:100%;margin-left: 3vw;"><span class="large-number"
                    style="margin-left: 2vw;">5000</span></h3>
                <p class="item4-text" style="width: 100%;height:100%;margin-bottom: 1.5vh;">We have 5,000 experienced
                  experts</p>
              </div>
            </div>
            <div>
              <div class="sub-content3">
                <div class="photo-333">
                  <h3 class="item4-title" style="width: 100%;height:100%;margin-left: 3vw;margin-top:-11vh"><span
                      class="large-number">55</span></h3>
                  <p class="item4-text" style="width: 100%;height:100%;margin-left: -1.5vw;">serve over 55 countries and
                    regions</p>
                </div>
              </div>
              <div class="sub-content4">
                <div class="photo-444">
                  <h3 class="item4-title" style="width: 100%;height:100%;margin-left: 3vw;margin-top:-11vh"><span
                      class="large-number">300</span></h3>
                  <p class="item4-text" style="width: 100%;height:100%;margin-left: -1.5vw;">more than 300 specialized
                    technologies</p>
                </div>
              </div>
            </div>
          </div>
        </div> -->
              <!-- 增 产 提 效 研 究 课 题模块改为我们的研究模块 -->
              <div class="serviceUs">
        <div class="image-text_1">
          <img class="image_8" referrerpolicy="no-referrer" src="../assets/home/tump.png" />
          <div class="text-group_1 flex-col">
            <span class="text_8">我们的研究</span>
            <span class="text_9">OUR RESEARCH</span>
          </div>
        </div>
<!--        <div class="intro2">-->
<!--          全行业专业人才社区，随时随地帮助您解决油气开发的一切难题。-->
<!--        </div>-->
        <div class="serviceStyle">
          <div class="text-content">
<!--            <div class="title-top" style="text-align: left">油气田开发动态监测服务-->
<!--            </div>-->
            <div class="title-bottom" style="text-align: left;margin-top:0">
              Guiding Engineering Solutions with Precision Geological Insights to Drive Cost Reduction and Efficiency Gains in Marginal and Tail-End Reservoir Development.</div>
            <!-- Discover More 按钮 -->
            <div class="discover-more">
              <button class="discover-btn" @click="upkefu">Learn More ></button>
            </div>
          </div>
          <div class="pro2" style="margin-top: 4vh">
            <div class="grand1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/home/research1.png">
                  <!-- <div class="photohover"></div> -->
                  <div class="title6" > Efficient Development of Marginal Oil Fields</div>
                </div>
              </div>
              <div class="back1">
                <!-- <div class="title6" @click="comment"> 油气藏综合地质评价服务</div> -->

              </div>
            </div>
            <div class="grand1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/home/research2.png">
                  <!-- <div class="photohover"></div> -->
                  <div class="title6new" > Production Enhancement and Stabilization in Late-Phase Mature Oil Fields</div>
                </div>
              </div>
              <div class="back1">
                <!-- <div class="title3" @click="exploit"> 开发方案设计服务</div> -->
              </div>
            </div>
            <div class="grand1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/home/research3.png">
                  <!-- <div class="photohover"></div> -->
                  <div class="title6"> Efficient Transformation of Deep Shale Gas</div>
                </div>
              </div>
              <div class="back1">
                <!-- <div class="title4" @click="naturalfrac"> 天然裂缝预测技术服务</div> -->
              </div>
            </div>
          </div>
          <div class="pro2">
            <div class="grand1" @click="openNew('https://cn.ogdri.com/fineseismic')">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/home/research4.png">
                  <!-- <div class="photohover"></div> -->
                  <div class="title6new2" > Precision Seismic Interpretation<br> and Inversion</div>
                </div>
              </div>
              <div class="back1">
                <!-- <div class="title6" @click="comment"> 油气藏综合地质评价服务</div> -->

              </div>
            </div>
            <div class="grand1" @click="openNew('https://cn.ogdri.com/naturalfrac')">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/home/research5.png">
                  <!-- <div class="photohover"></div> -->
                  <div class="title6new3" > Natural Fracture Prediction</div>
                </div>
              </div>
              <div class="back1">
                <!-- <div class="title3" @click="exploit"> 开发方案设计服务</div> -->
              </div>
            </div>
            <div class="grand1" @click="openNew('https://cn.ogdri.com/geomechanics')">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/home/research6.png">
                  <!-- <div class="photohover"></div> -->
                  <div class="title6new4" > Geomechanics research <br>and 3D Modeling</div>
                </div>
              </div>
              <div class="back1">
                <!-- <div class="title4" @click="naturalfrac"> 天然裂缝预测技术服务</div> -->
              </div>
            </div>
            <div class="grand1" @click="openNew('https://cn.ogdri.com/geomechanics')">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/home/research7.png">
                  <!-- <div class="photohover"></div> -->
                  <div class="title6new2" >Integrated Geological Modeling <br> and Analysis</div>
                </div>
              </div>
              <div class="back1">
                <!-- <div class="title5" @click="geomechanics">地质力学研究技术服务</div> -->
              </div>
            </div>
            <div class="grand1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/home/research8.png">
                  <!-- <div class="photohover"></div> -->
                  <div class="title6new4">Remaining oil distribute & product<br>boost/stability measures</div>
                </div>
              </div>
              <div class="back1">
                <!-- <div class="title5" @click="thinreservoir">三维地质力学建模服务</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
              <!-- <div class="screennews11">
          <div class="icon-photo">
            <img src="@/assets/new/icon1.png" style="width:7%;height:7%;margin-left: 42.5vw;margin-bottom: 3vh;"
              class="icon">
          </div>
          <div class="title-container">
            <h2 v-sliden-in="{duration:800}" v-zoom-in="{duration:800}" class="title">
              Our research
            </h2>
          </div>
          <div class="container11">
            <div class="image-wrapper">
              <img src="@/assets/new/技术1.png" alt="Image" class="image">
              <div class="title11">
                <h3 style="color: rgba(85,85,85,1);text-align: center;margin-right: 3vw;font-size: 1.6vw;">Fine
                  Geological Research on Unconventional Oil and Gas Reservoirs</h3>
              </div>
            </div>

            <div class="content11">
              <p style="font-weight:300;color:#616161;margin-left:1.8vw;">Gain a multidimensional understanding of
                reservoirs, identify potential areas, and advance key technologies for efficient development. Overcome
                challenges step by step, breaking single-technology limits. By dynamically integrating 'points, lines,
                planes, and volumes,
                ' we create comprehensive solutions that drive efficient reservoir development and deliver
                groundbreaking value to clients.</p>
              <div class="discover-btn" @click="upkefu">Know More ></div>
            </div>
          </div>
          <div class="subcontainer">
            <div class="container12">
              <div class="image-wrapper">
                <img src="@/assets/new/技术2.png" alt="Image" class="image">
                <div class="title11">
                  <h3 style="color:rgba(85,85,85,1);font-size: 1.6vw;margin-left: 2.5vw;">Old Well Recompletion and
                    Production Enhancement </h3>
                </div>
              </div>
              <div class="content11">
                <p style="font-weight:300;color:#616161;margin-left:1.8vw;">Focused on enhancing recovery, boosting
                  production, reducing injection pressure, and increasing injection volume, this technology optimizes
                  late-stage well performance, maximizing client benefits.</p>
                <div class="discover-btn" @click="upkefu">Know More ></div>
              </div>
            </div>

            <div class="container13">
              <div class="image-wrapper">
                <img src="@/assets/new/技术3.png" alt="Image" class="image">
                <div class="title11">
                  <h3 style="color:rgba(85,85,85,1);margin-left: 7vw;font-size: 1.6vw;">Efficient Transformation of Deep
                    Shale Gas</h3>
                </div>
              </div>
              <div class="content11">
                <p style="font-weight:300;color:#616161;margin-left:1.9vw;">Deep shale gas features complex structures,
                  fractures, and stress, posing drilling and fracturing challenges. Controlling casing deformation,
                  crossflow,
                  and expanding effective fracture networks are key to efficient development.</p>
                <div class="discover-btn" @click="upkefu">Know More ></div>
              </div>
            </div>
          </div>
        </div> -->
               <!--全 球 共 享 支 持 平 台 修改-->
               <div class="enjoyUs">
        <div class="image-text_1" style="padding-left: 8vw">
          <img class="image_8" referrerpolicy="no-referrer" src="../assets/home/global.png" />
          <div class="text-group_1 flex-col">
            <span class="text_8">全球油藏地质专家共享服务平台</span>
            <span class="text_9">GLOBAL SHARING </span>
          </div>
        </div>
        <div class="intro2" style="padding-left: 8vw">
          A professional talent community covering the entire industry, which can help you solve all the problems in oil and gas development anytime and anywhere.
        </div>

        <div>
          <div style="width: 100vw;height:60vh;margin-top: 13.8vh;position: relative">
            <dv-flyline-chart :config="config" :dev="true" style="position:absolute;;width:100vw;height:70vh;" />
            <dv-flyline-chart :config="config2" :dev="true" style="position:absolute;width:100vw;height:70vh;" />
            <dv-flyline-chart :config="config3" :dev="true" style="position:absolute;width:100vw;height:70vh;" />
            <dv-flyline-chart :config="config4" :dev="true" style="position:absolute;width:100vw;height:70vh;" />
            <!--              <img src="../assets/second.png">-->
          </div>
        </div>
        <div class="searchBack">
          <div class="searchItem" v-if="expert.length>0" style="display:flex">
            <li v-for="(item, index)  in expert" :ref="`rollul-${index}`" :key="index" :class="{anim:animate==true}">
              <div class="searchContent">
                <div class="photo">
                  <img style="border-radius: 0;height:100%" :src="item.img" />
                </div>
                <div class="content">
                  <!-- <div class="title1" v-html="item.title"></div> -->
                  <div class="title2" v-html="item.contend"></div>
                </div>
              </div>
            </li>
          </div>
          <!-- <div class="loginUp" @click="about">关于我们</div> -->
        </div>
        <div class="login">
          <!-- <div @click="login1"  class="loginUp">了解更多</div> -->
          <div @click="about" class="loginUp">Learn More</div>
          <div @click="login1" class="loginIn" style="text-align: center">Log in</div>
        </div>
      </div>
               <!-- <div ref="consultMove" class="screenGpts">
          <div style="display: flex;margin:0 auto;">
            <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
              <div v-sliden-in="{duration:800}" v-zoom-in="{duration:800}" class="title" style="margin-left:4vw;">
                &nbsp;Global Sharing Support Platform
              </div>
            </div>
          </div>
          <div class="light" style="margin-left: 3vw;">
            A professional talent community for the entire industry, where you can communicate with industry experts
            anytime and anywhere. The online platform helps you solve all challenges in oil and gas development.
            <br>

          </div>

          <div>
            <div style="width: 80vw;height:50vh;margin-top: 13.8vh;position: relative">
              <dv-flyline-chart :config="config" :dev="true" style="position:absolute;;width:80vw;height:50vh;" />
              <dv-flyline-chart :config="config2" :dev="true" style="position:absolute;width:80vw;height:50vh;" />

              <dv-flyline-chart :config="config3" :dev="true" style="position:absolute;width:80vw;height:50vh;" />
              <dv-flyline-chart :config="config4" :dev="true" style="position:absolute;width:80vw;height:50vh;" />
            </div>
          </div>
          <div class="searchBack">
            <div class="searchItem" v-if="expert.length>0" style="display:flex">
              <li v-for="(item, index)  in expert" :ref="`rollul-${index}`" :key="index" :class="{anim:animate==true}">
                <div class="searchContent">
                  <div class="photo">
                    <img style="border-radius: 0;height: 100%;" :src="item.img" />
                  </div>
                  <div class="content">
                    <div class="title2" v-html="item.contend"></div>
                  </div>

                </div>

              </li>
            </div>
          </div>
          <div class="login">
            <div class="loginUp" @click="about" style="text-align:center ;margin-left: 5vw;">Know More</div>
            <div class="loginIn" style="margin-left:10vw;" @click="login1">Sign In</div>
          </div>
        </div> -->
        <!--       油藏地质精细研究服务-->
        <!-- <div class="screenFace">

          <div v-sliden-in="{duration:800}" v-zoom-in="{duration:800}" class="title">&nbsp;what problems are you facing?
          </div>

          <div class="pro2">
            <div>
              <div class="grand1">
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/face01.png">
                  </div>
                </div>
                <div class="back1">
                  <div class="title1">
                    How to use limited expert resources to efficiently support global projects?
                  </div>

                </div>
              </div>
            </div>
            <div>
                     <img src="@a/Q.jpeg" alt="图片2" />
              <div class="grand1">
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/face02.png">

                  </div>
                </div>
                <div class="back1">
                  <div class="title1"> How to quickly respond to on-site problems and form solutions?</div>

                </div>
              </div>
            </div>
            <div>
                     <img src="@a/Q.jpeg" alt="图片2" />
              <div class="grand1">
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/face03.png">
                  </div>
                </div>
                <div class="back1">
                  <div class="title1"> How to manage reservoir geological data to empower business?</div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- 免费人工智能大模型 新增-->
         <ai @fibernew="fibernew"  @eur="eur" @recog="recog"></ai>
        <!-- <div class="screennews10">
          <div class="icon-photo">
            <img src="@/assets/new/icon.png" style="width:7%;height:7%;margin-left: 43vw;margin-bottom: 3vh;"
              class="icon">
          </div>
          <div class="title-container">
            <div class="title" v-sliden-in="{duration:800}" v-zoom-in="{duration:800}">&nbsp;Free artificial
              intelligence large model</div>
            <div class="light" style="text-align: center">
              <p>The world's first open platform for collaborative oil & gas development, integrating AI-powered Q&A,
                talent sharing, and intelligent geological engineering tools to optimize efficiency,Powered by <a
                  href="http://www.oilgasgpts.com"
                  style="color: rgba(8,78,138,1); text-decoration: underline;">Anvision</a>.</p>
            </div>
          </div>
          <div class="container-new10">
            <div class="contentphoto">
              <div class="title-top">Oil Gas Knowledge GPT</div>
              <div class="title-bottom">we provide the most specialized oil and gas industry services. </div>
              <div class="photonew10">
                <div slot="reference" class="search">
                  <el-input v-model="keyTitle" style="border: none" @input="getSearch"
                    placeholder="Ask or enter the oil and gas knowledge you want to learn about"
                    clearable="true"></el-input>
                  <i class="el-icon-search" @click="handleSearchClick"
                    style="margin-right:1.1vw;z-index: 999999999;font-size: 1.5vw;color:gray;font-weight: bold;height:7vh;display: flex;align-items: center"></i>
                </div>
                <el-dialog :visible.sync="isDialogVisible" width="600px" class="purple-dialog">
                  <template #title>
                    <div style="display: flex; align-items: center; justify-content: center">
                      <i class="el-icon-warning" style="font-size: 3vw;  "></i>
                    </div>
                    <i @click="closeDialog" style="position: absolute; top: 5px; right: 5px; cursor: pointer;"></i>
                  </template>
                  <div class="dialog-content">
                    <p>You are about to leave the Geological Cloud Platform and go to the Oil and Gas Large Model.</p>
                    <p>Please click the link below or continue to visit:</p>
                    <a :href="dynamicUrl" target="_blank">{{ dynamicUrl }}</a>
                  </div>
                  <div slot="footer" class="dialog-footer" style="text-align: center;">
                    <el-button @click="redirectToDynamicUrl">Continue to visit</el-button>
                  </div>
                </el-dialog>
                <img src="@/assets/new/立即体验11.png" class="imagenew" @click="about">
              </div>

            </div>
            <div class="consult10">
              <div class="title-top">Oil and gas knowledge AI large model</div>
              <div class="title-bottom">Experience AI applications in the oil & gas industry or upload your own model to
                share and co-create with experts. </div>
              <div class="zong">
                <div class="photonew11" @click="recog">
                  <img src="@/assets/new/11.png" class="imagenew">
                </div>
                <div class="photonew12" @click="fibernew">
                  <img src="@/assets/new/22.png" class="imagenew">
                </div>
                <div class="photonew13" @click="eur">
                  <img src="@/assets/new/33.png" class="imagenew">
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <!--精准工程技术模块 新增我们的服务模块 -->
       <OurServices @upkefu="upkefu"  @exploit="exploit" @naturalfrac="naturalfrac" @geomechanics="geomechanics" @thinreservoir="thinreservoir"
       @intelligent="intelligent" @single="single" @integration="integration" @distributevue="distributevue" @gasreservoir="gasreservoir" @number="number" @connect="connect"
        @comment="comment"></OurServices>
        <!-- <youzang id="scrollmodelnew" @about="about" @comment="comment" @exploit="exploit" @naturalfrac="naturalfrac"
          @geomechanics="geomechanics" @thinreservoir="thinreservoir"></youzang> -->

        <!-- 地质工程相结合的技术服务 -->
        <!-- <div class="screennews6">
          <div class="photocontent">
            <div class="photocontent1">
              <span @click="intelligent" class="newtitle3">Intelligent Engineer Optimized Technology</span>
              <img src="@/assets/new/智能工程迭代优化技术.png">
            </div>
            <div class="photocontent1" style="margin-left: -12vw;">
              <span @click="connect" class="newtitle4">Geological Engineer Combin Technology</span>
              <img src="@/assets/new/地质工程相结合老井.jpg">
            </div>
            <div class="text-content" style="margin-top:6vh">
              <div class="main-description">Geological Enginer Combined Technology
              </div>
              <div class="sub-description">
                You can obtain tailored engineering geology technical services, where engineering services are
                integrated with geology,
                fully utilizing geological research outcomes to guide the implementation of engineering plans.
              </div>
              <div class="discover-more">
                <button class="discover-btn" @click="upkefu">Know More ></button>
              </div>
            </div>
          </div>
        </div> -->
        <!-- 油气藏增产改造技术 -->
        <!-- <div class="screennews7">
          <div class="photocontent">
            <div class="text-content">
              <div class="main-description">Oil and Gas Reservoir Production Enhancement and Transformation Technology
                Services
              </div>
              <div class="sub-description">
                You can receive integrated production enhancement services and production risk services,
                centered around geological engineering integration scheme design and the integration of specialized
                product technologies.
              </div>
              <div class="discover-more">
                <button class="discover-btn" @click="upkefu">Know More ></button>
              </div>
            </div>
            <div class="photocontent1" style="margin-right: -11vw;">
              <span @click="single" class="newtitle4">Integrated Production Enhancement <br> Technical Service and
                Support Technology</span>
              <img src="@/assets/new/一体化增产服务.png">
            </div>
            <div class="photocontent1">
              <span @click="integration" class="newtitle3">Specialized Production <br>Enhancement Products</span>
              <img src="@/assets/new/特色增产产品.png">
            </div>
          </div>
        </div> -->
        <!-- 油气田开发动态监测模块 -->
        <!-- <div class="screennews8">
          <div class="containerzong">
            <div class="photocontent" style="margin: auto 0 auto 2vh">
              <div class="photocontent1">
                <span @click="distributevue" class="newtitle3">Distributed Optical Fiber Real-time Monitoring
                  Technology</span>
                <img src="@/assets/new/Group3(1).png">
              </div>
              <div class="photocontent1">
                <span @click="gasreservoir" class="newtitle3">Oil and Gas Reservoir Logging Technology</span>
                <img src="@/assets/new/image.png">
              </div>
              <div class="photocontent1">
                <span @click="number" class="newtitle3">Digital Rock and Rock Cuttings</span>
                <img src="@/assets/new/Group3(2).png">
              </div>
              <div class="text-content" style="margin-top: 1vh;">
                <div class="main-description">Oil and Gas Field Development Dynamic Monitoring Services</div>
                <div class="sub-description">
                  Receive reservoir evaluation and engineering monitoring with fiber optic technology, covering
                  drilling, completion, and production for precise control in oil & gas development.
                </div>
                <div class="discover-more">
                  <button class="discover-btn" @click="upkefu">Know More ></button>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- 油气田一体化增产总包服务 -->
        <!-- <div class="screennews9">
          <div class="photocontent">
            <div class="text-content" style="margin-top: 6vh;margin-left: 3vw;">
              <div class="main-description">Integrated Production Enhancement Turnkey Services for Oil and Gas Fields
              </div>
              <div class="sub-description">
                Receive production enhancement services with precise engineering from research to execution. Leverage
                proven project management and technology for maximum ROI.
              </div>
              <div class="discover-more">
                <button class="discover-btn" @click="upkefu">Know More ></button>
              </div>
            </div>
            <div class="img">
              <img style="margin-left: 9vw;" src="@/assets/new/一体化.jpg" @click="yitihua()">
            </div>
          </div>
        </div> -->
        <!-- 人才共创模块 -->
        <!-- 新建与客户交流画面模块 -->
        <!-- <div class="screennews5">
          <div class="content-containernews5">
            <div class="content-itemnew3" style="margin-bottom: 6vh;"> -->
              <!-- 左侧图片 -->
              <!-- <div class="photo-1">
                <img src="@/assets/new/3.jpg" style="height: 21.8vw">
              </div> -->
              <!-- 右侧文字 -->
              <!-- <div class="title-content" style="margin-right:-1vw">
                <div class="section">
                  <div class="icon" style="width:60px;height: 60px;margin-right: 8vw;">
                    <img src="@/assets/new/图标1.png">
                  </div>
                  <div class="main-title">Professional Talent Co-Creation Platform</div>
                  <div class="sub-text" style="margin-right:2vw">
                    <div class="item1111">Oil and Gas Reservoir Research-based Professionals</div>
                    <div class="item1111">Local Expert Talent</div>
                    <div class="item1111">Geological Engineering Integrated Talent</div>
                  </div>
                </div>
                <div class="vertical-line"></div>
                <div class="section">
                  <div class="icon" style="width:60px;height: 60px;margin-right: 6vw;">
                    <img src="@/assets/new/Vector (1).png">
                  </div>
                  <div class="main-title" style="margin-right: 1vw;">Enterprise Co-Creation Platform</div>
                  <div class="sub-text" style="margin-right:0.5vw">
                    <div class="item1111">Oil and Gas Reservoir Research-based Enterprise Co-Creation</div>
                    <div class="item1111">Oil and Gas Reservoir Service-oriented Enterprise Co-Creation</div>
                    <div class="item1111">Integrated Engineering Technical Services</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- what can you get?  模块-->
        <!-- <div class="screenGet">

          <div v-sliden-in="{duration:800}" v-zoom-in="{duration:800}" class="title">&nbsp;what can you get?
          </div>

          <div class="pro2">
            <div>
              <div class="grand1">
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/get1.png">
                  </div>
                </div>
                <div class="back1">
                  <div class="title1">
                    Data Management and  Information Insight

                  </div>
                  <div class="title2">
                    Efficient data indexing and retrieval mechanism, achieving intelligent management of massive data and obtaining accurate data support.




                  </div>
                </div>
              </div>
            </div>
            <div>
                     <img src="@a/Q.jpeg" alt="图片2" />
              <div class="grand1">
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/get2.png">

                  </div>
                </div>
                <div class="back1">
                  <div class="title1">      Real time cloud services

                  </div>
                  <div class="title2">     Integrate artificial intelligence
                    applications with online expert
                    teams to provide real-time output
                    of various solutions.

                  </div>

                </div>
              </div>
            </div>
            <div>
                     <img src="@a/Q.jpeg" alt="图片2" />
              <div class="grand1">
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/get3.png">
                  </div>
                </div>
                <div class="back1">
                  <div class="title1">  Artificial Intelligence Assisted Decision-making

                  </div>
                  <div class="title2">       Through data mining and pattern
                    recognition, the system can
                    provide highly accurate decision
                    support assistance.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- Online database  模块-->
        <!-- <div class="screenSupport">
          <div>
            <div v-sliden-in="{duration:800}" v-zoom-in="{duration:800}" class="title">Online database
            </div>
            <div class="light">
              From support for the efficient operation of the online expert support system
            </div>
            <div class="light2">
              <div>Massive Data Management</div>
              <div>Professional Knowledge Q&A</div>
              <div>Production Data Q&A</div>
              <div>Report Writing Assistant</div>
              <div>Daily Precise Q&A</div>
              <div>Accident Event Analysis</div>

                     </div>
          </div>
          <div class="photo">
            <img src="@/assets/support.png">
          </div>
        </div> -->
        <!-- Geological Engineering GPTs 模块 -->
        <!-- <div ref="generalMove" class="screenConsult">

          <div style="display: flex;margin:0 auto;">
            <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                           <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">
                           </div>
              <div v-sliden-in="{duration:800}" v-zoom-in="{duration:800}" class="title">&nbsp;Geological Engineering GPTs
              </div>
            </div>
          </div>
          <div class="light" style="text-align: center">
            Free online artificial intelligence application models for reservoir geology and development engineering for
            users.

          </div>
          <div class="pro2">

            <div class="swiper-containerpc">
              <div class="swiper-wrapper">
                <div class="swiper-slide" @click="recog">
                         <img src="@a/Q.jpeg" alt="图片2" />
                  <div class="grand1">
                    <div class="back">
                      <div class="photo">
                        <img src="@/assets/groundHome1.png">
                        <div class="photohover"></div>
                                               <div class="photolist">01</div>
                      </div>
                    </div>
                    <div class="back1">
                      <div class="title1"> Intelligent Reservoir Geological <br> Interpretation GPTS</div>
                      <div class="title2">
                        Read more

                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide" @click="fibernew">
                         <img src="@a/Q.jpeg" alt="图片2" />
                  <div class="grand1">
                    <div class="back">
                      <div class="photo">
                        <img src="@/assets/groundHome2.png">
                        <div class="photohover"></div>
                                               <div class="photolist">02</div>
                      </div>
                    </div>
                    <div class="back1">
                      <div class="title1"> Fiber Leak Point<br> Interpretation GPTS</div>
                      <div class="title2">
                        Read more

                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide" @click="eur">
                  <div class="grand1">
                    <div class="back">
                      <div class="photo">
                        <img src="@/assets/groundHome3.png">
                        <div class="photohover"></div>
                                               <div class="photolist">03</div>
                      </div>
                    </div>
                    <div class="back1">
                      <div class="title1">Fracturing Scheme Parameter <br>Design Optimization GPTs</div>
                      <div class="title2">
                        Read more

                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide" @click="plug">
                         <img src="@a/Q.jpeg" alt="图片2" />
                  <div class="grand1">
                    <div class="back">
                      <div class="photo">
                        <img src="@/assets/groundHome4.png">
                        <div class="photohover"></div>
                                               <div class="photolist">04</div>
                      </div>
                    </div>
                    <div class="back1">
                      <div class="title1">Intelligent Sand Blocking<br>Warning GPTS</div>
                      <div class="title2">
                        Read more

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div> -->

        <!--       油藏地质精细研究服务        Oil & Gas Field Development Technical 模块-->
        <!-- <div ref="professional" class="screenConsultnew" style="background-color: white">

          <div style="display: flex;margin:0 auto;">
            <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                           <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">
                           </div>
              <div v-sliden-in="{duration:800}" v-zoom-in="{duration:800}" class="title">&nbsp;
                Oil & Gas Field Development Technical
              </div>
            </div>
          </div>
          <div class="light" style="text-align: center">
            Global Oil & Gas development customers for the future of sustainable development, Anton provides a full
            range of solutions!
          </div>

          <div class="serviceStyle">
            <div :class="getClass(0)" @mouseenter="mouseSwiper(0)" @mouseleave="overSwiper(0)">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/new1.png">
                </div>
              </div>
              <div class="title">
                Oil Reservoir Geology Detailed Research Services
              </div>
              <div class="span"></div>
              <div class="light">
                <div class="lightbehind" @click="comment">
                  <img class="biaoti" src="@/assets/biaoti1.svg"/>
                  <div class="limit">Reservoir Comprehensive Geological Evaluation Service</div>
                  <div class="iconStyle"><i class="el-icon-arrow-right" style="color: white"></i></div>
                </div>
                <div class="lightbehind" @click="exploit"><img class="biaoti" src="@/assets/biaoti2.svg"/>
                  <div class="limit"> Oil & Gas Reservoir Overall Development Plan Design Service</div>
                  <div class="iconStyle"><i class="el-icon-arrow-right" style="color: white"></i></div>
                </div>
                <div class="lightbehind" @click="forecast"><img class="biaoti" src="@/assets/biaoti3.svg"/>
                  <div class="limit">Characteristic Technology of Oil & Gas Reservoir Research</div>
                  <div class="iconStyle"><i class="el-icon-arrow-right" style="color: white"></i></div>
                </div>
              </div>
            </div>
            <div :class="getClass(1)" @mouseenter="mouseSwiper(1)" @mouseleave="overSwiper(1)">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/new2.png">
                </div>
              </div>
              <div class="title">
                Oil Reservoir Geology Detailed Research Services
              </div>
              <div class="span"></div>
              <div class="light">
                <div class="lightbehind" @click="comment"><img class="biaoti" src="@/assets/biaoti3.svg"/>
                  <div class="limit">Reservoir Comprehensive Geological Evaluation Service</div>
                  <div class="iconStyle"><i class="el-icon-arrow-right" style="color: white"></i></div>
                </div>
                <div class="lightbehind" @click="exploit"><img class="biaoti" src="@/assets/biaoti4.svg"/>
                  <div class="limit"> Oil & Gas Reservoir Overall Development Plan Design Service</div>
                  <div class="iconStyle"><i class="el-icon-arrow-right" style="color: white"></i></div>
                </div>
                <div class="lightbehind" @click="forecast"><img class="biaoti" src="@/assets/biaoti5.svg"/>
                  <div class="limit">Characteristic Technology of Oil & Gas Reservoir Research</div>
                  <div class="iconStyle"><i class="el-icon-arrow-right" style="color: white"></i></div>
                </div>
              </div>
            </div>
            <div :class="getClass(2)" @mouseenter="mouseSwiper(2)" @mouseleave="overSwiper(2)">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/new3.png">
                </div>
              </div>
              <div class="title">
                Oil Reservoir Geology Detailed Research Services
              </div>
              <div class="span"></div>
              <div class="light">
                <div class="lightbehind" @click="comment"><img class="biaoti" src="@/assets/biaoti7.svg"/>
                  <div class="limit">Reservoir Comprehensive Geological Evaluation Service</div>
                  <div class="iconStyle"><i class="el-icon-arrow-right" style="color: white"></i></div>
                </div>
                <div class="lightbehind" @click="exploit"><img class="biaoti" src="@/assets/biaoti8.svg"/>
                  <div class="limit"> Oil & Gas Reservoir Overall Development Plan Design Service</div>
                  <div class="iconStyle"><i class="el-icon-arrow-right" style="color: white"></i></div>
                </div>
                <div class="lightbehind" @click="forecast"><img class="biaoti" src="@/assets/biaoti9.svg"/>
                  <div class="limit">Characteristic Technology of Oil & Gas Reservoir Research</div>
                  <div class="iconStyle"><i class="el-icon-arrow-right" style="color: white"></i></div>
                </div>
              </div>
            </div>
            <div :class="getClass(3)" @mouseenter="mouseSwiper(3)" @mouseleave="overSwiper(3)">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/new4.png">
                </div>
              </div>
              <div class="title">
                Oil Reservoir Geology Detailed Research Services
              </div>
              <div class="span"></div>
              <div class="light">
                <div class="lightbehind" @click="comment"><img class="biaoti" src="@/assets/biaoti10.svg"/>
                  <div class="limit">Reservoir Comprehensive Geological Evaluation Service</div>
                  <div class="iconStyle"><i class="el-icon-arrow-right" style="color: white"></i></div>
                </div>
                <div class="lightbehind" @click="exploit"><img class="biaoti" src="@/assets/biaoti11.svg"/>
                  <div class="limit"> Oil & Gas Reservoir Overall Development Plan Design Service</div>
                  <div class="iconStyle"><i class="el-icon-arrow-right" style="color: white"></i></div>
                </div>
                <div class="lightbehind" @click="forecast"><img class="biaoti" src="@/assets/biaoti9.svg"/>
                  <div class="limit">Characteristic Technology of Oil & Gas Reservoir Research</div>
                  <div class="iconStyle"><i class="el-icon-arrow-right" style="color: white"></i></div>
                </div>
              </div>
            </div>
            <div :class="getClass(4)" @mouseenter="mouseSwiper(4)" @mouseleave="overSwiper(4)">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/new5.png">
                </div>
              </div>
              <div class="title">
                Oil Reservoir Geology Detailed Research Services
              </div>
              <div class="span"></div>
              <div class="light">
                <div class="lightbehind" @click="increase"><img class="biaoti" src="@/assets/biaoti2.svg"/>
                  <div class="limit">Increasing Production Overall Solution Technical Services</div>
                  <div class="iconStyle"><i class="el-icon-arrow-right" style="color: white"></i></div>
                </div>
              </div>
            </div> -->

        <!--            <div class="swiper-containerpc2">-->
        <!--              <div class="swiper-wrapper">-->
        <!--             <div class="swiper-slide" >-->
        <!--                 </div>-->
        <!--                <div class="swiper-slide" >-->
        <!--                  &lt;!&ndash;        <img src="@a/Q.jpeg" alt="图片2" />&ndash;&gt;-->
        <!--                  </div>-->
        <!--                <div class="swiper-slide">-->
        <!--                  &lt;!&ndash;        <img src="@a/Q.jpeg" alt="图片2" />&ndash;&gt;-->
        <!--                 </div>-->
        <!--                <div class="swiper-slide">-->
        <!--                 </div>-->
        <!--                <div class="swiper-slide">-->
        <!--                 </div>-->
        <!--              </div>-->
        <!--              <div class="swiper-pagination"></div>-->
        <!--            </div>-->
        <!-- </div> -->


        <!-- </div> -->
        <!-- 联系我们模块新增-->
         <Contactus></Contactus>
        <!-- <div class="screenMap">
          <div class="icon-photo">
          <img src="@/assets/new/icon1.png" style="width:7%;height:7%;margin-left: 37.5vw;margin-bottom: 3vh;"
            class="icon">
        </div>
        <div class="title-container">
          <h2 v-sliden-in="{duration:800}" v-zoom-in="{duration:800}" class="title">
            Contact Us
          </h2>
        </div>
          <div style="margin-top: 5vh;">
            <div style="display: flex;width:100%;margin-left: 5vw;">
              <div class="top" @click="upkefu2" style="width: 30%;">
                <div style="margin-bottom: -2.5vh;"><img style="width: 5%;margin-left:3vw;margin-bottom: -1vh;"
                    src="@/assets/Vector (3).png"></div>
                <div class="high2">Online Service</div>
                <div class="high1" style="margin-bottom:-0.3vh">8:00-17:00 Available</div>
                <div class="high3"></div>
              </div>
              <div class="top1" style="width: 30%;">
                <div style="margin-bottom: -4vh;"><img style="width: 8%;margin-left: 3vw;" src="@/assets/new/电话.png">
                </div>
                <div class="high2" title=''>Contact Phone </div>
                <div class="high1">+86-18511006028</div>
                <div class="high3"></div>
              </div>
              <div class="top" style="width: 30%;">
                <div style="margin-bottom: -3vh;"><img style="width: 5%;margin-left: 3vw;"
                    src="@/assets/Vector (4).png"></div>
                <div class="high1">Email Us</div>
                <div class="high2">OGDRI@antonoil.com</div>
                <div class="high3"></div>
              </div>
              <div class="top">
              </div>
            </div>
          </div>

        </div> -->
        <!--底部-->
        <div class="screen7">
          <bottomTip @showComment="showComment" @showDialogBottom="scroll1" @showNet="showNet"
            @showProduct="showProduct"></bottomTip>
        </div>
        <netFeedback :dialogVisible="dialogNet" @hideDialog="hideNet"></netFeedback>
        <productFeedback :dialogVisible="dialogProduct" @hideDialog="hideProduct"></productFeedback>

        <commentFeedback :dialogVisible="dialogComment" @hideDialog="hideComment"></commentFeedback>
        <consultTip :dialogVisible="dialogVisible" :thisTitle="title" @hideDialog="hideDialog"></consultTip>
        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
      </div>
    </div>
    <div v-else>
      <div id="totalModel" ref="totalModel" class="totalModel">
        <!-- <kefu @uptop="uptop"  @showProduct="showProduct" style="z-index:99"></kefu> -->
        <kefumobile @uptop="uptop" @showProduct="showProduct" style="z-index:99"></kefumobile>
        <div class="screen1">
          <headOther @showDialog="showPartner" @showDialogTop="scroll" @button1="dialogVisible=true;" @button2="about"
            @position6="position6" @position7="position7"></headOther>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->
        </div>
        <div v-if="scrollTrue===false" class="up" @click="uptop"></div>
        <AboutUs></AboutUs>
        <research-section />
        <div ref="map" class="map" >
          <Map @button1="about" @button2="login1"></Map>
        </div>
        <div class="screenFace">
          <FreeModel @recog="recog" @fibernew="fibernew" @eur="eur"></FreeModel>
          <!-- <div   class="title">&nbsp;what problems are you
            facing?
          </div>

          <div class="pro2">
            <div>
              <div class="grand1">
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/face01.png">
                  </div>
                </div>
                <div class="back1">
                  <div class="title1">
                    How to use limited expert resources to efficiently support global projects?
                  </div>

                </div>
              </div>
            </div>
            <div>
              <div class="grand1">
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/face02.png">

                  </div>
                </div>
                <div class="back1">
                  <div class="title1"> How to quickly respond to on-site problems and form solutions?</div>

                </div>
              </div>
            </div>
            <div>
              <div class="grand1">
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/face03.png">
                  </div>
                </div>
                <div class="back1">
                  <div class="title1"> How to manage reservoir geological data to empower business?</div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <service-section />
       
        <!-- <div ref="consultMoveMobile" class="map" style="">
          <div style="background-color: #bed6f5;">
            <div class="title" style="color: #193871">
              Global Online Expert Support Services
            </div>
            <div class="light" style="color: #193871">
              Integrated shared cloud service for global service outlets and back-end technical staff of china unicom.
            </div>
            <div class="light" style="font-size:14px;color: #193871;margin: 0px auto 15px  auto;width: fit-content;border-bottom: 1px solid #193871;"
                 @click="login">
              Click to Log In
            </div>
          </div>
          <div style="position: relative;height: 170px;width: 350px">
            <dv-flyline-chart :config="configmodel" :dev="true" style="position:absolute;;height: 150px;width: 350px;"/>
            <dv-flyline-chart :config="configmodel2" :dev="true" style="position:absolute;height: 150px;width: 350px;"/>

            <dv-flyline-chart :config="configmodel3" :dev="true" style="position:absolute;height: 150px;width: 350px"/>
            <dv-flyline-chart :config="configmodel4" :dev="true" style="position:absolute;height: 150px;width: 350px;"/>
          </div>
          <personcard></personcard>
        </div> -->

        <!-- <div class="screenGet">

          <div   class="title">&nbsp;what can you get?
          </div>

          <div class="pro2">
            <div>
              <div class="grand1">
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/get1.png">
                  </div>
                </div>
                <div class="back1">
                  <div class="title1">
                    Data Management and  Information Insight

                  </div>
                 <div class="title2">
                   Efficient data indexing and retrieval mechanism, achieving intelligent management of massive data and obtaining accurate data support.




                 </div>
                </div>
              </div>
            </div>
            <div>
                     <img src="@a/Q.jpeg" alt="图片2" />
              <div class="grand1">
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/get2.png">

                  </div>
                </div>
                <div class="back1">
                  <div class="title1">      Real time cloud services

                  </div>
                 <div class="title2">     Integrate artificial intelligence
                   applications with online expert
                   teams to provide real-time output
                   of various solutions.

                 </div>

                </div>
              </div>
            </div>
            <div>
                     <img src="@a/Q.jpeg" alt="图片2" />
              <div class="grand1">
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/get3.png">
                  </div>
                </div>
                <div class="back1">
                  <div class="title1">  Artificial Intelligence Assisted Decision-making

                  </div>
                 <div class="title2">       Through data mining and pattern
                   recognition, the system can
                   provide highly accurate decision
                   support assistance.
                 </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="screenSupport">
          <div>
            <div   class="title">Online database
            </div>
            <div class="light">
              From support for the efficient operation of the online expert support system
            </div>
            <div class="light2">
              <div>Massive Data Management</div>
              <div>Professional Knowledge Q&A</div>
              <div>Production Data Q&A</div>
              <div>Report Writing Assistant</div>
              <div>Daily Precise Q&A</div>
              <div>Accident Event Analysis</div>
            </div>
          </div>
          <div class="photo">
            <img src="@/assets/support.png">
          </div>


        </div> -->
        <!-- <div ref="generalMoveMobile" class="GEGPTs" >
          <div class="title" style="color: #193871">
            Geological Engineering GPTs
          </div>
          <div class="light" style="color: #193871">
            Free online artificial intelligence application models for reservoir geology and development engineering for
            users.
          </div>

          <div class="pro2">
            <div class="swiper-container0">
              <div class="swiper-wrapper">
                <div class="swiper-slide" @click="recog">
                  <div class="grand1">
                    <div class="back">
                      <div class="photo">
                        <img src="@/assets/groundHome1.png">
                        <div class="photolist">01</div>
                      </div>
                    </div>
                    <div class="back1">
                      <div class="title1"> Intelligent Reservoir Geolo-<br>gical
                        Interpretation GPTS
                      </div>
                      <div class="title2">
                        Read more

                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide" @click="fibernew">
                  <div class="grand1">
                    <div class="back">
                      <div class="photo">
                        <img src="@/assets/groundHome2.png">
                        <div class="photolist">02</div>
                      </div>
                    </div>
                    <div class="back1">
                      <div class="title1"> Fiber Leak Point<br>
                        Interpretation GPTS
                      </div>
                      <div class="title2">
                        Read more

                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide" @click="eur">
                  <div class="grand1">
                    <div class="back">
                      <div class="photo">
                        <img src="@/assets/groundHome3.png">
                        <div class="photolist">03</div>
                      </div>
                    </div>
                    <div class="back1">
                      <div class="title1">Fracturing Scheme Parameter
                        Design Optimization GPTs
                      </div>
                      <div class="title2">
                        Read more

                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide" @click="plug">
                  <div class="grand1">
                    <div class="back">
                      <div class="photo">
                        <img src="@/assets/groundHome4.png">
                        <div class="photolist">04</div>
                      </div>
                    </div>
                    <div class="back1">
                      <div class="title1"> Intelligent Sand Blocking
                        Warning GPTS
                      </div>
                      <div class="title2">
                        Read more

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>

        </div> -->
        <!-- <div ref="seriesService" class="seriesService000" style="">
          <div v-sliden-in="{duration:800}" v-zoom-in="{duration:800}" class="titleti">
          </div>
          <div class="title">
            Oil & Gas Field Development Technical
          </div>
          <div class="light" style="color: #193871">
            Global Oil & Gas development customers for the future of sustainable development, Anton provides a full
            range of solutions!
          </div>
          <div class="swiper-container1">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="box1">
                  <div class="back">
                    <div class="photo">
                      <img src="@/assets/new1.png">
                    </div>
                    <div class="photolist">
                      <img src="@/assets/newicon1.png">
                    </div>
                  </div>
                  <div class="title" style="color: #193871">
                    Oil Reservoir Geology Detailed Research Services
                  </div>
                  <div class="light" style="color: #193871">
                    <div class="lightbehind" @click="comment"><img class="biaoti" src="@/assets/biaoti1.png"/>
                      <div class="titleHeight">Reservoir Comprehensive Geological Evaluation Service</div>
                      <div class="iconStyle"><i class="el-icon-arrow-right" style="color: white"></i></div>
                    </div>
                    <div class="lightbehind" @click="exploit"><img class="biaoti" src="@/assets/biaoti2.png"/>
                      <div class="titleHeight">Oil & Gas Reservoir Overall Development Plan Design Service</div>
                      <div class="iconStyle"><i class="el-icon-arrow-right" style="color: white"></i></div>
                    </div>
                    <div class="lightbehind" @click="forecast"><img class="biaoti" src="@/assets/biaoti3.png"/>
                      <div class="titleHeight">Characteristic Technology of Oil & Gas Reservoir Research</div>
                      <div class="iconStyle"><i class="el-icon-arrow-right" style="color: white"></i></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="box1">
                  <div class="back">
                    <div class="photo">
                      <img src="@/assets/new2.png">
                    </div>
                    <div class="photolist">
                      <img src="@/assets/newicon2.png">
                    </div>
                  </div>
                  <div class="title" style="color: #193871">
                    Engineering Geological Technology Services
                  </div>
                  <div class="light" style="color: #193871">
                    <div class="lightbehind" @click="intelligent"><img class="biaoti" src="@/assets/biaoti4.png"/>
                      <div class="titleHeight">The Intelligent Engineering Iterative Optimization GPTs</div>
                      <div class="iconStyle"><i class="el-icon-arrow-right" style="color: white"></i></div>
                    </div>
                    <div class="lightbehind" @click="connect"><img class="biaoti" src="@/assets/biaoti5.png"/>
                      <div class="titleHeight">A Series of Techniques for Tapping The Potential of Old Wells Combined
                        with Geological Engineering
                      </div>
                      <div class="iconStyle"><i class="el-icon-arrow-right" style="color: white"></i></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="box1">
                  <div class="back">
                    <div class="photo">
                      <img src="@/assets/new3.png">
                    </div>
                    <div class="photolist">
                      <img src="@/assets/newicon3.png">
                    </div>
                  </div>
                  <div class="title" style="color: #193871">
                    Oil & Gas Stimulation Technical Services
                  </div>
                  <div class="light" style="color: #193871">
                    <div class="lightbehind" @click="single"><img class="biaoti" src="@/assets/biaoti6.png"/>
                      <div class="titleHeight">Integrated Production Technology Services and Supporting Technologies
                      </div>
                      <div class="iconStyle"><i class="el-icon-arrow-right" style="color: white"></i></div>
                    </div>
                    <div class="lightbehind" @click="integration"><img class="biaoti" src="@/assets/biaoti7.png"/>
                      <div class="titleHeight">Characteristic Increasing Product</div>
                      <div class="iconStyle"><i class="el-icon-arrow-right" style="color: white"></i></div>
                    </div>
                   
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="box1">
                  <div class="back">
                    <div class="photo">
                      <img src="@/assets/new4.png">
                    </div>
                    <div class="photolist">
                      <img src="@/assets/newicon4.png">
                    </div>
                  </div>
                  <div class="title" style="color: #193871">
                    Oil & Gas Field Development Monitoring Services
                  </div>
                  <div class="light" style="color: #193871">
                    <div class="lightbehind" @click="distributevue"><img class="biaoti" src="@/assets/biaoti10.png"/>
                      <div class="titleHeight">Distributed Optical Fiber Real-time Monitoring Technology</div>
                      <div class="iconStyle"><i class="el-icon-arrow-right" style="color: white"></i></div>
                    </div>
                    <div class="lightbehind" @click="gasreservoir"><img class="biaoti" src="@/assets/biaoti11.png"/>
                      <div class="titleHeight">Reservoir Logging Technique</div>
                      <div class="iconStyle"><i class="el-icon-arrow-right" style="color: white"></i></div>
                    </div>
                    <div class="lightbehind" @click="number"><img class="biaoti" src="@/assets/biaoti12.png"/>
                      <div class="titleHeight">Digital Rock Debris</div>
                      <div class="iconStyle"><i class="el-icon-arrow-right" style="color: white"></i></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="box1">
                  <div class="back">
                    <div class="photo">
                      <img src="@/assets/new5.png">
                    </div>
                    <div class="photolist">
                      <img src="@/assets/newicon5.png">
                    </div>
                  </div>
                  <div class="title" style="color: #193871">
                    Increasing Production Overall Solution Technical Services
                  </div>
                  <div class="light" style="color: #193871">
                    <div class="lightbehind" @click="increase"><img class="biaoti" src="@/assets/biaoti9.png"/>
                      <div class="titleHeight">Increasing Production Overall Solution Technical Services</div>
                      <div class="iconStyle"><i class="el-icon-arrow-right" style="color: white"></i></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>

        </div> -->
        <!-- <div  class="screenMap" >
          <div class="title">Contact Us
          </div>
          <div class="light" >
            For all enquiries, please email, call or visit our office using the information below.
          </div>
          <div>
            <div>
              <div class="top" style="border: none;margin-top: 0" @click="upkefu2">
                <div class="high2"  >Online Service</div>
                <div class="high1">8:00-17:00 Available</div>
              </div>
                <div class="top" @click="dialogVisible=true;title=''">
                  <div class="high2" >Cooperation Consulting</div>
                <div class="high1">+86-13880250028</div>
              </div>
              <div class="top">
                <div class="high1">Email Us</div>
                <div class="high2">OGDRI@antonoil.com</div>
              </div>
              <div class="top">
                <div class="high1">Our office</div>
                <div class="high2">NARGESS 8, New Cairo 1, <br>Cairo Governorate
                </div>
              </div>
            </div>
            <div class="photo" style="margin-top: 25px;height: 250px;">
              <img style="border-radius: 9px" src="@/assets/kailuo.png"/>
            </div>
          </div>


        </div> -->
        <div class="our-section1">
        <contact-section />
        </div>
        <div class="serviceFeedback" style="background-color: #d8d8d8;">
          <ServiceFeedback></ServiceFeedback>
        </div>
        <div class="our-section">
          <about-section />
          <!-- <contact-section /> -->
        </div>
        <mobile-footer />
        <!--底部-->
        <!-- <div ref="bottom" class="screen7">
          <bottomTip @showDialogBottom="scroll1"></bottomTip>
        </div> -->

        <consultTip :dialogVisible="dialogVisible" :thisTitle="title" @hideDialog="hideDialog"></consultTip>
        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
      </div>
    </div>
  </div>
</template>
<script>
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import headtip from "@/views/headTip.vue";
import headOther from "@/views/headOther.vue";
import bottomTip from "@/views/bottomTip.vue";
import {distribute} from "gsap/gsap-core";
import netFeedback from "@/components/netFeedback.vue";
import productFeedback from "@/components/productFeedback.vue";
import commentFeedback from "@/components/commentFeedback.vue";
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.css'
import {vSlidenIn} from '@/utils/vSlidenIn.js'; // 确保路径正确
// import newMap from '@/components/map.vue'
import {vZoomIn} from '@/utils/vZoomIn.js';
// import Personcard from "@/components/personcard.vue"; // 确保路径正确
import FreeModel from '@/views/component/FreeModel.vue';
import Map from "@/views/component/Map.vue"
import ServiceFeedback from "@/views/component/ServiceFeedback.vue"
import ResearchSection from "@/components/home/mobile/ResearchSection.vue";
import ServiceSection from "@/components/home/mobile/ServiceSection.vue";
// import AboutSection from "@/components/home/mobile/AboutSection.vue";
import AboutUs from "@/components/home/mobile/AboutUs.vue";
import ContactSection from "@/components/home/mobile/ContactSection.vue";
import MobileFooter from "@/components/MobileFooter.vue";
// import youzang from "@/components//home/pc/youzang.vue";
import ai from "@/components/home/pc/ai.vue";
import OurServices from "@/components/home/pc/OurServices.vue";
// import Kefu from "@/components/kefu.vue";
import kefuPc from "@/components/kefuPc.vue";
import kefumobile from "@/components/kefumobile.vue";
import Contactus from "../components/home/pc/Contactus.vue";
export default {
  name: "Home",
  components: {
    // Personcard,
    bottomTip,
    PartnerTip,
    consultTip,
    netFeedback,
    productFeedback,
    commentFeedback,
    headtip,
    headOther,
    FreeModel,
    Map,
    ServiceFeedback,
    ServiceSection,
     ContactSection,
     AboutUs,
    // AboutSection,
    MobileFooter,
    ResearchSection,
    // youzang,
    kefuPc,
    kefumobile,
    ai,
    OurServices,
    Contactus,
    // Kefu,

  },
  data() {
    return {
      keyTitle: '',
      isDialogVisible: false, // 控制弹框的显示与隐藏
      dynamicUrl: '', // 存储动态拼接的链接
      search: [
        {
          link: "gptTranslate",
          title: "工艺类GPTs",
          contend: "工艺类GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "解释类GPTs",
          contend: "解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "工艺类GPTs",
          contend: "工艺类GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "解释类GPTs",
          contend: "解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "工艺类GPTs",
          contend: "工艺类GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "解释类GPTs",
          contend: "解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持安全稳定开发"
        },
      ],
      animate: true,
      expert: [
        {
          id:1,
          img: require("@/assets/expert/user1.jpg"),
          title: "Expert Chen",
          contend: "Construct Interpretation Expert"
        },
        {
          id:2,
          img: require("@/assets/expert/user2 copy.png"),
          title: "Expert Chen",
          contend: "Stimulation Technology Expert"
        },
        {
          id:3,
          img: require("@/assets/expert/user3 copy.jpg"),
          title: "Expert Cheng",
          contend: "Development Geology Specialists"
        },
        {
          id:4,
          img: require("@/assets/expert/user4 copy.png"),
          title: "Expert Chen",
          contend: "Construct Interpretation Expert"
        },
        {
          id:5,
          img: require("@/assets/expert/user5.jpg"),
          title: "Expert Tian",
          contend: "Total Solution Specialist"
        },
        {
          id:6,
          img: require("@/assets/expert/user6 copy.png"),
          title: "Expert Liang",
          contend: "Logging Interpretation Expert"
        },

        {
          id:7,
          img: require("@/assets/expert/user7 copy.jpg"),
          title: "Expert Wang",
          contend: "Reservoir Engineering Expert"
        },
        {
          id:8,
          img: require("@/assets/expert/user8 copy.jpg"),
          title: "Expert Liang",
          contend: "Reservoir Geology Expert"
        },
        {
          id:9,
          img: require("@/assets/expert/user9.jpg"),
          title: "Expert Liang",
          contend: "Reservoir Forecasting Specialist"
        },
        {
          id:10,
          img: require("@/assets/expert/user10 copy.png"),
          title: "Expert Liu",
          contend: "Development Geology Specialists"
        },
        {
          id:11,
          img: require("@/assets/expert/user11.jpg"),
          title: "Expert Liu",
          contend: "Expert in Geomechanics"
        },
        {
          id:12,
          img: require("@/assets/expert/user12 copy.png"),
          title: "Expert Wu",
          contend: "Reservoir Engineering Expert"
        },
      ],
      config: {
        centerPoint: [0.55, 0.47],
        points: [
          {
            position: [0.28, 0.68],
            text: ' ',
          },
          {
            position: [0.25, 0.17],
            text: '  ',
          },
          {
            position: [0.56, 0.74],
            text: ' ',
          },

        ],
        k: -0.5,
        lineWidth: 2,
        flylineColor: '#2167d9',
        flylineRadius: 350,
        duration: [28, 25],
        orbitColor: '',
        bgImgUrl: require("../assets/second.png"),
        centerPointImg: {
          url: require("../assets/center1.png"),
        }, curvature: 5,
        pointsImg: {
          width: 110,
          height: 110,

          url: require("../assets/assets.png"),
        },
        text: {
          offset: [0, 35],
          fontSize: 14,
          color: '#2167d9'
        }
      },

      config2: {
        centerPoint: [0.61, 0.32],
        points: [
          {
            position: [0.57, 0.13],
            text: ' ',
          },
          {
            position: [0.65, 0.40],
            text: ' ',
          },
        ],
        k: -0.5,
        flylineColor: '#2167d9',
        flylineRadius: 450,
        lineWidth: 2,
        orbitColor: '',
        curvature: 3,
        bgImgUrl: '',
        centerPointImg: {
          url: require("../assets/center1.png"),
        },
        pointsImg: {
          width: 110,
          height: 110,

          url: require("../assets/assets.png"),
        },
        text: {
          offset: [0, 35],
          fontSize: 14,
          color: '#13237a'
        }
      },
      config3: {
        centerPoint: [0.81, 0.36],
        points: [


          {
            position: [0.90, 0.23],
            text: ' ',
          }, {
            position: [0.93, 0.79],
            text: ' ',
          },

        ],
        k: -1,
        flylineColor: '#2167d9',
        flylineRadius: 550,
        lineWidth: 2,
        orbitColor: '',
        curvature: 3.5,
        bgImgUrl: '',
        centerPointImg: {
          url: require("../assets/center1.png"),
        },
        pointsImg: {
          width: 110,
          height: 110,

          url: require("../assets/assets.png"),
        },
        text: {
          offset: [0, 35],
          fontSize: 14,
          color: '#13237a'
        }
      },
      config4: {
        centerPoint: [0.61, 0.34],
        points: [
          {
            position: [0.56, 0.52],
          },
          {
            position: [0.81, 0.36],
          },
        ],
        k: -0.5,
        flylineColor: '#2167d9',
        flylineRadius: 350,
        lineWidth: 2,
        orbitColor: '',
        curvature: 4,
        bgImgUrl: '',
        centerPointImg: {
          url: '',
        },
        pointsImg: {
          width: 110,
          height: 110,

          url: '',
        },
        text: {
          offset: [0, 35],
          fontSize: 14,
          color: '#13237a'
        }
      },

      configmodel: {
        halo: {
          show: true,
          radius: 50,
        },
        centerPoint: [0.55, 0.48],
        points: [
          {
            position: [0.28, 0.68],
            text: ' ',
          },
          {
            position: [0.25, 0.17],
            text: '  ',
          },
          {
            position: [0.56, 0.74],
            text: ' ',
          },

        ],
        k: -0.5,
        lineWidth: 1,
        flylineColor: ' ',
        flylineRadius: 50,
        duration: [20, 30],
        orbitColor: '',
        bgImgUrl: require("../assets/second.png"),
        centerPointImg: {
          width: 20,
          height: 20,
          url: require("../assets/center1.png"),
        }, curvature: 5,
        pointsImg: {
          width: 45,
          height: 45,
          url: require("../assets/assets.png"),
        },
        text: {
          offset: [0, 18],
          fontSize: 10,
          color: '#13237a'
        }
      },
      configmodel2: {
        centerPoint: [0.60, 0.30],
        points: [
          {
            position: [0.59, 0.10],
            text: ' ',
          },
          {
            position: [0.65, 0.40],
            text: ' ',
          },
        ],
        halo: {
          radius: 50
        },
        k: -0.5,
        flylineColor: ' ',
        flylineRadius: 50,
        lineWidth: 1,
        orbitColor: '',
        curvature: 3,
        bgImgUrl: '',
        centerPointImg: {
          width: 20,
          height: 20,
          url: require("../assets/center1.png"),
        },
        pointsImg: {
          width: 45,
          height: 45,
          url: require("../assets/assets.png"),
        },
        text: {
          offset: [0, 18],
          fontSize: 10,
          color: '#13237a'
        }
      },
      configmodel3: {
        centerPoint: [0.81, 0.36],
        halo: {
          radius: 50
        },
        points: [


          {
            position: [0.90, 0.23],
            text: ' ',
          }, {
            position: [0.93, 0.79],
            text: ' ',
          },

        ],
        k: -1,
        flylineColor: '  ',
        flylineRadius: 50,
        lineWidth: 1,
        orbitColor: '',
        curvature: 3.5,
        bgImgUrl: '',
        centerPointImg: {
          width: 20,
          height: 20,
          url: require("../assets/center1.png"),
        },
        pointsImg: {
          width: 45,
          height: 45,
          url: require("../assets/assets.png"),
        },
        text: {
          offset: [0, 18],
          fontSize: 10,
          color: '#13237a'
        }
      },
      configmodel4: {
        halo: {
          show: false
        },
        centerPoint: [0.61, 0.34],
        points: [
          {
            position: [0.56, 0.52],
          },
          {
            position: [0.81, 0.36],
          },
        ],
        k: -0.5,
        flylineColor: '',
        flylineRadius: 50,
        lineWidth: 1,
        orbitColor: '',
        curvature: 4,
        bgImgUrl: '',
        centerPointImg: {
          url: '',
        },
        pointsImg: {
          width: 45,
          height: 45,
          url: "",
        },
        text: {
          offset: [0, 35],
          fontSize: 10,
          color: '#13237a'
        }
      },
      swiper: null,
      swiper2: null,
      scrollPosition: null,
      model: false,
      swiperSlid: [0, 0, 0, 0, 0],
      avator: require("../assets/user.jpg"),
      userName: "",
      userId: null,
      dialogVisible: false,
      dialogComment: false,
      dialogProduct: false,
      title: "",
      dialogconsult: false,
      dialogNet: false,
      product: 1,
      infogpt: 1,
      currentIndex: 0,
      itemsPerPage: 4,
      letters: '0123456789ABCDEF',
      carouselItems: [
        {
          img: require("../assets/logo1.png"),
          title: "智能钻井",
          text:
              "着陆段地质层分类预警：基于六个维度数据特征，建立最小AI模型，准确率提升20%." +
              "<br>" +
              "水平段上下穿层预警：水平段穿层实时判断、上下切角实时预警建立最小模型.",
        },
        {
          img: require("../assets/logo2.png"),
          title: "智能压裂",
          text:
              "影响压裂效果的指标权重分析：影响压裂效果（EUR）的多维地质工程参数的指标权重分析.\n" +
              "<br>" +
              "压裂产量EUR预测：基于给定的多维地质工程参数，对EUR的AI预测模型准确率78%.",
        },
        {
          img: require("../assets/logo3.png"),
          title: "智能采油气",
          text: "磕头机的生产冲次辅助决策：构建智能采油气机器人提供辅助决策.",
        },
        {
          img: require("../assets/logo4.png"),
          title: "地层监测",
          text: "基于光纤的井筒完整性监测：基于光纤DTS温度数据建立对井筒故障监测及位置预警的最小模型.",
        },
        {
          img: require("../assets/logo5.png"),
          title: "设备设施监测",
          text: "设备故障检测：基于开源的滚动轴承数据建立模型，实时判断轴承故障类型、故障大小、故障位置",
        },
      ],
      groupedItems: [],
      scrollY: 0,
      scrollHeight: 0,
      scrollTrue: true,
    };
  },
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn
  },
  computed: {
    // unconventional() {
    //   return unconventional
    // }
    // network() {
    //   return network
    // },
    // technology() {
    //   return technology
    // },

  },
  created() {
    this.getSearch()
    localStorage.setItem("home", 1)
    // 通用GPT
    if (sessionStorage.getItem("ground") === '1') {
      this.$nextTick(() => {
        this.position2();
      });
    }
    // 油气资讯
    else if (sessionStorage.getItem("ground") === '2') {
      this.$nextTick(() => {
        this.position7();
      });
    }
    // 底部
    else if (sessionStorage.getItem("ground") === '3') {
      this.$nextTick(() => {
        this.position6();
      });
    }
    // 油气专业GPTs
    else if (sessionStorage.getItem("ground") === '4') {
      this.$nextTick(() => {
        this.position3();
      });
    } else if (sessionStorage.getItem("ground") === '6') {
      this.$nextTick(() => {
        this.posHandle('generalAuto')
      });
    } else if (sessionStorage.getItem("ground") === '0') {
      this.$nextTick(() => {
        this.uptop();
      });
    }

  },

  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
    if (this.swiper2) {
      this.swiper2.destroy();
      this.swiper2 = null;
    }
    this.$refs.total.removeEventListener('scroll', this.handleScroll);

  },
  mounted() {
    this.updateModel();
    this.initSwiper();
    this.mouseSwiper()
    this.overSwiper()
    // 通用GPT
    if (sessionStorage.getItem("skip") === '1') {
      this.$nextTick(() => {
        this.skipp1();
      });
    }
    // 油气资讯
    else if (sessionStorage.getItem("skip") === '2') {
      this.$nextTick(() => {
        this.skipp2();
      });
    }
    // 底部
    else if (sessionStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
    // 油气专业GPTs
    else if (sessionStorage.getItem("skip") === '4') {
      this.$nextTick(() => {
        this.skipp4();
      });
    }
    // 油气专业GPTs
    else if (sessionStorage.getItem("skip") === '5') {
      this.$nextTick(() => {
        this.skipp5();
      });
    }
    // 油气开发作业GPT应用服务
    else if (sessionStorage.getItem("skip") === '6') {
      this.$nextTick(() => {
        this.skipp6();
      });
    } else if (sessionStorage.getItem("skip") === '7') {
      this.$nextTick(() => {
        this.skipp7();
      });
    } else if (sessionStorage.getItem("skip") === '8') {
      this.$nextTick(() => {
        this.skipp8();
      });
    } else if (sessionStorage.getItem("skip") === '10') {
      this.$nextTick(() => {
        this.skipp10();
      });
    } else if (sessionStorage.getItem("skip") === '11') {
      this.$nextTick(() => {
        this.skipp11();
      });
    } else if (sessionStorage.getItem("skip") === '12') {
      this.$nextTick(() => {
        this.skipp12();
      });
    } else if (sessionStorage.getItem("skip") === '13') {
      this.$nextTick(() => {
        this.skipp13();
      });
    } else if (sessionStorage.getItem("skip") === '14') {
      this.$nextTick(() => {
        this.skipp14();
      });
    }
    // 添加滚动事件监听器
    this.updateGroupedItems();
    this.mapExpert()
  },

  methods: {
    scrollToModule() {
      // 获取目标模块的元素
      const targetElement = document.getElementById('scrollmodelnew');
      if (targetElement) {
        // 使用 scrollIntoView 方法滚动到目标模块
        targetElement.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.log('Target module not found!');
      }
    },
           // 点击搜索图标时，显示弹框
           handleSearchClick() {
        if (this.keyTitle.trim()) {
          this.dynamicUrl = `https://www.oilgasinfoai.com/AIAnswer?question=${this.keyTitle}`;
          this.isDialogVisible = true; // 显示弹框
        } else {
         this.$message.error('Please enter search content');
        }
      },
          // 跳转到动态链接
    redirectToDynamicUrl() {
      window.location.href = this.dynamicUrl; // 跳转到动态链接
    },
      // 关闭弹框
      closeDialog() {
        this.isDialogVisible = false;
      },
    upkefu(){
      window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3589007934247681&webPageTitle=在线咨询")
    },
    yitihua(){
      window.open("https://www.ogdri.com/increase")
    },
    mapExpert(){
      this.expert.forEach((index,item)=>{
        console.log("__________",item)
        this.expert.push(index)
      })
    },
    upkefu2() {
      // window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3452726083343114&webPageTitle=在线咨询")
      window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3589007934247681&webPageTitle=在线咨询")
    },
    getSearch() {
      console.log( this.search.length)
      this.$axios
          .get("api/search/getThirdByName_IntroduceEn?keyword=" + this.keyTitle, {
            // .get("http://192.168.200.50:16542/search/getThirdByName_Introduce?keyword=" + this.keyTitle, {
            headers: {
              Authorization: "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjUyNjZiN2QxLTNhOWUtNDBiOC05MGJlLTNjMTU1OGM3YmI3YiJ9.OsIDjD4UL8AZHu5j5KO-26IQwJu6uVvu59KNM18vuLmjzXMJFl-lX2vyyw_4L1AXC6wot6lpxhv_JZUVe1LokQ",
            },
          })
          .then((res) => {
            console.log("search", res.data)
            this.search = []
            var searchdata = res.data.data.thirdPageList

            searchdata.forEach((item, index) => {
              console.log(res.data.data.keywordList[0]!=="")
              if(res.data.data.keywordList[0]!==""){
                item.leighttitle = this.highlightText(item.title,  res.data.data.keywordList);
                item.leightcontend = this.highlightText(item.introduce,  res.data.data.keywordList);
                this.$set(this.search, index, {
                  link: item.url,
                  title: item.leighttitle,
                  contend: item.leightcontend,
                });
              }
              else{
                this.$set(this.search, index, {
                  link: item.url,
                  title: item.title,
                  contend: item.introduce,
                });
              }


            })
            console.log("成都", this.search.length)
          })
          .catch((err) => {
            console.log(err);
          });
    },
    highlightText(text, keywords) {
      const regex = this.createHighlightRegex(keywords);
      return text.replace(regex, (match) => `<span style="color: #eb7c31">${match}</span>`);
    },
    createHighlightRegex(keywords) {
      const escapedKeywords = keywords.map(keyword => keyword.replace(/[/\\^$*+?.()|[\]{}]/g, '\\$&'));
      const regexPattern = escapedKeywords.join('|');
      return new RegExp(regexPattern, 'gi');
    },
    routerSkip(link) {
      window.open(link, "_blank");
    },
    mouseSwiper(index) {

      console.log("sss")
      this.swiperSlid = this.swiperSlid.map(() => 0);
      this.$set(this.swiperSlid, index, 1);
    },
    overSwiper(index) {
      this.$set(this.swiperSlid, index, 0);
      // if (this.swiperSlid.every(value => value === 0)) {
      //   this.swiperSlid[index] = 1;
      // }
    },
    getClass(index) {
      return this.swiperSlid[index] === 1 ? 'box' : 'box1';
    },
    updateModel() {
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      this.model = screenWidth <= 700;
    },
    initSwiper() {
      // 确保 swiper-container 已经被渲染
      this.$nextTick(() => {

        this.swiper = new Swiper(".swiper-containerpc", {
          autoplay: 3000,
          disableOnInteraction: true,
          slidesPerView: 3,
          spaceBetween: 25,
          loop: false,
          pagination: ".swiper-pagination",
          paginationType: "bullets",
          paginationClickable: true,
          pauseOnMouseEnter: true, // 鼠标悬停时暂停自动播放
        });
        this.swiper2 = new Swiper(".swiper-containerpc2", {
          autoplay: false,
          disableOnInteraction: true,
          slidesPerView: 'auto',
          spaceBetween: 5,
          loop: false,
          pagination: ".swiper-pagination",
          paginationType: "bullets",
          paginationClickable: true,
          pauseOnMouseEnter: true, // 鼠标悬停时暂停自动播放
        });
        this.swiper = new Swiper(".swiper-container0", {
          autoplay: 3000,
          disableOnInteraction: true,
          slidesPerView: 1.2,
          spaceBetween: 10,
          loop: false,
          pagination: ".swiper-pagination",
          paginationType: "bullets",
          paginationClickable: true,
          pauseOnMouseEnter: true, // 鼠标悬停时暂停自动播放
        });

        this.swiper = new Swiper(".swiper-container1", {
          autoplay: 3000,
          disableOnInteraction: true,
          slidesPerView: 1.2,
          spaceBetween: 10,
          loop: false,
          pagination: ".swiper-pagination",
          paginationType: "bullets",
          paginationClickable: true,
          pauseOnMouseEnter: true, // 鼠标悬停时暂停自动播放
        });

      });
    },
    login() {
      this.$router.push("/login")
    },
    login1() {
      window.open("/login")
    },
    about(){
      window.open("/about")
    },
    showComment() {
      this.dialogComment = true
      // console.log("chufa")
    },
    // showProduct() {
    //   this.dialogProduct = true
    // },
    showProduct(emit) {
      console.log("emit123123123", emit)
      if(emit){
        this.dialogProduct = true
      }else{
        window.open("/netFeedback")
        // this.dialogProduct = true
      }
     
    },
    showNet() {
      this.dialogNet = true
    },
    hideComment() {
      localStorage.setItem("skip", 0)
      this.dialogComment = false;
    },
    hideProduct() {
      localStorage.setItem("skip", 0)
      this.dialogProduct = false;
    },
    hideNet() {
      localStorage.setItem("skip", 0)
      this.dialogNet = false;
    },
    fibernew() {
      window.open("fibernew")
    },
    eur() {
      window.open("eur")
    },
    plug() {
      window.open("plug")
    },
    recog() {
      window.open("recognition")
    },
    posHandle() {
      let ref = this.model ? 'generalAutoMobile' : 'generalAuto'
      let id = this.model ? 'totalModel' : 'total'
      let element = this.$refs[ref];
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      document.querySelector('.' + id)
          .scroll({
            top: rect,
            behavior: 'smooth'
          })
    },
    distribute() {
      return distribute
    },

    handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
    },

    clickConsult(item) {
      this.title = item
      this.dialogVisible = true;
      sessionStorage.setItem('title', item)
    },
    show() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = true;
    },
    comment() {
      window.open("/geoevaluation", "_blank");
    },
    exploit() {
      window.open("/schemedesign", "_blank");
    },
    intelligent() {
      window.open("/intelligentengineering", "_blank");
    },
    connect() {
      window.open("/potentialtapping", "_blank");
    },
    special() {
      window.open("/specialengineering", "_blank");
    },
    forecast() {
      window.open("/featuretech", "_blank");
    },
    ground() {
      window.open("/ground", "_blank");
    },
    modeling() {
      window.open("/modeling", "_blank");
    },
    single() {
      window.open("/integratedstimulation", "_blank");
    },
    integration() {
      window.open("/featuredproducts", "_blank");
    },
    risk() {
      window.open("/stimulationmonitoring", "_blank");
    },
    unconventional() {
      window.open("/unconventional", "_blank");
    },
    drill() {
      window.open("/drill", "_blank");
    },
    realtime() {
      window.open("/realtime", "_blank");
    },
    area() {
      window.open("/area", "_blank");
    },
    unite() {
      window.open("/unite", "_blank");
    },
    scene() {
      window.open("/scene", "_blank");
    },
    increase() {
      window.open("/increase", "_blank");
    },
    distributevue() {
      window.open("/FiberOptic", "_blank");
    },
    gasreservoir() {
      window.open("/logging", "_blank");
    },
    number() {
      window.open("/digital", "_blank");
    },


    gpts() {
      localStorage.setItem("skip", 0)
      window.open("https://www.oilgascommunity.com/recently?id=39", "_blank");
    },
    // 油藏地质
    // ground() {
    //   localStorage.setItem("skip", 0)
    //   window.open("/groundOil", "_blank");
    // },
    oilgpt() {
      localStorage.setItem("skip", 0)
      window.open("/gptOil", "_blank");
    },
    // 钻井
    hole() {
      localStorage.setItem("skip", 0)
      window.open("/makeHole", "_blank");
    },
    oilDesign() {
      localStorage.setItem("skip", 0)
      window.open("/gptDesign", "_blank");
    },
    oilTranslate() {
      localStorage.setItem("skip", 0)
      window.open("/gptTranslate", "_blank");
    },
    oilTechnology() {
      localStorage.setItem("skip", 0)
      window.open("/gptTechnology", "_blank");
    },
    oilgpt2() {
      localStorage.setItem("skip", 0)
      window.open("/gptTranslate", "_blank");
    },
    // 压裂
    frack() {
      localStorage.setItem("skip", 0)
      window.open("/frackOil", "_blank");
    },
    oilgpt3() {
      localStorage.setItem("skip", 0)
      window.open("/gptHole", "_blank");
    },
    // 采油
    getOil() {
      localStorage.setItem("skip", 0)
      window.open("/getOil", "_blank");
    },

    // <!--设备检测-->
    equipment() {
      localStorage.setItem("skip", 0)
      window.open("/monitorEqu", "_blank");
    },

    // 地层监测
    monitor() {
      localStorage.setItem("skip", 0)
      window.open("/monitorOil", "_blank");
    },

    // 油田管理
    menage() {
      localStorage.setItem("skip", 0)
      window.open("/menageOil", "_blank");
    },

    station() {
      localStorage.setItem("skip", 0)
      window.open("/station", "_blank");
    },
    security() {
      localStorage.setItem("skip", 0)
      window.open("/security", "_blank");
    },
    productANxun() {
      localStorage.setItem("skip", 0)
      window.open("/product", "_blank");
    },
    equipmentAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/equipmentAnxun", "_blank");
    },
    technologyAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/technology", "_blank");
    },
    monitorAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/monitorAnxun", "_blank");
    },
    networkAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/network", "_blank");
    },
    basic() {
      localStorage.setItem("skip", 0)
      window.open("/basic", "_blank");
    },
    setting() {
      localStorage.setItem("skip", 0)
      window.open("/setting", "_blank");
    },
    traning() {
      const element = this.$refs.aiPeixun;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    governance() {
      window.open("/governance", "_blank");
    },
    analysis() {
      window.open("/analysis", "_blank");
    },
    traningAi() {
      window.open("/traning", "_blank");
    },

    consult() {
      window.open("/consult", "_blank");
    },
    ERP() {
      window.open("/ERP", "_blank");
    },
    java() {
      window.open("/java", "_blank");
    },
    web() {
      window.open("/web", "_blank");
    },
    service() {
      const element = this.$refs.oilZixun;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    meitiProduct() {
      window.open("http://www.oilmates-omm.com/h-col-106.html", "_blank");
    },
    meiti3D() {
      window.open("http://www.oilmates-omm.com/h-col-102.html", "_blank");
    },
    meitiDesign() {
      window.open("http://www.oilmates-omm.com/h-col-104.html", "_blank");
    },
    construct() {
      const element = this.$refs.companyZijian;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    constructPlat() {
      window.open("/construct", "_blank");
    },
    business() {
      window.open("/business", "_blank");
    },
    company() {
      window.open("/company", "_blank");
    },
    computingPower() {
      window.open("/computingPower", "_blank");
    },

    menageService() {
      const element = this.$refs.menageService;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    application() {
      window.open("/application", "_blank");
    },
    oilApplication() {
      window.open("/oilApplication", "_blank");
    },

    knowledge() {
      window.open("/gptKnowledge", "_blank");
    },
    data() {
      window.open("/gptData", "_blank");
    },
    wisdom() {
      window.open("/wisdom", "_blank");
    },
    plat() {
      window.open("/plat", "_blank");
    },
    design() {
      window.open("/design", "_blank");
    },
    automation() {
      window.open("/automation", "_blank");
    },

    traning1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/traning")
    },
    service1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/service")
    },
    construct1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/construct")
    },
    menageService1() {
      const element = this.$refs.menageServices;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    application1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/application")
    },
    oilApplication1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/oilApplication")
    },

    knowledge1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/gptKnowledge")
    },
    data1() {
      this.$router.push("/gptData")
      sessionStorage.setItem("skip", 10)
    },
    wisdom1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/wisdom");
    },
    plat1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/plat")
    },
    design1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/design")
    },
    automation1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/automation")
    },
    thinreservoir(){
      window.open("/thinreservoir", "_blank");
    },
    gpts1() {
      window.open("https://www.oilgascommunity.com/recently?id=39", "_blank");
    },
    // 油藏地质
    ground1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/groundOil")
    },
    oilgpt1() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptOil")
    },
    // 钻井
    hole1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/makeHole")
    },

    oilgpt21() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptTranslate")
    },
    // 压裂
    frack1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/frackOil")
    },
    oilgpt31() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptHole")
    },
    // 采油
    getOil1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/getOil")
    },

    // <!--设备检测-->
    equipment1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/monitorEqu")
    },

    // 地层监测
    monitor1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/monitorOil")
    },

    // 油田管理
    menage1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/menageOil")
    },

    station1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/station")
    },
    security1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/security")
    },
    productANxun1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/product")
    },
    equipmentAnxun1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/equipmentAnxun")
    },
    technologyAnxun1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/technology")
    },
    monitorAnxun1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/monitorAnxun")
    },
    networkAnxun1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/network")
    },
    basic1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/basic")
    },
    setting1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/setting")
    },
    showPartner() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = true;
    },
    scroll(value) {
      console.log(value)
      // 通用GPT
      if (value == 1) {
        this.$nextTick(() => {
          console.log("7889459")
          this.position2();
        });
      } else if (value == 2) {
        this.$nextTick(() => {
          this.position7();
        });
      } else if (value == 3) {
        this.$nextTick(() => {
          this.position6();
        });
      } else if (value == 4) {
        this.$nextTick(() => {
          this.position3();
        });
      } else if (value == 6) {
        this.$nextTick(() => {
          this.posHandle('generalAuto')
        });
      } else if (value == 0) {
        this.$nextTick(() => {
          this.uptop();
        });
      }
    },
    scroll1(value) {
      console.log(value)
      // 通用GPT
      if (value == 1) {
        this.$nextTick(() => {
          console.log("7889459")
          if (this.model === false) {
            console.log("7889459")

            const element = this.$refs.generalMove;
            const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

            console.log(rect)
            window.scrollTo({
              top: 700,
              behavior: 'smooth'
            });


          } else {
            // console.log("1545")
            const element = this.$refs.generalMoveMobile;
            const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

            console.log(rect)
            window.scrollTo({
              top: 300,
              behavior: 'smooth'
            });


          }
        });
      } else if (value == 2) {
        this.$nextTick(() => {
          if (this.model === false) {
            console.log("7889459")

            const element = this.$refs.generalMove;
            const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

            console.log(rect)
            window.scrollTo({
              top: 2400,
              behavior: 'smooth'
            });


          } else {
            // console.log("1545")
            const element = this.$refs.generalMoveMobile;
            const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

            console.log(rect)
            window.scrollTo({
              top: 1100,
              behavior: 'smooth'
            });


          }
        });
      } else if (value == 3) {
        this.$nextTick(() => {
          if (this.model === false) {
            console.log("7889459")

            const element = this.$refs.generalMove;
            const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

            console.log(rect)
            window.scrollTo({
              top: 1600,
              behavior: 'smooth'
            });


          } else {
            // console.log("1545")
            const element = this.$refs.generalMoveMobile;
            const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

            console.log(rect)
            window.scrollTo({
              top: 800,
              behavior: 'smooth'
            });


          }
        });
      } else if (value == 4) {
        this.$nextTick(() => {
          this.position3();
        });
      } else if (value == 6) {
        this.$nextTick(() => {
          this.posHandle('generalAuto')
        });
      } else if (value == 0) {
        this.$nextTick(() => {
          this.uptop();
        });
      }
    },
    scrollskip(value) {
      console.log(value)
      // 通用GPT
      if (value == 1) {
        this.$nextTick(() => {
          this.position2();
        });
      } else if (value == 2) {
        this.$nextTick(() => {
          this.position7();
        });
      } else if (value == 3) {
        this.$nextTick(() => {
          this.position6();
        });
      } else if (value == 4) {
        this.$nextTick(() => {
          this.position3();
        });
      } else if (value == 0) {
        this.$nextTick(() => {
          this.uptop();
        });
      }
    },
    hideDialog() {
      localStorage.setItem("skip", 0)
      this.dialogVisible = false;
    },
    hide() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = false;
    },
    expo() {
      localStorage.setItem("skip", 0)
      window.open("https://cn.oilgasmall.com/", "_blank");
    },
    job() {
      localStorage.setItem("skip", 0)
      window.open("https://oilgasjobai.com/", "_blank");
    },
    info() {
      localStorage.setItem("skip", 0)
      window.open("https://cn.oilgasinfoai.com/", "_blank");
    },
    wen() {
      localStorage.setItem("skip", 0)
      window.open("https://yiyan.baidu.com/", "_blank");
    },
    dou() {
      localStorage.setItem("skip", 0)
      window.open("https://www.doubao.com/", "_blank");
    },
    xun() {
      localStorage.setItem("skip", 0)
      window.open("https://xinghuo.xfyun.cn/", "_blank");
    },
    tong() {
      localStorage.setItem("skip", 0)
      window.open("https://tongyi.aliyun.com/qianwen/", "_blank");
    },
    zhi() {
      localStorage.setItem("skip", 0)
      window.open("https://chatglm.cn/", "_blank");
    },
    teng() {
      localStorage.setItem("skip", 0)
      window.open("https://hunyuan.tencent.com/", "_blank");
    },
    tian() {
      localStorage.setItem("skip", 0)
      window.open("https://home.tiangong.cn/", "_blank");
    },
    ge() {
      localStorage.setItem("skip", 0)
      window.open("https://yige.baidu.com/", "_blank");
    },
    wan() {
      localStorage.setItem("skip", 0)
      window.open("https://ai.kezhan365.com/", "_blank");
    },
    yi() {
      localStorage.setItem("skip", 0)
      window.open("https://aigc.yizhentv.com/", "_blank");
    },


    expo1() {
      sessionStorage.setItem("skip", 2)
      // 在 Vue 组件或路由导航守卫中使用 router.push() 导航到外部链接
      const externalLink = 'https://cn.oilgasmall.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    job1() {
      sessionStorage.setItem("skip", 2)
      const externalLink = 'https://oilgasjobai.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    info1() {
      sessionStorage.setItem("skip", 2)
      const externalLink = 'https://cn.oilgasinfoai.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    wen1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://yiyan.baidu.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    traningAi1() {
      sessionStorage.setItem("skip", 12)
      this.$router.push("/traning")
    },
    consult1() {
      sessionStorage.setItem("skip", 12)
      this.$router.push("/consult")
    },
    constructPlat1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/construct")

    },
    business1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/business")
    },
    company1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/company")
    },
    computingPower1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/computingPower")
    },
    inter() {
      window.open("/inter")
    },
    position2() {
      if (this.model === false) {
        console.log("7889459")

        const element = this.$refs.generalMove;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        console.log(rect)
        window.scrollTo({
          top: rect,
          behavior: 'smooth'
        });


      } else {
        // console.log("1545")
        const element = this.$refs.generalMoveMobile;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        console.log(rect)
        window.scrollTo({
          top: rect,
          behavior: 'smooth'
        });


      }
    },
    position3() {
      if (this.model === false) {
        const element = this.$refs.aiPeixun;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
      } else {
        const element = this.$refs.aiPeixunMobile;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.totalModel, rect, 500); // 滚动持续时间为500毫秒
      }
    },
    position6() {
      if (this.model === false) {
        const element = this.$refs.consultMove;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        console.log(rect)
        window.scrollTo({
          top: rect - 20,
          behavior: 'smooth'
        });

      } else {


        const element = this.$refs.consultMoveMobile;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        console.log(rect)
        window.scrollTo({
          top: rect - 20,
          behavior: 'smooth'
        });

      }

    },
    position7() {
      if (this.model === false) {

        const element = this.$refs.professional;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        console.log(rect)
        window.scrollTo({
          top: rect - 20,
          behavior: 'smooth'
        });


      } else {

        const element = this.$refs.seriesService;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        console.log(rect)
        window.scrollTo({
          top: rect,
          behavior: 'smooth'
        });


      }

    },
    position8() {
      if (this.model === false) {
        const element = this.$refs.generalAuto;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
      } else {
        const element = this.$refs.professionalMobile;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.totalModel, rect, 500); // 滚动持续时间为500毫秒
      }

    },
    skipp1() {
      const element = this.$refs.general;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp2() {
      const element = this.$refs.zixun;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp4() {
      const element = this.$refs.professional;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    skipp5() {
      const element = this.$refs.professional1;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp6() {
      const element = this.$refs.upload;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp7() {
      const element = this.$refs.station;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp8() {
      const element = this.$refs.technology;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp10() {
      const element = this.$refs.menageServices;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    uptop() {
      console.log("zhiding11",this.isPC)
      if (this.model === false) {
        const element = this.$refs.total;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
        console.log("zhiding",rect)
        this.$nextTick(() => {
          // this.$refs.total.scrollTop = rect
          window.scrollTo({ top: 0, behavior: 'auto' }); // 滚动到页面顶部
        });
      } else {
        const elementModel = this.$refs.totalModel;
        const rect = elementModel.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
        console.log("zhiding",rect)
        this.$nextTick(() => {
          // this.$refs.totalModel.scrollTop = rect
          window.scrollTo({ top: 0, behavior: 'auto' }); 
        });
      }
    },
    skipp11() {
      const element = this.$refs.company;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp12() {
      const element = this.$refs.traningConsult;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp13() {
      const element = this.$refs.companyConduct;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp14() {
      const element = this.$refs.media;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    dou1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://www.doubao.com/';
      this.$router.replace({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    xun1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://xinghuo.xfyun.cn/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    tong1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://tongyi.aliyun.com/qianwen/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    zhi1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://chatglm.cn/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    teng1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://hunyuan.tencent.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    tian1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://home.tiangong.cn/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    ge1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://yige.baidu.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    wan1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://ai.kezhan365.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    yi1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://aigc.yizhentv.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    meitiProduct1() {
      sessionStorage.setItem("skip", 14)
      const externalLink = 'http://www.oilmates-omm.com/h-col-106.html';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    meiti3D1() {
      sessionStorage.setItem("skip", 14)
      const externalLink = 'http://www.oilmates-omm.com/h-col-102.html';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    meitiDesign1() {
      sessionStorage.setItem("skip", 14)
      const externalLink = 'http://www.oilmates-omm.com/h-col-104.html';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    updateGroupedItems() {
      const startIndex = this.currentIndex;
      this.groupedItems = [];

      for (let i = 0; i < this.itemsPerPage; i++) {
        const group = this.carouselItems.slice(
            startIndex + i,
            startIndex + i + this.itemsPerPage
        );
        this.groupedItems.push(group);
      }
    },
    leftnext() {
      console.log(this.currentIndex);
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.updateGroupedItems();
      }
    },
    rightnext() {
      if (this.currentIndex < 1) {
        this.currentIndex++;
        this.updateGroupedItems();
      }
    },
    onCarouselChange(index) {
      this.currentIndex = index;
      this.updateGroupedItems();
    },
  },
};
</script>
<style>
.searchBack {
//background-color: rgba(102, 102, 102, 0.2);
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  column-gap: 1.5vw;
  row-gap: 2vh;
  padding: 3vh 2vw;
  list-style-type: none;
}
.searchBackno{
  display: flex;
  padding: 3vh 2vw;
  font-weight: bold;
//justify-content: center;
//align-items: center;
}
.searchContent {
  cursor: pointer;
//background: #e5ebfa;
  border: white 1px solid;
  background-color: white;
  box-shadow: 5px 0 14px 0px #e5ebfa;
  padding: 2vh 1.5vw;
  height: 100%;
  border-radius: 9px;
//color: white;

  .title1 {
    font-size: 1vw;
    text-align: left;
    white-space: pre-wrap;
    word-break: break-word;
    font-weight: bold;
    line-height: 3.5vh;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title2 {
    font-size: 0.8vw;
    line-height: 2.8vh;
    text-align: left;
    white-space: pre-wrap;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
    overflow: hidden;
    text-overflow: ellipsis;
    height:5.6vh ;
  }
}

.searchContent:hover {
  border: #9b85f4 1px solid;


}
@media (max-width: 757px) {
  .el-message-box {
    width: 300px !important;
    margin: 100px auto !important; /* 水平居中 */
  }

  .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
    margin-top: 0;
  }

  .swiper-pagination {
    position: relative;
  }

  .swiper-pagination-bullet {
    width: 25px;
    height: 5px;
    border-radius: 9px;
  }

  .swiper-pagination-bullet-active {
    background-color: rgb(47, 110, 255);
  }
}


.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0;
  margin-top: 2vh;
}

.swiper-pagination {
  position: relative;
}

.swiper-pagination-bullet {
  width: 25px;
  border-radius: 9px;
}

.swiper-pagination-bullet-active {
  background-color: rgb(47, 110, 255);
}
/* 自定义紫色背景 */
.purple-dialog .el-dialog {
  /* background-color: #6a4c9c;  */
  background-color: rgb(8 ,78 ,138,1);
  color: white; /* 白色文本，并加上 !important 强制覆盖 */
  border-radius: 15px ; /* 添加圆角效果 */
}

/* 自定义遮罩背景颜色 */
/* .purple-dialog .el-dialog__wrapper {
  background-color: rgba(106, 76, 156, 0.8) ; 
} */
</style>
<style lang="scss" scoped>
.avator {
  border: 2px solid #fff;
}

.userName {
  color: #2f6eff;
  font-size: 16px;
  line-height: 1.3;;
  margin-left: 10px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.total {
  // background: #bed8f9;
  background: white;
  overflow-x: hidden;
  width: 100%;
  //height: 100vh;
  position: relative;
  .run-enter-active {
    animation: fadeInLeft 0.8s linear 0s;
  }

  .run-leave-active {
    animation: fadeInLeft 0.8s linear 0s;
  }

  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    overflow-x: hidden;
    height: 100vh;
    padding: 0 0 2.84301606922126vh 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;

    .topback {
      position: fixed;
      z-index: 9;
      width: 99vw;
      padding: 1vh 0;
      background-color: rgb(52, 48, 48, 0.3);
    }

    .up {
      cursor: pointer;
      position: fixed;
      top: 90%;
      right: 2%;
      padding: 5px;
      width: 2.5vw;
      height: 2.5vw;
      border-radius: 50%;
      //border: 5px solid #1295d9;
      background-image: url("../assets/up.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
    }

    .layer2 {
      // margin: 0vh auto auto 8vw;
      margin: 0vh auto ;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60vh;

      .title1 {
        // width:fit-content;
        // transition: transform 0.3s ease-out;
        // padding: 16vh 0;
        // height: fit-content;
        // font-size: 3vw;
        // font-weight: bold;
        // font-family: Roboto, sans-serif;
        // color:#f8f8f8;
        // line-height: 1.5;
        // text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.7); /* 添加阴影 */
        // margin-top:18vh;
        margin: 40vh 0 6vh 0;
        font-weight: 600;
        font-size: 4vw;
        color: #FFFFFF;
        line-height: 5vw;
        text-align: center;
        font-style: normal;
        transition: transform 0.3s ease-out;
        height: fit-content;
        font-family: PingFangSC, PingFang SC;
        text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.7); /* 添加阴影 */
        .titlenew1{
          font-size: 3.8vw;
          // font-weight: bold;
          color:#f8f8f8;
          margin-top:1.5vw;
          // line-height: 1.5;
          font-family: Roboto, sans-serif;
          text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.7); /* 添加阴影 */
        }
      .titlenew{
          font-size: 2vw;
          font-weight: bold;
          // color: white;
          // color:#15257b;
          color:#f8f8f8;
          margin-top:1.3vw;
          font-family: Roboto, sans-serif;
          text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.7); /* 添加阴影 */
      }
        .title2 {
          margin-top: 2vh;
          font-size: 1.6vw;
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
          font-weight: bold;
          color: white;
          line-height: 5vh;
          width: 35vw;
        }
      }


      .title31 {
        margin-top: 5vh;
        transition: transform 0.3s ease-out, height 0.5s ease-out;
        cursor: pointer;
        width: 35vw;
        display: flex;

        font-size: 2vw;
        font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: bold;
        color: #13237a;
        line-height: 5vh;
        overflow: hidden; /* 隐藏溢出的内容 */
        .hoverImg {
          height: 2vw;
          min-width: 3vw;
          margin: 2vh 0 1vh 1vw;
        }

        .hoverTitleup {
          display: flex;
          align-items: center;
          height: 8vh;
          margin: 0 auto 0 1vw;
          width: fit-content;
          text-align: left;
          cursor: pointer;
          transition: transform 0.3s ease-out;
          font-size: 1.5vw;
          font-weight: bold;
        }

        .hoverTitleup2 {
          display: flex;
          align-items: center;
          margin: 0 auto 1vh auto;
          text-align: left;
          cursor: pointer;
          transition: max-height 0.5s ease-out, opacity 0.5s ease-out; /* 添加高度和透明度过渡 */
          font-size: 1.1vw;
          font-weight: bold;
          opacity: 0;
          max-height: 0; /* 初始最大高度为0 */
          overflow: hidden; /* 隐藏溢出的内容 */
        }

        .hoverTitle {
          display: flex;
          margin-right: 2vw;
          cursor: pointer;
          font-weight: normal;
          transition: transform 0.3s ease-out;
        }
      }

      .title31:hover {
        background-image: url("../assets/imgback.png");
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent;
        border-radius: 9px;
        height: fit-content; /* 悬停时高度增加 */
        .hoverTitleup2 {
          display: flex;
          //opacity: 1; /* 悬停时变为完全不透明 */
          //max-height: 15vh; /* 悬停时的最大高度，根据实际内容调整 */
        }
      }

      .title32 {
        position: absolute;
        bottom: 2vh;
        left: 49.5%;
        transform: translateX(-50%);
        transition: transform 0.3s ease-out, height 0.5s ease-out;
        cursor: pointer;
        width: 25vw;
        padding: 2vh 2vw;
        font-size: 1.1vw;
        font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: bold;
        color: #13237a;
        line-height: 4vh;
        overflow: hidden; /* 隐藏溢出的内容 */
        .hoverImg {
          height: 3vw;
          width: fit-content;
          margin: 2vh auto 1vh auto;
        }

        .hoverTitleup {
          display: flex;
          height: 8vh;
          align-items: center;
          justify-content: center;
          margin: 0 auto 0 auto;
          text-align: center;
          cursor: pointer;
          transition: transform 0.3s ease-out;
          font-size: 1.3vw;
          font-weight: bold;
        }

        .hoverTitleup2 {
          display: flex;
          align-items: center;
          margin: 0 auto 1vh auto;
          text-align: left;
          cursor: pointer;
          transition: max-height 0.5s ease-out, opacity 0.5s ease-out; /* 添加高度和透明度过渡 */
          font-size: 1.1vw;
          font-weight: bold;
          opacity: 0;
          max-height: 0; /* 初始最大高度为0 */
          overflow: hidden; /* 隐藏溢出的内容 */
        }

        .hoverTitle {
          display: flex;
          margin-right: 2vw;
          cursor: pointer;
          font-weight: normal;
          transition: transform 0.3s ease-out;
        }
      }

      .title32:hover {
        background-image: url("../assets/imgback.png");
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent;
        border-radius: 9px;
        height: fit-content; /* 悬停时高度增加 */
        .hoverTitleup2 {
          display: flex;
          opacity: 1; /* 悬停时变为完全不透明 */
          max-height: 15vh; /* 悬停时的最大高度，根据实际内容调整 */
        }
      }

      .title33 {
        position: absolute;
        bottom: 2vh;
        right: 12vw;
        transition: transform 0.3s ease-out, height 0.5s ease-out;
        cursor: pointer;
        width: 25vw;
        padding: 2vh 2vw;
        font-size: 1.1vw;
        font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: bold;
        color: #13237a;
        line-height: 4vh;
        overflow: hidden; /* 隐藏溢出的内容 */
        .hoverImg {
          height: 3vw;
          width: fit-content;
          margin: 2vh auto 1vh auto;
        }

        .hoverTitleup {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto 0 auto;
          text-align: center;
          cursor: pointer;
          height: 8vh;
          transition: transform 0.3s ease-out;
          font-size: 1.3vw;
          font-weight: bold;
        }

        .hoverTitleup2 {
          display: flex;
          align-items: center;
          margin: 0 auto 1vh auto;
          text-align: left;
          cursor: pointer;
          transition: max-height 0.5s ease-out, opacity 0.5s ease-out; /* 添加高度和透明度过渡 */
          font-size: 1.1vw;
          font-weight: bold;
          opacity: 0;
          max-height: 0; /* 初始最大高度为0 */
          overflow: hidden; /* 隐藏溢出的内容 */
        }

        .hoverTitle {
          display: flex;
          margin-right: 2vw;
          cursor: pointer;
          font-weight: normal;
          transition: transform 0.3s ease-out;
        }
      }

      .title33:hover {
        background-image: url("../assets/imgback.png");
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent;
        border-radius: 9px;
        height: fit-content; /* 悬停时高度增加 */
        .hoverTitleup2 {
          display: flex;
          opacity: 1; /* 悬停时变为完全不透明 */
          max-height: 15vh; /* 悬停时的最大高度，根据实际内容调整 */
        }
      }
    }
    .titlenew-content{
       display: flex;
       flex-wrap: wrap;  /* 如果你有多个项，允许换行 */
      //  justify-content: space-between; /* 分布布局，可以调整 */
      justify-content: space-around; /* 分布布局，可以调整 */
      //  gap: 20px; /* 元素之间的间距 *
    }
    .titlenew-content > div {
    display: flex;
    flex-direction: column; /* 垂直排列图标和文字 */
    align-items: center; /* 使图标和文字水平居中 */
    cursor: pointer; /* 点击时显示手形光标 */
}
        .Img{
          width: 60px;  /* 设置图标的固定宽度 */
          height: 60px; /* 设置图标的固定高度 */
        }
        .Img img {
    width: 100%;  /* 让图标充满其容器 */
    height: 100%; /* 让图标充满其容器 */
    object-fit: contain; /* 保持图标的比例 */
}
        .Img-title{
          font-size: 1.3vw; /* 文字大小，可以根据需要调整 */
         font-weight: bold;
         text-align: center; /* 文字居中 */
         color:#f8f8f8;
        line-height: 5vh;
        text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.7); /* 添加阴影 */
        transition: color 0.3s, transform 0.3s;
        }
.titlenew-content:hover .Img-title {
  color: #15257b; /* 鼠标悬停时文字颜色变化 */
  transform: translateX(5px); /* 鼠标悬停时文字平移效果 */
}
        .vertical-line {
       width: 1px; /* 设置宽度为1px */
       height: 100%; /* 使其高度与父容器相同 */
       background-color: white; /* 设置背景颜色为白色 */
}
      .vertical-line::after {
       content: ''; /* 必须设置content，即使内容为空 */
        display: block; /* 将伪元素转换为块级元素 */
        width: 2px; /* 设置宽度为1px */
        height: 10%; /* 使其高度与父容器相同 */
        background-color: white; /* 设置背景颜色为白色 */
        position: absolute; /* 绝对定位 */
        left: 50%; /* 将伪元素水平居中 */
        transform: translateX(-50%); /* 通过平移将其居中 */
}

    .layer3 {
      position: absolute;
      bottom: 6vh;
      left: 50%;
      transform: translateX(-50%);
      width: 40vw;
      background-size: 100% auto;
      background-position: center;
      background-repeat: no-repeat;
      background-color: transparent;
      opacity: 1;

      .search {
        display: flex;
        border-radius: 53px;
        width: 40vw;;
        height: 7vh;
        background-color: white;
        margin-left: 2vw;

        ::v-deep .el-input {
          border-radius: 50%;
          height: 7vh;
          width: 40vw;
          font-size: 1.2vw;
        }

        ::v-deep .el-input__inner {
          border-radius: 50%;
          height: 7vh !important;
          border: white 1px solid !important;
          //background-color: transparent !important;
          background-color: transparent !important;
        }

      }

    }
    .hezuo{
        display: flex;
        justify-content: center;
        align-items: center;
        .titlenew1{
          background-color: #3365f0;
        color:#f8f8f8;
        font-family: Roboto, sans-serif;
        text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.7); /* 添加阴影 */
        padding:10px 40px;
        border-radius: 40px;
        transition: all 0.3s ease; /* 平滑过渡 */
      }
      .titlenew2{
        margin-top: 3vw;
        margin-left: 6vw;
        color:#f8f8f8;
        font-family: Roboto, sans-serif;
        text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.7); /* 添加阴影 */
        padding:10px 40px;
        border:2px solid white;
        border-radius: 40px;
        transition: all 0.3s ease; /* 平滑过渡 */
      }
      .titlenew1:hover, .titlenew2:hover {
        background-color: white; /* 背景变为白色 */
        color: #15257b !important; /* 字体变为黑色 */
        text-shadow: none; /* 去除文字阴影 */
    // border-color: #000; /* 边框颜色变为黑色 */
     }

  /* 可选：添加渐变效果 */
     .titlenew1:hover, .titlenew2:hover {
    background: linear-gradient(45deg, #ffffff, #e0e0e0); /* 背景渐变 */
}
  }
}
  

  .screenFace {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 11vh 11.09763142692085vw 11vh 8.09763142692085vw;
    background-image: url("../assets/faceBack.png");
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;

    .title {
      height: fit-content;
      margin: 0 auto;
      text-align: center;
      opacity: 1;
      font-size: 3vw;
      color: white;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700;
      line-height: 10vh
    }

    .light {
      margin-top: 3vh;
      text-align: left;
      width: 50vw;
      height: fit-content;
      font-size: 1.8vw;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 400;
      color: #15257b;
      line-height: 6vh;
    }

    .pro2 {
      height: fit-content;
      padding-top: 15vh;
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      overflow-x: hidden;
      //z-index: 89156;

      .grand1 {
        width: 100%;
        padding: 1vh 1vw 2vh 1vw;
        opacity: 1;
        position: relative;

        height: fit-content;

        .back {
          width: 100%;
          //margin:-8vh auto 0 0;
          height: 100%;
          border-radius: 9px;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          width: 100%;
          height: fit-content;

          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          padding: 1vh 5%;
          justify-content: space-between;
        }

        .photo {
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 6vw;
          display: flex;
          margin: 0 auto;
          height: 6vw;
          position: relative;
        }

        .photohover {
          position: absolute;
          bottom: 0;
          left: 0; /* 初始位置在容器左侧外面 */
          width: 0%; /* 初始宽度为0 */
          height: 2vh;
          background-color: #2f6dfd;
          transition: width 0.5s ease; /* 平滑过渡效果 */
        }

        .photolist {
          font-size: 4.5vw;
          color: #e3e8ed;
          margin: auto 1vw 0 1vw;
          height: 100%;
          font-weight: bold;
        }

        .title1 {
          margin-top:6vh;
          text-align: center;
          font-size: 1.5vw;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;
          overflow: hidden;
          height: fit-content;
          line-height: 5.5vh;
        }

        .title2 {
          display: flex;

          opacity: 4;
          width: fit-content;
          height: fit-content;
          // text-align: justify;
          font-size: 0.9vw;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          color: white;
          margin-top: 2vh;
          border-radius: 23px;
          padding: 5px 15px;
          background-color: #2168DB;
          //line-height: 35px;
        }
      }

      .grand1:hover {
        .photohover {
          width: 100%; /* 鼠标悬浮时宽度变为100% */
        }
      }

    }

    //.pro2 {
    //  height: fit-content;
    //  width: 100%;
    //  margin-top: 10vh;
    //  //z-index: 89156;
    //  display: grid;
    //  grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
    //  row-gap:10vh;
    //  column-gap: 1vw;
    //  .grand1 {
    //    cursor: pointer;
    //    width: 100%;
    //    border-bottom-right-radius: 53px;
    //    border-top-left-radius: 9px;
    //    border-top-right-radius: 9px;
    //    border-bottom-left-radius: 9px;
    //
    //    padding: 1vh 1vw;
    //    opacity: 1;
    //    position: relative;
    //    background-image: url("../assets/groundBack.png");
    //    background-position: left;
    //    background-size: cover;
    //    background-repeat: no-repeat;
    //    background-color: transparent;
    //    height: fit-content;
    //    .back {
    //      width: 100%;
    //      margin:-8vh auto 0 0;
    //      height:fit-content;
    //      border-radius: 9px;
    //      // border-radius:6px;
    //      // display: flex;
    //    }
    //
    //    .back1 {
    //      width: 100%;
    //      height:fit-content;
    //      margin-top:1vh;
    //      border-top: none;
    //      border-bottom-right-radius: 9px;
    //      border-bottom-left-radius: 9px;
    //      // border-radius:6px;
    //      padding: 1vh 1vw;
    //      justify-content: space-between;
    //    }
    //
    //    .photo {
    //      position: relative;
    //      //background-image: url("../assets/pro1.jpg");
    //      background-size: cover;
    //      background-position: center;
    //      background-repeat: no-repeat;
    //      background-color: transparent; /* 这里设置背景颜色为透明 */
    //      opacity: 1.25;
    //      width: 12vw;
    //      display: flex;
    //      margin:-5vh 0  0 5%;
    //      border-radius: 9px;
    //      height: fit-content;
    //    }
    //    .photohover{
    //      position: absolute;
    //      bottom: 0;
    //      left: 0; /* 初始位置在容器左侧外面 */
    //      width: 0%; /* 初始宽度为0 */
    //      height: 2vh;
    //      background-color: #2f6dfd;
    //      transition: width 0.5s ease; /* 平滑过渡效果 */
    //    }
    //    .photolist {
    //      font-size: 5vw;
    //      color: #e3e8ed;
    //      margin:auto 0 0 2.5vw;
    //      height: 100%;
    //      font-weight: bold;
    //    }
    //
    //    .title1 {
    //      text-align: left;
    //      font-size: 1vw;
    //      font-family: Roboto, sans-serif;
    //      font-weight: bold;
    //      width: 60vw;
    //      overflow: hidden;
    //      height: fit-content;
    //      //line-height: 35px;
    //    }
    //
    //    .title2 {
    //      display: flex;
    //
    //      opacity: 4;
    //      width: fit-content;
    //      height: fit-content;
    //      // text-align: justify;
    //      font-size: 0.9vw;
    //      font-family: Roboto, sans-serif;
    //      font-weight: 500;
    //      color: white;
    //      margin-top: 2vh;
    //      border-radius: 23px;
    //      padding: 5px 15px;
    //      background-color: #2168DB;
    //      //line-height: 35px;
    //    }
    //  }
    //  .grand1:hover{
    //    .photohover {
    //      width: 100%; /* 鼠标悬浮时宽度变为100% */
    //    }
    //  }
    //
    //}

  }
  .screenGet {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 5vh 11.09763142692085vw 5vh 8.09763142692085vw;
    background-image: url("../assets/getBack.png");
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;

    .title {
      height: fit-content;
      margin: 0 auto;
      text-align: center;
      opacity: 1;
      font-size: 3vw;
      color: white;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700;
      line-height: 10vh
    }

    .light {
      margin-top: 3vh;
      text-align: left;
      width: 50vw;
      height: fit-content;
      font-size: 1.8vw;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 400;
      color: #15257b;
      line-height: 6vh;
    }

    .pro2 {
      height: fit-content;
      padding-top: 12vh;
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      overflow-x: hidden;
      //z-index: 89156;

      .grand1 {
        width: 100%;
        padding: 1vh 1vw 2vh 1vw;
        opacity: 1;
        position: relative;
        height: fit-content;

        .back {
          width: 100%;
          //margin:-8vh auto 0 0;
          height: 100%;
          border-radius: 9px;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          width: 100%;
          height: fit-content;

          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          padding: 1vh 0;
          justify-content: space-between;
        }

        .photo {
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 6vw;
          display: flex;
          margin: 0 auto;
          height: 6vw;
          position: relative;
        }

        .photohover {
          position: absolute;
          bottom: 0;
          left: 0; /* 初始位置在容器左侧外面 */
          width: 0%; /* 初始宽度为0 */
          height: 2vh;
          background-color: #2f6dfd;
          transition: width 0.5s ease; /* 平滑过渡效果 */
        }

        .photolist {
          font-size: 4.5vw;
          color: #e3e8ed;
          margin: auto 1vw 0 1vw;
          height: 100%;
          font-weight: bold;
        }

        .title1 {
          margin-top: 4vh;
          text-align: center;
          font-size: 1.5vw;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;
          overflow: hidden;
          height: fit-content;
          line-height: 5.5vh;
        }

        .title2 {
          margin-top: 4vh;
          text-align: center;
          font-size: 1.3vw;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;
          overflow: hidden;
          height: fit-content;
          line-height: 4.5vh;
        }
      }

      .grand1:hover {
        .photohover {
          width: 100%; /* 鼠标悬浮时宽度变为100% */
        }
      }

    }

    //.pro2 {
    //  height: fit-content;
    //  width: 100%;
    //  margin-top: 10vh;
    //  //z-index: 89156;
    //  display: grid;
    //  grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
    //  row-gap:10vh;
    //  column-gap: 1vw;
    //  .grand1 {
    //    cursor: pointer;
    //    width: 100%;
    //    border-bottom-right-radius: 53px;
    //    border-top-left-radius: 9px;
    //    border-top-right-radius: 9px;
    //    border-bottom-left-radius: 9px;
    //
    //    padding: 1vh 1vw;
    //    opacity: 1;
    //    position: relative;
    //    background-image: url("../assets/groundBack.png");
    //    background-position: left;
    //    background-size: cover;
    //    background-repeat: no-repeat;
    //    background-color: transparent;
    //    height: fit-content;
    //    .back {
    //      width: 100%;
    //      margin:-8vh auto 0 0;
    //      height:fit-content;
    //      border-radius: 9px;
    //      // border-radius:6px;
    //      // display: flex;
    //    }
    //
    //    .back1 {
    //      width: 100%;
    //      height:fit-content;
    //      margin-top:1vh;
    //      border-top: none;
    //      border-bottom-right-radius: 9px;
    //      border-bottom-left-radius: 9px;
    //      // border-radius:6px;
    //      padding: 1vh 1vw;
    //      justify-content: space-between;
    //    }
    //
    //    .photo {
    //      position: relative;
    //      //background-image: url("../assets/pro1.jpg");
    //      background-size: cover;
    //      background-position: center;
    //      background-repeat: no-repeat;
    //      background-color: transparent; /* 这里设置背景颜色为透明 */
    //      opacity: 1.25;
    //      width: 12vw;
    //      display: flex;
    //      margin:-5vh 0  0 5%;
    //      border-radius: 9px;
    //      height: fit-content;
    //    }
    //    .photohover{
    //      position: absolute;
    //      bottom: 0;
    //      left: 0; /* 初始位置在容器左侧外面 */
    //      width: 0%; /* 初始宽度为0 */
    //      height: 2vh;
    //      background-color: #2f6dfd;
    //      transition: width 0.5s ease; /* 平滑过渡效果 */
    //    }
    //    .photolist {
    //      font-size: 5vw;
    //      color: #e3e8ed;
    //      margin:auto 0 0 2.5vw;
    //      height: 100%;
    //      font-weight: bold;
    //    }
    //
    //    .title1 {
    //      text-align: left;
    //      font-size: 1vw;
    //      font-family: Roboto, sans-serif;
    //      font-weight: bold;
    //      width: 60vw;
    //      overflow: hidden;
    //      height: fit-content;
    //      //line-height: 35px;
    //    }
    //
    //    .title2 {
    //      display: flex;
    //
    //      opacity: 4;
    //      width: fit-content;
    //      height: fit-content;
    //      // text-align: justify;
    //      font-size: 0.9vw;
    //      font-family: Roboto, sans-serif;
    //      font-weight: 500;
    //      color: white;
    //      margin-top: 2vh;
    //      border-radius: 23px;
    //      padding: 5px 15px;
    //      background-color: #2168DB;
    //      //line-height: 35px;
    //    }
    //  }
    //  .grand1:hover{
    //    .photohover {
    //      width: 100%; /* 鼠标悬浮时宽度变为100% */
    //    }
    //  }
    //
    //}

  }
  .screenConsult {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 5vh 11.09763142692085vw 8vh 8.09763142692085vw;
    //background: #85a8e7;
    background: linear-gradient(to bottom, #7fa2e5 0%, rgb(200, 226, 255) 100%);

    .title {

      height: fit-content;
      opacity: 1;
      font-size: 3vw;
      color: white;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700;
      line-height: 10vh
    }

    .light {
      margin: 3vh auto 3vh auto;
      text-align: left;
      width: 60vw;
      height: fit-content;
      font-size: 1.8vw;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 400;
      color: white;
      line-height: 6vh;
    }

    .pro2 {
      height: fit-content;
      padding-top: 2vh;
      overflow-x: hidden;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 9px;
        padding: 1vw;
        opacity: 1;
        position: relative;
        background-color: white;
        height: fit-content;

        .back {
          width: 100%;
          margin: 0 auto 0 0;
          height: fit-content;
          border-radius: 9px;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          width: 100%;
          height: fit-content;
          margin-top: 2vh;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          padding: 1vh 0;
          justify-content: space-between;
        }

        .photo {
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          display: flex;
          height: 45vh;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          position: relative;
        }

        .photohover {
          position: absolute;
          bottom: 0;
          left: 0; /* 初始位置在容器左侧外面 */
          width: 0%; /* 初始宽度为0 */
          height: 2vh;
          background-color: #2f6dfd;
          transition: width 0.5s ease; /* 平滑过渡效果 */
        }

        //.photolist {
        //  font-size: 5vw;
        //  color: #e3e8ed;
        //  margin:auto 1vw 0 2vw;
        //  height: 100%;
        //  width: fit-content;
        //  font-weight: bold;
        //}

        .title1 {
          text-align: left;
          font-size: 1.5vw;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          width: 60vw;
          overflow: hidden;
          height: fit-content;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          opacity: 4;
          width: fit-content;
          height: fit-content;
          // text-align: justify;
          font-size: 1.2vw;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          color: white;
          margin-top: 2vh;
          border-radius: 23px;
          padding: 1vh 1vw;
          background-color: #2168DB;
          //line-height: 35px;
        }
      }

      .grand1:hover {
        .photohover {
          width: 100%; /* 鼠标悬浮时宽度变为100% */
        }
      }

    }

    //.pro2 {
    //  height: fit-content;
    //  width: 100%;
    //  margin-top: 10vh;
    //  //z-index: 89156;
    //  display: grid;
    //  grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
    //  row-gap:10vh;
    //  column-gap: 1vw;
    //  .grand1 {
    //    cursor: pointer;
    //    width: 100%;
    //    border-bottom-right-radius: 53px;
    //    border-top-left-radius: 9px;
    //    border-top-right-radius: 9px;
    //    border-bottom-left-radius: 9px;
    //
    //    padding: 1vh 1vw;
    //    opacity: 1;
    //    position: relative;
    //    background-image: url("../assets/groundBack.png");
    //    background-position: left;
    //    background-size: cover;
    //    background-repeat: no-repeat;
    //    background-color: transparent;
    //    height: fit-content;
    //    .back {
    //      width: 100%;
    //      margin:-8vh auto 0 0;
    //      height:fit-content;
    //      border-radius: 9px;
    //      // border-radius:6px;
    //      // display: flex;
    //    }
    //
    //    .back1 {
    //      width: 100%;
    //      height:fit-content;
    //      margin-top:1vh;
    //      border-top: none;
    //      border-bottom-right-radius: 9px;
    //      border-bottom-left-radius: 9px;
    //      // border-radius:6px;
    //      padding: 1vh 1vw;
    //      justify-content: space-between;
    //    }
    //
    //    .photo {
    //      position: relative;
    //      //background-image: url("../assets/pro1.jpg");
    //      background-size: cover;
    //      background-position: center;
    //      background-repeat: no-repeat;
    //      background-color: transparent; /* 这里设置背景颜色为透明 */
    //      opacity: 1.25;
    //      width: 12vw;
    //      display: flex;
    //      margin:-5vh 0  0 5%;
    //      border-radius: 9px;
    //      height: fit-content;
    //    }
    //    .photohover{
    //      position: absolute;
    //      bottom: 0;
    //      left: 0; /* 初始位置在容器左侧外面 */
    //      width: 0%; /* 初始宽度为0 */
    //      height: 2vh;
    //      background-color: #2f6dfd;
    //      transition: width 0.5s ease; /* 平滑过渡效果 */
    //    }
    //    .photolist {
    //      font-size: 5vw;
    //      color: #e3e8ed;
    //      margin:auto 0 0 2.5vw;
    //      height: 100%;
    //      font-weight: bold;
    //    }
    //
    //    .title1 {
    //      text-align: left;
    //      font-size: 1vw;
    //      font-family: Roboto, sans-serif;
    //      font-weight: bold;
    //      width: 60vw;
    //      overflow: hidden;
    //      height: fit-content;
    //      //line-height: 35px;
    //    }
    //
    //    .title2 {
    //      display: flex;
    //
    //      opacity: 4;
    //      width: fit-content;
    //      height: fit-content;
    //      // text-align: justify;
    //      font-size: 0.9vw;
    //      font-family: Roboto, sans-serif;
    //      font-weight: 500;
    //      color: white;
    //      margin-top: 2vh;
    //      border-radius: 23px;
    //      padding: 5px 15px;
    //      background-color: #2168DB;
    //      //line-height: 35px;
    //    }
    //  }
    //  .grand1:hover{
    //    .photohover {
    //      width: 100%; /* 鼠标悬浮时宽度变为100% */
    //    }
    //  }
    //
    //}

  }
  .screenMap {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    padding: 5vh 11.09763142692085vw 5vh 8.09763142692085vw;
    // background: #192079;
    background-color:rgba(245,245,245,1) ;
    background-image: url("../assets/new/关于我们背景.png");

    .title {

      //height: 5vh;
      opacity: 1;
      font-size: 3vw;
      color: white;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 10vh;
      text-align: center;

    }

    .light {
      margin: 3vh auto 4vh auto;
      text-align: center;
      width: 66vw;
      height: fit-content;
      font-size: 1.8vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: white;
      line-height: 4.94437577255871vh;

    }
    .top{
      margin: 1vh 5vw 0 0 ;
      // border-top: rgba(185, 185, 185, 0.5) 1px solid;
      cursor: pointer;
      //height: 13vh;
      padding: 2vh 0;
      .high1{
        color: white;
        font-size: 1.3vw;
        line-height: 5vh;
        text-align: center;

      }
      .high2{
        // color: #fd8a08;
        color: white;
        font-size: 1.3vw;
        font-weight: bold;
        // width: fit-content;
        // width: 33vw;
        margin:0 auto;
        line-height: 4vh;
        text-align: center;

      }
      .high3{
        margin-top:1vh;
        border-top: rgba(185, 185, 185, 0.5) 1px solid;
      }
    }
    .top1{
      margin: 1vh 5vw 0 0 ;
      // border-top: rgba(185, 185, 185, 0.5) 1px solid;
      padding: 2vh 0;
      cursor: pointer;
     width: 100%;
      // height: 13vh;

      .high1{
        color:white;
        // color:rgba(97,97,97,1);
        font-size: 1.2vw;
        line-height: 5vh;
        text-align: center;

      }
      .high2{
        // color: #fd8a08;
        // color:rgba(8,78,138,1);
        color: white;
        font-size: 1.2vw;
        font-weight: bold;
        // width: fit-content;
        width: 20vw;
        margin:0 auto;
        line-height: 4vh;
        text-align: center;
      }
      .high3{
        margin-top:1vh;
        border-top: rgba(185, 185, 185, 0.5) 1px solid;
      }
    }
    .top:hover{
      .high2{

        font-size: 1.4vw;

      }

    }
    .top1:hover{
      .high2{

        font-size: 1.4vw;

      }

    }

  }
  .screenConsultnew {
    z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 5vh 8vw 5vh 8vw;
    background: #f3f3f3;


    .title {

      height: fit-content;
      opacity: 1;
      font-size: 3vw;
      color: #15257b;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700;
      line-height: 10vh;
      text-align: center;
    }

    .light {
      margin: 3vh auto;
      text-align: center;
      width: 60vw;
      height: fit-content;
      font-size: 1.8vw;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 400;
      color: #15257b;
      line-height: 6vh;
    }

    .serviceStyle {
      margin-top: 5vh;
      display: flex;
      //grid-template-columns:  minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
      column-gap: 0.5vw;
      overflow: hidden;

      .box, .box1 {
        transition: width 0.5s ease-in-out, opacity 0.5s ease-in-out, margin-top 0.5s ease-in-out;
      }

      //display: flex;
      .box {
        width: 42vw;
        cursor: pointer;
        margin: 0;
        height: 65vh;
        position: relative;
        border-bottom-right-radius: 33px;
        background-image: url("../assets/newback.png");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: transparent;

        .back {
          display: flex;
        }

        .photo {

          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 0;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          display: flex;
          border-radius: 9px;
        }

        .title {
          transition: margin-top 0.5s ease-in-out;
          position: relative;
          padding: 0;
          height: fit-content;
          opacity: 1;
          font-size: 1.8vw;
          z-index: 3;
          text-align: left;
          color: white;
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
          font-weight: 700;
          line-height: 6vh;
          margin: 7vh 1vw 1vh 1vw;
        }

        .span {
          animation: slideInFromLeft 0.5s ease-in-out forwards;
          position: relative;
          z-index: 3;
          margin: 2vh 0 5vh 1vw;
          width: 5vw;
          height: 0.2vh;
          background-color: white;
        }

        .light {
          animation: slideInFromLeft 0.5s ease-in-out forwards;
          position: relative;
          z-index: 3;
          margin: 1vh 1vw 0 1vw;
          text-align: left;
          height: fit-content;
          width: 95%;
          column-gap: 1vw;
          font-size: 0.9vw;
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
          font-weight: 400;
          color: #2C3E50;
          line-height: 2.5vh;

          .lightbehind {
            transition: transform 0.3s ease-out;
            cursor: pointer;
            display: flex;
            border-radius: 53px;
            color: white;

            .limit {
              margin: 0 auto 0 1.5vw;
              display: -webkit-box;
              align-items: center;
              font-size: 1.2vw;
              font-weight: bold;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3; /* 限制在一个块元素显示的文本的行数 */
              overflow: hidden;
              line-height: 4vh;
              height: 12vh;
              text-overflow: ellipsis;
            }

          }

          .lightbehind:hover {
            font-weight: bold;
          }

          .biaoti {
            width: 2vw;
            height: fit-content;
            display: flex;
            align-items: center;
            margin: 1vh 0 auto 0;
          }

          .iconStyle {
            margin: 3vh 1vw auto auto;
            font-size: 1.2vw;
            //animation: pulse 2s infinite;
          }

          //@keyframes pulse { 0% { transform: translateX(0px); /* 原始大小 */ } 50% { transform: translateX(5px); /* 放大到120% */ } 100% { transform: translateX(-5px); /* 恢复到原始大小 */ } }
        }
      }

      @keyframes slideInFromLeft {
        from {
          transform: translateX(-50%); /* 从左侧隐藏的位置开始 */
          opacity: 0; /* 开始时完全透明 */
        }
        to {
          transform: translateX(0); /* 移动到正常位置 */
          opacity: 1; /* 结束时完全不透明 */
        }
      }

      .box1 {
        width: 20vw;
        cursor: pointer;
        margin: 0;
        height: 65vh;
        position: relative;
        border-bottom-right-radius: 33px;
        background-image: url("../assets/newback.png");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: transparent;

        .back {
          display: flex;;
        }

        .photo {

          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 0;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          display: flex;
          border-radius: 9px;
        }

        .title {
          transition: margin-top 0.5s ease-in-out;
          position: relative;
          padding: 0;
          height: fit-content;
          opacity: 1;
          font-size: 1.6vw;
          z-index: 3;
          text-align: left;
          color: white;
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
          font-weight: 700;
          line-height: 5vh;
          margin: 38vh 1vw 1vh 1vw;
        }

        .light {
          display: none;
          margin: 1vh 0 0 5%;
          text-align: left;
          height: fit-content;
          font-size: 0.9vw;
          width: 90%;
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
          font-weight: 400;
          color: #2C3E50;
          line-height: 2.5vh;

          .lightbehind {
            transition: transform 0.3s ease-out;
            cursor: pointer;
            display: flex;
            border: #8faffa 1px solid;
            background-color: #e4ecfd;
            margin-top: 1vh;
            border-radius: 53px;
            padding: 1vh 1vw;

            .limit {
              display: -webkit-box;
              align-items: center;
              font-size: 1.2vw;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
              overflow: hidden;
              line-height: 3vh;
              height: 6vh;
              text-overflow: ellipsis;
            }

          }

          .lightbehind:hover {
            color: #8faffa;
          }

          .biaoti {
            width: 2vw;
            height: auto;
            margin: auto 0.5vw auto 0.5vw;
          }

          .iconStyle {
            margin: auto 1vw auto auto;
            //animation: pulse 2s infinite;
          }

          //@keyframes pulse { 0% { transform: translateX(0px); /* 原始大小 */ } 50% { transform: translateX(5px); /* 放大到120% */ } 100% { transform: translateX(-5px); /* 恢复到原始大小 */ } }
        }
      }

      @keyframes moveTitle {
        from {
          margin-top: 35vh;
        }
        to {
          margin-top: 10vh;
        }
      }

      .swiper-container {
        display: flex;
      }

      .swiper-slide {
        width: fit-content;
      }
    }

    ::v-deep img {
      object-fit: cover;
    }

    .pro3 {
      //margin-top: -4vh;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 2vh;

      .grand2 {
        cursor: pointer;
        width: 100%;

        height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          position: relative;
          height: 40vh;
          background: #d4e4ff;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          //border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          opacity: 1.25;
          margin: auto;
          background-color: white;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 30vh;
        }


        .title1 {

          opacity: 4;
          margin-top: 3vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.2vw;
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-weight: bold;
          color: #2168DB;
          line-height: 3.5vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.9vw;
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-weight: 500;
          font-size: 0.9vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }

      }


    }

    .pro4 {
      //margin-top: -4vh;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      padding: 0 10vw;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 2vh;

      .grand2 {
        cursor: pointer;
        width: 100%;

        height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          position: relative;
          height: 40vh;
          background: #c0d8f9;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          //border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          opacity: 1.25;
          margin: auto;
          background-color: white;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 30vh;
        }


        .title1 {

          opacity: 4;
          margin-top: 3vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.2vw;
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-weight: bold;
          color: #2168DB;
          line-height: 3.5vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.9vw;
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-weight: 500;
          font-size: 0.9vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }

      }


    }


  }

  .servicegrand {
    background-color: #123a74;
    width: 80vw;
    height: fit-content;
    margin: 4vh 0 0 0;

    .servicegrandback {
      padding: 4vh 4vh 0vh 4vh;

      .title {
        color: white;
        margin: 0;
        height: unset;
      }

      .light {
        width: fit-content;
        color: white;
        margin: unset;
        padding: 1vh 0 1vh 0
      }
    }
  }

  .servicecontent {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr)  minmax(0, 1fr);
    width: 80vw;
    height: fit-content;
    background: linear-gradient(#123a74 50%, white 50%);

    .contentcard {
      height: fit-content;
      width: 90%;
      background-color: #95d1d1;
      padding: 2vh 2vw 2vh 2vw;
      margin: auto
    }

    .contentcard :hover {
      cursor: pointer;
    }

    .servicecontenttitle {
      color: black;
      text-align: center;
      font-size: 1.0vw;
      font-weight: bolder;
    }
  }
  
  .screennews10{
    width: 100vw;
    height: fit-content;
    // padding: 7vh 2.09763142692085vw 4vh 8.09763142692085vw;
    padding:5vh 4.09763142692085vw 7vh 4.09763142692085vw;
    background-color: white;
    // background-color: #f8f8f8;
    position: relative;
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    .title-container {
        // margin-bottom: 5vh;
        .title {
            // color: #15257b;
            color: rgba(8,78,138,1);
            // font-size: 3vw;
            // font-weight: 700;
            font-size: 60px;
            font-weight: 400;
            text-align: center;
            margin: 0;
            line-height: 1.2;
        }
    }

.light {
margin: 3vh auto 1vh auto;
text-align: center;
width:93%;
height: fit-content;
font-size: 1.5vw;
font-family: Roboto, sans-serif;
font-weight: 400;
// color: #15257b;
color: #555555;
line-height: 6vh;
// margin-left:8vw;
}
.container-new10{
  display: flex;
  width:100%;
  height: fit-content;
}
.contentphoto{
position: relative;
display:inline-block;
border: 1px solid #E1E1E1; /* 2px 宽度，实线，黑色边框 */
box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 阴影效果 */
border-radius:25px ;
margin-top:4vh;
// width:36%
width:32vw;
height:69.6vh;
}
.photonew10{
  position: absolute;
  background-image: url("../assets/new/立即体验1.png");
  background-size: cover; /* 背景图填充容器 */
  // width: 95%;          /* 设置容器宽度 */
  // height: 67%;  
  width: 22vw;          /* 设置容器宽度 */
  height: 44.5vh;  
  // height: 30vh;       /* 设置容器高度 */
transition: background-image 0.3s ease-out; /* 添加图片透明度的过渡效果 */
border-radius: 25px;
margin-top:3vh;
margin-left:0.6vw;
}
.photonew10:hover{
  background-image: url("../assets/new/立即体验.png");
}
.photonew10 img{
  // width: 100%;
  // height: 70%;
  width:22vw;
  height: fit-content;
  // object-fit: cover;
  // position: absolute;
  top: 0;
  left: 0;
  opacity: 0;  /* 让 img 标签中的图片不可见 */
}
/* 弹框内容部分居中显示，并且有间距 */
.dialog-content {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.dialog-content p {
  margin: 10px 0;
  font-size: 16px;
  font-weight: 400;
  color: white;
}

.dialog-content a {
  display: inline-block;
  margin-top: 10px;
  // color: blue;
  color:orange;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 400;
}
.search {
  position: relative;
  z-index:3;
  margin-top: 31vh;
        display: flex;
        border-radius: 53px;
        // width: 40vw;;
        // height:7vh;
        background-color: white;
    

        ::v-deep .el-input {
          border-radius: 50%;
          // height:7vh;
          // width: 40vw;
          width:20vw;
          font-size: 15px;
          font-weight: 400;
        }
        ::v-deep .el-input__inner {
          border-radius: 50%;
          height:7vh !important;
          border: white 1px solid !important;
          background-color: transparent !important;
        }

      }
.title-top  {
    width: 100%;
    color: #555555;
    text-align: center;
    font-size: 2vw;
    font-weight: 400;
    padding: 10px;
    left: 15%;
    line-height:40px;
}
.title-bottom {
    width: 100%;
    color: #616161;
    text-align: center;
    font-size: 2vw;
    font-weight: 400;
    margin-top:10px;
    // letter-spacing: 0.2em; /* 增加字间距 */
    line-height:3vh;
    height: 8vh;
    white-space: normal; /* 允许换行 */
    word-wrap: break-word; /* 防止长单词或URL溢出 */
}
.click-experience {
    color: red; /* 设置“点击体验”部分的文字为红色 */
    font-weight: bold;
}

.consult10{
  position: relative;
  display:inline-block;
  // margin-bottom: 100px; /* 使用负的 margin 使元素向上移动 */
  border-radius:25px ;
  border: 1px solid #E1E1E1; /* 2px 宽度，实线，黑色边框 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 阴影效果 */
  margin-top:4vh;
  margin-left:1vw;
  width:100%;
  height: 69.6vh;
}
.zong{
  display: flex;
  gap: 1vw; /* 设定这两个元素之间的间距 */
  // margin-top: 4vh;
  height: 44.5vh;
  width:100%;
}
.photonew11{
  background-image: url("../assets/new/11.png");
  background-size: cover; /* 背景图填充容器 */
  width: 100%;          /* 设置容器宽度 */
  height: 100%;         /* 设置容器高度 */
  transition: background-image 0.3s ease-out; /* 添加图片透明度的过渡效果 */
  border-radius: 25px;
  margin-top:2.7vh;
  margin-left: 1vw;
  margin-bottom:1vh;
}
.photonew11:hover{
  background-image: url("../assets/new/智能地质.png");
}
.photonew11 img{
  width: 100%;
  height: 100%;
  // object-fit: cover;
  // position: absolute;
  top: 0;
  left: 0;
  opacity: 0;  /* 让 img 标签中的图片不可见 */
}

.photonew12{
  background-image: url("../assets/new/22.png");
  background-size: cover; /* 背景图填充容器 */
  width: 100%;          /* 设置容器宽度 */
  height: 100%;         /* 设置容器高度 */
  transition: background-image 0.3s ease-out; /* 添加图片透明度的过渡效果 */
  border-radius: 25px;
  margin-top:2.7vh;
}
.photonew12:hover{
  background-image: url("../assets/new/光纤漏点.png");
}
.photonew12 img{
  width: 100%;
  height: 100%;
  // object-fit: cover;
  // position: absolute;
  top: 0;
  left: 0;
  opacity: 0;  /* 让 img 标签中的图片不可见 */
}

.photonew13{
  background-image: url("../assets/new/33.png");
  background-size: cover; /* 背景图填充容器 */
  width: 100%;          /* 设置容器宽度 */
  height: 100%;         /* 设置容器高度 */
  transition: background-image 0.3s ease-out; /* 添加图片透明度的过渡效果 */
  border-radius: 25px;
  margin-top:2.7vh;
  margin-right:1vw;
}
.photonew13:hover{
  background-image: url("../assets/new/压裂方案参数.png");
}
.photonew13 img{
  width: 100%;
  height: 100%;
  // object-fit: cover;
  // position: absolute;
  top: 0;
  left: 0;
  opacity: 0;  /* 让 img 标签中的图片不可见 */
}
.title-top  {
    width: 100%;
    color: #555555;
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    padding: 10px;
    left: 15%;
    line-height:40px;
}
.title-bottom {
    width: 100%;
    color: #616161;
    text-align: center;
    font-size: 25px;
    font-weight: 400;
    margin-top:10px;
    // letter-spacing: 0.2em; /* 增加字间距 */
    line-height:30px;
}

  .screenConsult {
    //z-index: 10;
    width:60vw;
    height: fit-content;
    border-radius: 9px;
    padding: 5vh 10vw;
    background: transparent;
    color: #15257b;
    // background-color:white;
    margin-top: -7.5vh;
    .title {
      //height: 5vh;
      opacity: 1;
      font-size: 2vw;
      color: #15257b;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 10vh;
      text-align: left;
    }

    .light {
      // margin:3vh auto 4vh auto;
      text-align: justify;
      width: 100%;
      height: fit-content;
      font-size: 1.2vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #15257b;
      line-height: 3.94437577255871vh;
    }
    .pro2 {
      height: fit-content;
      padding-top: 2vh;
      overflow-x: hidden;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 9px;
        padding: 1vw;
        opacity: 1;
        position: relative;
        background-color: white;
        height: fit-content;
        .back {
          width: 100%;
          margin:0 auto 0 0;
          height:fit-content;
          border-radius: 9px;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          width: 100%;
          height:fit-content;
          margin-top:2vh;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          padding: 1vh 0;
          justify-content: space-between;
        }

        .photo {
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          display: flex;
          height: 45vh;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          position: relative;
        }
        .photohover{
          position: absolute;
          bottom: 0;
          left: 0; /* 初始位置在容器左侧外面 */
          width: 0%; /* 初始宽度为0 */
          height: 2vh;
          background-color: #2f6dfd;
          transition: width 0.5s ease; /* 平滑过渡效果 */
        }
        //.photolist {
        //  font-size: 5vw;
        //  color: #e3e8ed;
        //  margin:auto 1vw 0 2vw;
        //  height: 100%;
        //  width: fit-content;
        //  font-weight: bold;
        //}

        .title1 {
          text-align: left;
          font-size: 1.2vw;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          width: 60vw;
          overflow: hidden;
          height: fit-content;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          opacity: 4;
          width: fit-content;
          height: fit-content;
          // text-align: justify;
          font-size:1vw;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          color: white;
          margin-top: 2vh;
          border-radius: 23px;
          padding: 1vh 1vw;
          background-color: #2168DB;
          //line-height: 35px;
        }
      }
      .grand1:hover{
        .photohover {
          width: 100%; /* 鼠标悬浮时宽度变为100% */
        }
      }

    }

  }
  }
  .image-text_1 {
    display: flex;
    margin-bottom: 3vh;
    .image_8 {
      width: 3.5vw;
      height: 3.5vw;
    }

    .text-group_1 {
      position: relative;
      width: fit-content;
      height: 4vw;
      margin-left: 0.5vw;
    }

    .text_8 {
      height:4vw;
      overflow-wrap: break-word;
      color: rgba(118, 118, 118, 0.2);
      font-size: 1.8vw;
      text-transform: uppercase;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 700;
      text-align: center;
      white-space: nowrap;
      line-height: 5vw;

    }

    .text_9 {

      position: absolute;
      left: 0;
      top: 0;
      height: 4vw;
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 1.8vw;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 600;
      text-align: center;
      white-space: nowrap;
    }
  }
// 关于我们模块新的样式
.aboutUs{
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 5vh 10vw 0 10vw;
    background-position: center;
    background-size: cover;
    background-color: white;
    .intro1{
      display: flex;
      width: 100%;
      height: fit-content;
      .intro_text_1{
        width: 45%;
        height: 50vh;
        display: flex;
        justify-content:space-between;
        flex-direction: column; /* 使用 flex 布局，方便子元素对齐 */
        .intro_text_2{
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 1.5vw;
          color: #333333;
          // line-height: 2vh;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
        .intro_text_3{
          width: 90%;
          margin: auto auto 0 0;
          display: grid;
          grid-template-columns: minmax(0, 1fr)  minmax(0, 1fr);
          column-gap: 1.5vw;
          row-gap: 4vh;
          .image-text_2 {
            background: #F5F5F5;
            width: 100%;
            padding: 3vh 1.5vw;
            height: 100%;
            display: flex;
          }

          .label_5 {
            display: flex;
            align-items: center;
            width: fit-content;
            height: 1.5w;
            object-fit: cover;
          }

          .text-group_2 {
            display: flex;
            align-items: center;
            width: fit-content;
            overflow-wrap: break-word;
            color: rgba(51, 51, 51, 1);
            font-size: 1vw;
            font-family: PingFang SC-Regular;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            margin-left: 0.5vw;
          }


        }
      }
      .video-container {
        width: 55%;
        height: 50vh;
        border-radius:10px; /* Optional: rounding the container edges */
        //box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Add shadow around the container */
        video {
          width: 100%;
          height: 100%;
          border-radius:10px;
          // border-radius: 0.5vw;
          object-fit: cover;
        }
      }
    }
    .intro2{
      margin-top: 7vh;
      width: 100%;
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)  minmax(0, 1fr);
      column-gap: 1.5vw;
      row-gap: 4vh;
      .block_3 {
        padding-top: 8vh;
        width: 100%;
        height: 30vh;

        background-size: 100% 100%;
        justify-content: center;
      }
      .flex-col1{
        background-image: url("../assets/home/img7.png");
      } .flex-col2{
        background-image: url("../assets/home/img8.png");
      } .flex-col3{
        background-image: url("../assets/home/img9.png");
      } .flex-col4{
        background-image: url("../assets/home/img10.png");
      }

      .text_11 {
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.2vw;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        margin: 5vh 0 0 3vh;
      }

      .box_10 {
        margin: 3vh 0 0 3vh;
      }

      .text-wrapper_3 {
        overflow-wrap: break-word;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
      }

      .text_12 {
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 2.5vw;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
        line-height: 5vh;
      }

      .text_13 {
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size:2.2vw;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
        line-height: 5vh;
      }

      .text_14 {
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.5vw;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
        line-height: 1vh;
        margin-left: -2px;
      }


    }
  }
  .screenGpts {
    //z-index: 10;
    // width: 100vw;
    width:93%;
    height: fit-content;
    //border-radius: 9px;
    padding: 5vh 11.09763142692085vw 3vh 4.09763142692085vw;
    background: white;
    position: relative;
    border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
    border-radius: 25px;
    margin-left:3.8vw;
    margin-bottom: 7vh;
    .title {

      height: fit-content;
      opacity: 1;
      font-size: 60px;
      // color: #15257b;
      color:rgba(8,78,138,1);
      // font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 400;
      line-height: 1.2;
    }

    .light {
      margin: 3vh auto 5vh auto;
      text-align: center;
      width: 100%;
      height: fit-content;
      font-size: 1.5vw;
      // font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-family: Roboto, sans-serif;
      font-weight: 400;
      // color: #15257b;
      color:#555555;
      line-height: 6vh;
    }

    .login {
      width: fit-content;
      margin: 0 auto;
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 1vw;
      //font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1.2vw;

      .loginUp {
        cursor: pointer;
        padding: 1vh 3vw;
        border: 1px solid #192079;
        color: #192079;
        border-radius: 53px;
        width: fit-content;
      }

      .loginUp:hover {
        background-color: #e0dddd;
      }

      .loginIn {
        cursor: pointer;
        padding: 1vh 3vw;
        // background-color: #192079;
        border:1px solid #192079;
        // color: white;
        color:#192079;
        border-radius: 53px;
        // width: fit-content;
      }

      .loginIn:hover {
        // background-color: #777996;
        background-color: #e0dddd;
      }
    }

    .searchBack{
      position: absolute;
      height: 50vh;
      top:20vh;
      display: block;
      left:4vw;
      width:92%;
      //filter: blur(10px);

      .searchContent{
        display: flex;
        animation: myMove 30s linear infinite;
        animation-fill-mode: forwards;
        border: rgba(128, 128, 128, 0.2) 1px solid;
        // border-radius: 28px;
        // padding: 1vh 1.5vw 0 1.5vw;
        // margin:3vh auto;
        border-radius: 9px;
        padding: 1vh 2vw 1vh 0vw;
        height:fit-content;
      }
      .searchItem{
        height: 27vh;
        width:100%;
        gap:10px;
        margin-top:7vh;
        overflow-x: hidden;
        mask-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 1),  rgba(0, 0, 0, 1), rgba(0, 0, 0, 1),rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)); /* 新增 */
        -webkit-mask-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 1),rgba(0, 0, 0, 1),  rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5));
      }
      .loginUp {
        cursor: pointer;
        margin-top: 5vh;
        padding: 1vh 3vw;
        //border: 1px solid #192079;
        color: white;
        font-size:1vw;
        text-align: center;
        background-color: #192079;
        border-radius: 53px;
      }

      .loginUp:hover {
        background-color: rgba(25, 32, 121, 0.5);;
      }

      .photo{
        // border-radius: 50%;
        // height: 6vh;
        // width: fit-content;
        height: 7vh;
        width: 10vw;
        img{
          object-fit:contain ;
          height:100%;
          width:100%;
        }
      }
      .content{
        margin: 1vh  0 0 1vw;
        transition: opacity 0.3s ease-in-out; /* 新增 */
        position: relative; /* 新增 */
        z-index: 1; /* 新增 */
       .title1{
          font-size: 1.5vw;
          line-height: 5.5vh;
        }
        .title2{
          font-size: 1.2vw;
          line-height: 3vh;
        }
      }
      @keyframes myMove {
        0% {
          transform: translateX(0vw);
          //opacity: 0.5;
        }
        100% {
          transform: translateX(-200vw);
          //opacity: 0.5;
        }

      }
    }

    .pro3 {
      //margin-top: -4vh;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr)  minmax(0, 1fr);
      column-gap: 0;
      row-gap: 2vh;

      .grand2 {
        cursor: pointer;
        width: 100%;

        height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          position: relative;
          height: 100%;
          background: white;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: #fff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 0 auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 35vh;
        }


        .title1 {
          padding: 5vh 0;
          opacity: 4;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.17094165222415vw;
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-weight: bold;
          color: #2168DB;
          line-height: 3.5vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          // //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.9vw;
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-weight: 500;
          font-size: 0.9vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }

      }
    }
  }
  .screennews11{
    width: 100vw;
    height: fit-content;
    padding: 7vh 4.09763142692085vw 8vh 4.09763142692085vw;
    // background-color: white;
    background-color: #f8f8f8;
    background-image:url("../assets/new/增产提效研究课题背景图.png");
    position: relative;
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    .title-container {
        margin-bottom: 5vh;

        .title {
            // color: #15257b;
            color:white;
            // font-size: 3vw;
            // font-weight: 700;
            font-size: 60px;
            font-weight: 400;
            text-align: center;
            margin: 0;
            line-height: 1.2;
        }
    }
.container11 {
  position: relative;
  width: 100%;  /* 容器宽度 */
  height: fit-content;/* 容器高度 */
  overflow: hidden; /* 隐藏超出容器范围的内容 */
  // border:3px solid #ddd; /* 可选：容器的边框 */
  border-radius: 15px;
  box-sizing: border-box;  /* 包括边框和内边距在内计算容器尺寸 */
}

/* 图片包装器 */
.image-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden; /* 隐藏图片超出容器的部分 */
  background-color: white ;
}

/* 图片样式 */
.image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 保持图片比例 */
  transition: transform 0.5s ease-out; /* 图片上移动画 */
}

/* 标题样式 */
.title11 {
  position: relative;
  bottom: 40px;
  left: 20px;
  // color: black;
  color:#555555;
  font-weight:400;
  font-size: 30px;
  // z-index: 1;
  transition: transform 0.5s ease-out; /* 标题上移动画 */
  margin-top:6vh;
}

/* 内容样式 */
.content11 {
  position: absolute;
  bottom: 0; /* 初始时将内容隐藏在下方 */
  left: 0;
  width: 60%;
  font-size: 20px;
  color: black;
  background-color: white;
  margin-bottom:20px;
  padding: 20px;
  text-align: left;
  transform: translateY(100%); /* 初始状态，内容被移动到容器底部之外 */
  transition: transform 0.5s ease-out; /* 内容滑入动画 */
}
 .discover-btn {
                    background: none;
                    border: none;
                    color: #15257b;
                    font-size: 1.1vw;
                    font-weight: 400;
                    padding: 0;
                    cursor: pointer;
                    transition: color 0.3s;
                    margin-top: 2vh;
                    margin-left:1.8vw;
                    &:hover {
                        color: darken(#15257b, 10%);
                    }
            }

/* 鼠标悬停时效果 */
.container11:hover .content11 {
  transform: translateY(25%); /* 鼠标悬停时内容向上滑入 */
}
.container11:hover .image {
  transform: translateY(-30%); /* 图片上移 */
}

.container11:hover .title11 {
  transform: translateY(-300%); /* 标题上移 */
}
.subcontainer{
  display: flex;
  align-items: center;
  width:100%;
  margin-top:1vh;
}
.container12 {
  position: relative;
  width: 50%;  /* 容器宽度 */
  height: fit-content;/* 容器高度 */
  overflow: hidden; /* 隐藏超出容器范围的内容 */
  // border: 1px solid #ddd; /* 可选：容器的边框 */
  border-radius: 15px;
}

/* 图片包装器 */
.image-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden; /* 隐藏图片超出容器的部分 */
}

/* 图片样式 */
.image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 保持图片比例 */
  transition: transform 0.5s ease-out; /* 图片上移动画 */
  border-radius: 9px;
}

/* 标题样式 */
.title11 {
  position: relative;
  bottom: 40px;
  left: 20px;
  color: black;
  font-size: 30px;
  // z-index: 1;
  transition: transform 0.5s ease-out; /* 标题上移动画 */
  margin-top:6vh;
}

/* 内容样式 */
.content11 {
  position: absolute;
  bottom: 0; /* 初始时将内容隐藏在下方 */
  left: 0;
  width: 100%;
  font-size: 20px;
  color: black;
  padding: 20px;
  text-align: left;
  transform: translateY(100%); /* 初始状态，内容被移动到容器底部之外 */
  transition: transform 0.5s ease-out; /* 内容滑入动画 */
}
 .discover-btn {
                    background: none;
                    border: none;
                    color: #15257b;
                    font-size: 1.1vw;
                    font-weight: 400;
                    padding: 0;
                    cursor: pointer;
                    transition: color 0.3s;
                    margin-top: 1.5vh;
                    margin-left: 1.8vw;
                    &:hover {
                        color: darken(#15257b, 10%);
                    }
            }

/* 鼠标悬停时效果 */
.container12:hover .content11 {
  transform: translateY(20%); /* 鼠标悬停时内容向上滑入 */
}
.container12:hover .image {
  transform: translateY(-35%); /* 图片上移 */
}

.container12:hover .title11 {
  transform: translateY(-390%); /* 标题上移 */
}

.container13 {
  position: relative;
  width: 50%;  /* 容器宽度 */
  height: fit-content;/* 容器高度 */
  overflow: hidden; /* 隐藏超出容器范围的内容 */
  // border: 1px solid #ddd; /* 可选：容器的边框 */
  border-radius: 15px;
  margin-left: 1vw;
}

/* 图片包装器 */
.image-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden; /* 隐藏图片超出容器的部分 */
}

/* 图片样式 */
.image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 保持图片比例 */
  transition: transform 0.5s ease-out; /* 图片上移动画 */
  border-radius: 9px;
}

/* 标题样式 */
.title11 {
  position: relative;
  bottom: 40px;
  left: 20px;
  color: black;
  font-size: 30px;
  // z-index: 1;
  transition: transform 0.5s ease-out; /* 标题上移动画 */
  margin-top:6vh;
}

/* 内容样式 */
.content11 {
  position: absolute;
  bottom: 0; /* 初始时将内容隐藏在下方 */
  left: 0;
  width: 100%;
  font-size: 20px;
  color: black;
  padding: 20px;
  text-align: left;
  transform: translateY(100%); /* 初始状态，内容被移动到容器底部之外 */
  transition: transform 0.5s ease-out; /* 内容滑入动画 */
}
 .discover-btn {
                    background: none;
                    border: none;
                    color: #15257b;
                    font-size: 1.1vw;
                    font-weight: 400;
                    padding: 0;
                    cursor: pointer;
                    transition: color 0.3s;
                    margin-top: 1.5vh;
                    margin-left:1.9vw;
                    &:hover {
                        color: darken(#15257b, 10%);
                    }
            }

/* 鼠标悬停时效果 */
.container13:hover .content11 {
  transform: translateY(20%); /* 鼠标悬停时内容向上滑入 */
}
.container13:hover .image {
  transform: translateY(-35%); /* 图片上移 */
}

.container13:hover .title11 {
  transform: translateY(-390%); /* 标题上移 */
}
}

  .screennews6{
    width: 100vw;
    height: fit-content;
    padding: 5vh 4.09763142692085vw 2vh 4.09763142692085vw;
    background-color: white;
    // background-color: #f8f8f8;
    position: relative;
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    .title-container {
        margin-bottom: 5vh;
        .title {
            color: #15257b;
            font-size: 3vw;
            font-weight: 700;
            text-align: center;
            margin: 0;
            line-height: 1.2;
        }
    }

.light {
margin: 3vh auto 3vh auto;
text-align: center;
width:66vw;
height: fit-content;
font-size: 1.5vw;
font-family: Roboto, sans-serif;
font-weight: 400;
color: #15257b;
line-height: 6vh;
}
.photocontent{
  display: flex;
  border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
  border-radius: 9px;
  // margin-left: 2vw;
}
.photocontent1{
  width: 100%;
  position: relative;
  text-align: center; /* 让文字居中 */
  margin-top:2vh;
  margin-bottom:2vh;
}
.photocontent1 img{
  // width: 22vw;
  // height: 33vh;
  width: 20vw;
  height: 39vh;
  border-radius: 12px;
}
  .newtitle3 {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.1vw;
  font-weight: 400;
  color: white; /* 文字颜色，建议与图片背景有对比 */
  padding: 5px 10px;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  }
  .newtitle3:hover {
  color: #007BFF;

}
  .newtitle4 {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2vw;
  font-weight: 400;
  color: white; /* 文字颜色，建议与图片背景有对比 */
  padding: 5px 10px;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  }
 .newtitle4:hover{
  color: #007BFF; /* 鼠标悬停时文字颜色变化 */
}
.text-content {
            margin-right:5vw;
            width:70%;
            .main-description {
                font-size: 1.4vw;
                line-height: 1.8;
                margin-bottom: 2vh;
                // color: #000000;
                color:#555555;
            }

            .sub-description {
                font-size: 1.1vw;
                line-height: 1.8;
                // color: #000000;
                color:#616161;
                margin-bottom: 2vh;
            }

            .discover-more {
            

                .discover-btn {
                    background: none;
                    border: none;
                    color: #15257b;
                    font-size: 1.1vw;
                    font-weight: 500;
                    padding: 0;
                    cursor: pointer;
                    transition: color 0.3s;
                    margin-bottom:1vh;

                    &:hover {
                        color: darken(#15257b, 10%);
                    }
                }
            }
          }
}
.screennews7{
    width: 100vw;
    height: fit-content;
    padding: 5vh 4.09763142692085vw 2vh 4.09763142692085vw;
    background-color: white;
    // background-color: #f8f8f8;
    position: relative;
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    .title-container {
        margin-bottom: 5vh;
        .title {
            color: #15257b;
            font-size: 3vw;
            font-weight: 700;
            text-align: center;
            margin: 0;
            line-height: 1.2;
        }
    }

.light {
margin: 3vh auto 3vh auto;
text-align: center;
width:66vw;
height: fit-content;
font-size: 1.5vw;
font-family: Roboto, sans-serif;
font-weight: 400;
color: #15257b;
line-height: 6vh;
}
.photocontent{
  display: flex;

  border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
      border-radius: 9px;
}
.photocontent1{
  width: 100%;
  position: relative;
  text-align: center; /* 让文字居中 */
  margin-top:2vh;
  margin-bottom:2vh;
}
.photocontent1 img{
  width: 20.7vw;
  height: 39vh;
  border-radius: 12px;
}
  .newtitle3 {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2vw;
  font-weight: 400;
  color: white; /* 文字颜色，建议与图片背景有对比 */
  padding: 5px 10px;
  border-radius: 5px;
  width:100%;
  cursor: pointer;
  }
.newtitle3:hover {
  color: #007BFF; /* 鼠标悬停时文字颜色变化 */
}
  .newtitle4 {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2vw;
  font-weight: 400;
  color: white; /* 文字颜色，建议与图片背景有对比 */
  padding: 5px 10px;
  border-radius: 5px;
  width:100%;
  cursor: pointer;
  }
  .newtitle4:hover {
  color: #007bff; /* 鼠标悬停时文字颜色变化 */
}
.text-content {
            margin-left:3vw;
            margin-top:3vh;
            width:75%;
            .main-description {
                font-size: 1.4vw;
                line-height: 1.8;
                margin-bottom: 3vh;
                // color: #000000;
                color:#555555;
            }

            .sub-description {
                font-size: 1.1vw;
                line-height: 1.8;
                // color: #000000;
                color: #616161;
                margin-bottom: 2vh;
            }

            .discover-more {
                margin-top: 2vh;

                .discover-btn {
                    background: none;
                    border: none;
                    color: #15257b;
                    font-size: 1.1vw;
                    font-weight: 500;
                    padding: 0;
                    cursor: pointer;
                    transition: color 0.3s;

                    &:hover {
                        color: darken(#15257b, 10%);
                    }
                }
            }
        }
  }
  .screennews8{
  width: 100vw;
    height: fit-content;
    padding: 5vh 4.09763142692085vw 2vh 4.09763142692085vw;
    background-color: white;
    // background-color: #f8f8f8;
    position: relative;
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    .title-container {
        margin-bottom: 5vh;
        .title {
            color: #15257b;
            font-size: 3vw;
            font-weight: 700;
            text-align: center;
            margin: 0;
            line-height: 1.2;
        }
    }

.light {
margin: 3vh auto 3vh auto;
text-align: center;
width:66vw;
height: fit-content;
font-size: 1.5vw;
font-family: Roboto, sans-serif;
font-weight: 400;
color: #15257b;
line-height: 6vh;
}
.containerzong{
  width: 100%;
  display: flex;
  border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
  border-radius: 9px;
  // flex-direction: space-between; /* 确保整体的排布是等间距的 */
  // align-items: center;
  // gap: 20px; /* 元素之间的间隔 */
  }
.container{
  justify-content: center; /* 确保图片在容器中居中对齐 */
}
.photocontent{
  display: flex;
  flex-direction: space-between; /* 确保整体的排布是等间距的 */
  align-items: center;
  gap: 1vw; /* 元素之间的间隔 */

}
.photo-container{
  width: 80%; /* 可以根据需要调整 */
  justify-content: center; /* 确保图片在容器中居中对齐 */
  flex-wrap: wrap; /* 如果需要，超出宽度时换行 */
  margin-left: 70px;
}
.photocontent1{
  position: relative;
  text-align: center; /* 让文字居中 */
}
.photocontent1 img {
  height: auto;
  display: block;
  margin: 0 auto;
  border-radius: 12px;
  width: 100%;
}
.newtitle3 {
  position: absolute;
  top: 86%;
  left: 50%;
  width:100%;
  transform: translate(-50%, -50%);
  font-size: 1.2vw;
  font-weight: 400;
  color: white; /* 文字颜色，建议与图片背景有对比 */
  // padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  // transition: color 0.3s, transform 0.3s;
}
.newtitle3:hover {
  color: #007bff; /* 鼠标悬停时文字颜色变化 */
}
.photocontent2 {
  position: relative;
  text-align: center; /* 让文字居中 */
  width: 70%; /* 可以根据需要调整 */
  height: 100%;
}

.photocontent2 img {
  width: 100%; /* 可以根据需要调整 */
  height: 100%;
  border-radius: 12px;
}
.photocontent2 .newtitle4 {
  width: 100%;
  position: absolute;
  top: 23%;
  left: 30%;
  transform: translate(-50%, -50%);
  font-size: 1.8vw;
  font-weight: bold;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
}
 .newtitle4:hover {
  color: #007bff; /* 鼠标悬停时文字颜色变化 */
}
.text-content {
            margin-left:1.8vw;
            width:28%;
            margin-top:-20vh;
            .main-description {
                font-size: 1.4vw;
                line-height: 1.8;
                margin-bottom: 2vh;
                // color: #000000;
                color:#555555;
            }

            .sub-description {
                font-size: 1.1vw;
                line-height: 1.8;
                // color: #000000;
                color:#616161;
                margin-bottom: 2vh;
            }

            .discover-more {
                

                .discover-btn {
                    background: none;
                    border: none;
                    color: #15257b;
                    font-size: 1.1vw;
                    font-weight: 500;
                    padding: 0;
                    cursor: pointer;
                    transition: color 0.3s;
                    margin-bottom: 3vh;
                    &:hover {
                        color: darken(#15257b, 10%);
                    }
                }
            }
        }
}
.screennews9{
  width: 100vw;
    height: fit-content;
    padding: 5vh 4.09763142692085vw 4vh 4.09763142692085vw;
    background-color: white;
    // background-color: #f8f8f8;
    position: relative;
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    .title-container {
        margin-bottom: 5vh;
        .title {
            color: #15257b;
            font-size: 3vw;
            font-weight: 700;
            text-align: center;
            margin: 0;
            line-height: 1.2;
        }
    }

.light {
margin: 3vh auto 3vh auto;
text-align: center;
width:66vw;
height: fit-content;
font-size: 1.5vw;
font-family: Roboto, sans-serif;
font-weight: 400;
color: #15257b;
line-height: 6vh;
}
.photocontent{
  // width:auto;
  // height:auto;
  display: flex;
  border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
      border-radius: 9px;
}
.photocontent img{
  border-radius: 12px;
  width:38vw;
  height:37vh;
  margin-left:3.5vw;
  margin-top:2vh;
  margin-bottom: 2vh;
}
.text-content {
           width:40%;
            .main-description {
                font-size: 1.4vw;
                line-height: 1.8;
                margin-bottom: 2vh;
                // color: #000000;
                color: #555555;
            }

            .sub-description {
                font-size: 1.1vw;
                line-height: 1.8;
                // color: #000000;
                color: #616161;
                margin-bottom: 2vh;
            }

            .discover-more {
                margin-top: 2vh;

                .discover-btn {
                    background: none;
                    border: none;
                    color: #15257b;
                    font-size: 1.1vw;
                    font-weight: 500;
                    padding: 0;
                    cursor: pointer;
                    transition: color 0.3s;
                    margin-bottom: 4vh;

                    &:hover {
                        color: darken(#15257b, 10%);
                    }
                }
            }
        }
}

// 我们的研究模块新增样式
.title-top{
    font-size: 1.5vw;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #333333;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .title-bottom{
    font-size: 1.2vw;
    margin-top: 2vh;
    line-height: 4vh;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #909399;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .serviceUs {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 5vh 10vw 0 10vw;
    background-position: center;
    background-size: cover;
    background-color: white;
    .intro2{
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 1.5vw;
      color: #333333;
      line-height: 2vh;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
    .serviceStyle {
      margin-top: 5vh;
      padding:5vh 2vw 3vh 2vw;
      column-gap: 0.5vw;
      // overflow: hidden;
      width:100%;
      background: #F5F5F5;
      //border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
      .discover-more {
        margin-top: 2vh;

        .discover-btn {
          background: none;
          border: none;
          color: #3366F2;
          // color: #084E8A;
          font-size: 1.1vw;
          font-weight: 500;
          padding: 0;
          cursor: pointer;
          transition: color 0.3s;

          &:hover {
            color: darken(#3366F2, 20%);
          }
        }
      }
      .text-content {
        text-align: left;

      }

      .imgStyle{
        width: 100%;
        height: fit-content;
        margin:auto auto 0 auto;
        position: relative;
        .titleStyle{
          position: absolute;
          top:0;
          color:white;
          padding: 5vh 2vw;

        }
      }
      .pro2 {
        width:100%;
        height: fit-content;
        overflow-x: hidden;
        display: flex;
        column-gap: 1vw;

        .grand1 {
          cursor: pointer;
          width: 100%;
          opacity: 1;
          position: relative;
          height: fit-content;
          .back {
            width: 100%;
            margin:0 auto 0 0;
            height:fit-content;
            // border-radius:6px;
            // display: flex;
          }

          .back1 {
            width: 100%;
            height:fit-content;
            // margin-top:2vh;
            border-top: none;
            // border-radius:6px;
            padding: 1vh 0;
            justify-content: space-between;
          }

          .photo {
            //background-image: url("../assets/pro1.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            display: flex;
            // display: inline-block;
            height: fit-content;
            // border-top-right-radius: 9px;
            // border-top-left-radius: 9px;
            position: relative;
          }
          .photo img{
            width: 100%; /* 设置图片的宽度为父容器的宽度，也可以调整为固定宽度 */
            height: auto; /* 保持图片比例 */
          }
          .photohover{
            position: absolute;
            bottom: 0;
            left: 0; /* 初始位置在容器左侧外面 */
            width: 0%; /* 初始宽度为0 */
            height: 2vh;
            background-color: #2f6dfd;
            transition: width 0.5s ease; /* 平滑过渡效果 */
          }
          //.photolist {
          //  font-size: 5vw;
          //  color: #e3e8ed;
          //  margin:auto 1vw 0 2vw;
          //  height: 100%;
          //  width: fit-content;
          //  font-weight: bold;
          //}

          .title1 {
            text-align: left;
            font-size: 1.5vw;
            font-family: Roboto, sans-serif;
            font-weight: bold;
            overflow: hidden;
            height: fit-content;
            //line-height: 35px;
            margin-top:-3vh;
          }


        }
        .grand1:hover{
          .photohover {
            width: 100%; /* 鼠标悬浮时宽度变为100% */
          }
        }
      }

    }
    .title6 {
      position: absolute;
      color: #FFFFFF;
      text-shadow: 0px 1px 2px rgba(0,0,0,0.5);
      font-style: normal;
      transform: translateX(-50%);
      left:50%;
      bottom:3vh; /* 距离图片底部20px */
      text-align: center;
      font-size: 20px;
      font-family: Roboto, sans-serif;
      font-weight: 400;
      overflow: hidden;
      height: fit-content;
      width: 24vw;
      //line-height: 35px;
      // white-space: nowrap; /* 防止文字换行 */
      cursor: pointer; /* 鼠标悬停时显示为手指形状 */
      // transition: color 0.3s ease, transform 0.3s ease; /* 平滑的颜色变化和变换效果 */
    }
    .title6new {
      position: absolute;
      color: #FFFFFF;
      text-shadow: 0px 1px 2px rgba(0,0,0,0.5);
      font-style: normal;
      transform: translateX(-50%);
      left:50%;
      bottom:2vh; /* 距离图片底部20px */
      text-align: center;
      font-size: 20px;
      font-family: Roboto, sans-serif;
      font-weight: 400;
      overflow: hidden;
      height: fit-content;
      //line-height: 35px;
      width: 24vw;
      cursor: pointer; /* 鼠标悬停时显示为手指形状 */
      // transition: color 0.3s ease, transform 0.3s ease; /* 平滑的颜色变化和变换效果 */
    }
    .title6new1 {
      position: absolute;
      color: #FFFFFF;
      text-shadow: 0px 1px 2px rgba(0,0,0,0.5);
      font-style: normal;
      transform: translateX(-50%);
      left:50%;
      bottom:2vh; /* 距离图片底部20px */
      text-align: center;
      font-size: 16px;
      font-family: Roboto, sans-serif;
      font-weight: 400;
      overflow: hidden;
      height: fit-content;
      //line-height: 35px;
      width: 15vw;
      cursor: pointer; /* 鼠标悬停时显示为手指形状 */
      // transition: color 0.3s ease, transform 0.3s ease; /* 平滑的颜色变化和变换效果 */
    }
    .title6new2 {
      position: absolute;
      color: #FFFFFF;
      text-shadow: 0px 1px 2px rgba(0,0,0,0.5);
      font-style: normal;
      transform: translateX(-50%);
      left:50%;
      bottom:3vh; /* 距离图片底部20px */
      text-align: center;
      font-size: 16px;
      font-family: Roboto, sans-serif;
      font-weight: 400;
      overflow: hidden;
      height: fit-content;
      //line-height: 35px;
      // width: 13vw;
      white-space: nowrap;
      cursor: pointer; /* 鼠标悬停时显示为手指形状 */
      // transition: color 0.3s ease, transform 0.3s ease; /* 平滑的颜色变化和变换效果 */
    }
    .title6new3 {
      position: absolute;
      color: #FFFFFF;
      text-shadow: 0px 1px 2px rgba(0,0,0,0.5);
      font-style: normal;
      transform: translateX(-50%);
      left:50%;
      bottom:3vh; /* 距离图片底部20px */
      text-align: center;
      font-size: 16px;
      font-family: Roboto, sans-serif;
      font-weight: 400;
      overflow: hidden;
      height: fit-content;
      //line-height: 35px;
      width: 13vw;
      cursor: pointer; /* 鼠标悬停时显示为手指形状 */
      // transition: color 0.3s ease, transform 0.3s ease; /* 平滑的颜色变化和变换效果 */
    }
    .title6new4 {
      position: absolute;
      color: #FFFFFF;
      text-shadow: 0px 1px 2px rgba(0,0,0,0.5);
      font-style: normal;
      transform: translateX(-50%);
      left:50%;
      bottom:3vh; /* 距离图片底部20px */
      text-align: center;
      font-size: 16px;
      font-family: Roboto, sans-serif;
      font-weight: 400;
      overflow: hidden;
      height: fit-content;
      //line-height: 35px;
      // width: 13vw;
      white-space: nowrap;
      cursor: pointer; /* 鼠标悬停时显示为手指形状 */
      // transition: color 0.3s ease, transform 0.3s ease; /* 平滑的颜色变化和变换效果 */
    }
    /* 鼠标悬浮时的效果 */
    .title6:hover {
      font-weight: 600;
      /* 悬浮时的颜色 */
      // transform: scale(1.25);
      /* 轻微放大效果 */
    }
    .title6new:hover {
      font-weight: 600;
      /* 悬浮时的颜色 */
      // transform: scale(1.25);
      /* 轻微放大效果 */
    }
    .title6new1:hover {
      font-weight: 600;
      /* 悬浮时的颜色 */
      // transform: scale(1.25);
      /* 轻微放大效果 */
    }
    .title6new2:hover {
      font-weight: 600;
      /* 悬浮时的颜色 */
      // transform: scale(1.25);
      /* 轻微放大效果 */
    }
    .title6new3:hover {
      font-weight: 600;
      /* 悬浮时的颜色 */
      // transform: scale(1.25);
      /* 轻微放大效果 */
    }
    .title6new4:hover {
      font-weight: 600;
      /* 悬浮时的颜色 */
      // transform: scale(1.25);
      /* 轻微放大效果 */
    }
    .title6new4.box_10 {
      font-weight: 600;
      /* 悬浮时的颜色 */
      // transform: scale(1.25);
      /* 轻微放大效果 */
    }
    .gap1{
      width: 35%;
      margin-top: 4vh;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .text-content {
        width:100%;

      }
    }
    .gap2{
      width: 63%;
      .photocontent{
        display: flex;
        border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
        border-radius: 9px;
      }
      .photocontent1{
        width: 100%;
        cursor: pointer;
        position: relative;
        text-align: center; /* 让文字居中 */
        margin-top:3vh;
      }
      .photocontent1 img{
        // width: 22vw;
        // height: 33vh;
        width: 100%;
        height: fit-content;
      }



    }
  }

  // 新增全球人才模块
  .enjoyUs {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 5vh 0 0 0;
    background-position: center;
    background-size: cover;
    background-color: white;
    .intro2{
      width: 95%;
      height: fit-content;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.5vw;
      color: #333333;
      // line-height: 2vh;
      text-align: left;
      font-style: normal;

    }
    .login{
      width: fit-content;
      margin : 8vh auto 0 auto;
      display: grid;
      grid-template-columns: minmax(0,1fr) minmax(0,1fr);
      column-gap: 3vw;
      font-size: 1vw;
      z-index:999;
      .loginUp{
        cursor: pointer;
        padding:1vh 3vw;
        border:1px solid #3366F2;
        color:#3366F2;
        font-family: Roboto, sans-serif;
        border-radius: 53px;
      }
      .loginUp:hover{
        background-color: #3366F2;
        color:white;

      }
      .loginIn{
        cursor: pointer;
        padding:1vh 3vw;
        font-family: Roboto, sans-serif;
         background-color:#3366F2;
        border:1px solid #3366F2;
        color:white;
        border-radius: 53px;
      }
      .loginIn:hover{
        border:1px solid #3366F2;
         color:  #3366F2;
        background-color: #ffffff;
      }
    }
    .searchBack{
      width:100vw;
      position: absolute;
      height: 52vh;
      top:25.5vh;
      display: block;
      // left:4vw;
      //filter: blur(10px);

      .searchContent{
        display: flex;
        animation: myMove 30s linear infinite;
        animation-fill-mode: forwards;
        border: rgba(128, 128, 128, 0.2) 1px solid;
        border-radius: 9px;
        padding: 1vh 2vw 1vh 0vw;
        // margin:3vh auto;
        height:fit-content;
        align-items: center;
      }
      .searchItem{
        height: 27vh;
        width:100%;
        gap:10px;
        margin-bottom:50vh;
        overflow-x: hidden;
        mask-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 1),  rgba(0, 0, 0, 1), rgba(0, 0, 0, 1),rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)); /* 新增 */
        -webkit-mask-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 1),rgba(0, 0, 0, 1),  rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
      }
      .loginUp {
        cursor: pointer;
        margin-top: 5vh;
        font-family: Roboto, sans-serif;
        padding: 1vh 3vw;
        //border: 1px solid #192079;
        color: white;
        font-size:1vw;
        text-align: center;
        background-color: #192079;
        border-radius: 53px;
      }

      .loginUp:hover {
        background-color: rgba(25, 32, 121, 0.5);;
      }

      .photo{
        height: 7vh;
        width: 10vw;
        display: flex;
        align-items: center;
        img{
          object-fit:contain ;
          height:100%;
          width:100%;
          margin-left:-2vw;
        }
      }
      .content{
        // margin: 0  0 0 2vw;
        transition: opacity 0.3s ease-in-out; /* 新增 */
        position: relative; /* 新增 */
        z-index: 1; /* 新增 */
        min-height: 3vh;     /* 增加内容区域的最小高度 (50% 视窗高度) */
        .title1{
          font-size: 1.3vw;
          line-height: 5.5vh;
        }
        .title2{
          font-size: 1vw;
          line-height: 2.4vh;
          width:9vw;
          height: fit-content;
          margin-top:2.7vh;
          margin-left:-3vw;
        }
      }
      @keyframes myMove {
        0% {
          transform: translateX(0vW);
          //opacity: 0.5;
        }
        100% {
          transform: translateX(-200vW);
          //opacity: 0.5;
        }

      }
    }

  }

.screennews {
    width: 100vw;
    height: fit-content;
    padding: 7vh 4.09763142692085vw 5vh 4.09763142692085vw;
    background-color: white;
    // background-color: #f8f8f8;
    position: relative;
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../assets/new/关于我们背景.png");
    // border: 2px solid #cccccc;

    .title-container {
        margin-bottom: 5vh;

        .title {
            color: #15257b;
            // font-size: 3vw;
            // font-weight: 700;
            font-size: 60px;
            font-weight: 400;
            text-align: center;
            margin: 0;
            line-height: 1.2;
        }
    }
    .zong-content{
      display:flex;
      height:25vw;
    }
    .sub-content1{
     height:100%;
     border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
     box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
     border-radius: 9px;
     width:30vw;
     background-color: rgba(255,255,255,1);
    }
    .photo-111{
      width: 100%;
      height: fit-content;
      margin-left:30px;
      margin-top:10px;
      img{
        width: 50%;
        height: 50%;
      }
    }
    .photo-222{
      width: 100%;
      height: 12vw;
      // margin-top:14vh;
      img{
        width: 80%;
        height: 80%;
      }
      .item4-text{
          text-align: center;
          // color: #15257b;
          color: #123456;
          font-size: 2vw;
          margin-bottom:2vh;
          font-weight:300;
        }
        .item4-title{
          font-size:9vw;
          color:#123456;
          font-weight:400;
          font-family: 'Noto Sans CJK SC', sans-serif; /* 字体设置为 Noto Sans CJK SC */
          margin-left: 2.8vw;
        }
        .item4-title .large-number {
        font-size: 5vw;  /* 设置 10 的字体大小比 K 更大 */
        font-weight:300;
        font-family: 'Noto Sans CJK SC', sans-serif; /* 字体设置为 Noto Sans CJK SC */
          }
        .item4-title .plus {
            font-size: 4vw;  /* 调整 + 的大小 */
            vertical-align: super;  /* 设置为上标对齐 */
            position: relative;
            top: -0.9em;  /* 微调位置，确保 + 符号位于右上角 */
            left:-0.5em;  /* 微调位置，确保 + 符号位于右上角 */
}
    }
    .sub-content2{
      height:100%;
      border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
     border-radius: 9px;
     width:30vw;
     margin-left:2.1vw;
     background-color: rgba(18,52,86,1);
    }
    .photo-111{
      width: 100%;
      height: fit-content;
      margin-left:30px;
      margin-top:10px;
      img{
        width: 50%;
        height: 50%;
      }
    }
    .photo-555{
      width: 100%;
      height: fit-content;
      margin-top:11vh;
      img{
        width: 80%;
        height: 80%;
      }
    .item4-text{
          text-align: center;
          // color: #15257b;
          // color: #123456;
          color:white;
          font-size: 1.9vw;
          font-weight:300;
          margin-bottom:1.5vh;
        }
        .item4-title{
          font-size:9vw;
          // color:#123456;
          color:white;
          font-weight:400;
          font-family: 'Noto Sans CJK SC', sans-serif; /* 字体设置为 Noto Sans CJK SC *
        }
        .item4-title .large-number {
        // font-size: 11vw;  /* 设置 10 的字体大小比 K 更大 */
        font-size: 5vw;  /* 设置 10 的字体大小比 K 更大 */
        font-weight:300;
        font-family: 'Noto Sans CJK SC', sans-serif; /* 字体设置为 Noto Sans CJK SC */
          }
        .item4-title .plus {
            font-size: 4vw;  /* 调整 + 的大小 */
            vertical-align: super;  /* 设置为上标对齐 */
            position: relative;
            top: -0.9em;  /* 微调位置，确保 + 符号位于右上角 */
            left: 0.1em;  /* 微调位置，确保 + 符号位于右上角 */
}
      }
    .sub-content3{
      height:48.5%;
      border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
      border-radius: 9px;
      width:30vw;
      margin-left:2.1vw;
      margin-bottom:1vh;
      background-color: rgba(255,255,255,1);
    }
    .photo-333{
      width: 100%;
      height: fit-content;
      margin-top:6vh;
      margin-left:25px;
      img{
        width: 80%;
        height: 80%;
      }
      .item4-text{
          text-align: center;
          // color: #15257b;
          color: #123456;
          font-size: 1.8vw;
          font-weight:300;
        }
        .item4-title{
          font-size:9vw;
          color:#123456;
          font-weight:400;
          font-family: 'Noto Sans CJK SC', sans-serif; /* 字体设置为 Noto Sans CJK SC */
        }
        .item4-title .large-number {
        // font-size: 11vw;  /* 设置 10 的字体大小比 K 更大 */
        font-size: 5vw;  /* 设置 10 的字体大小比 K 更大 */
        font-weight:300;
        font-family: 'Noto Sans CJK SC', sans-serif; /* 字体设置为 Noto Sans CJK SC */
          }
        .item4-title .plus {
            font-size: 4vw;  /* 调整 + 的大小 */
            vertical-align: super;  /* 设置为上标对齐 */
            position: relative;
            top: -0.8em;  /* 微调位置，确保 + 符号位于右上角 */
            left: 0.1em;  /* 微调位置，确保 + 符号位于右上角 */
}
    }

        .sub-content4{
      height:49.5%;
      border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
      border-radius: 9px;
      width:30vw;
      margin-left:2.1vw;
      background-color: rgba(255,255,255,1);
    }
    .photo-444{
      width: 100%;
      height: fit-content;
      margin-top:6vh;
      margin-left:25px;
      img{
        width: 80%;
        height: 80%;
      }
      .item4-text{
          text-align: center;
          // color: #15257b;
          color: #123456;
          font-size: 1.8vw;
          // margin-left:0.5vw;
          font-weight:300;
         
        }
        .item4-title{
          font-size: 9vw;
          color:#123456;
          font-weight:400;
          font-family: 'Noto Sans CJK SC', sans-serif; /* 字体设置为 Noto Sans CJK SC */
        }
        .item4-title .large-number {
        // font-size: 11vw;  /* 设置 10 的字体大小比 K 更大 */
        font-size: 5vw;  /* 设置 10 的字体大小比 K 更大 */
        font-weight:300;
        font-family: 'Noto Sans CJK SC', sans-serif; /* 字体设置为 Noto Sans CJK SC */
          }
        .item4-title .plus {
            font-size: 4vw;  /* 调整 + 的大小 */
            vertical-align: super;  /* 设置为上标对齐 */
            position: relative;
            top: -0.8em;  /* 微调位置，确保 + 符号位于右上角 */
            left: 0.1em;  /* 微调位置，确保 + 符号位于右上角 */
}
    }


    .content-itemnew4{
      display:grid;
      grid-template-columns: minmax(0, 1fr)  minmax(0, 1fr)  minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 3vw;
      width: 100%;
      margin-top: 4vh;
      .item4{
        flex: 1;
      padding: 3vh 2vw;
      // background: white;
      background-color: transparent;
      border-radius: 8px;
      // box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
      transition: transform 0.3s ease;
      position: relative;
        .item4-title{
          color: #15257b;
         font-size: 4.7vw;
         font-weight: 600;
         text-align: center;
         transition: font-size 0.3s ease; /* 添加平滑过渡效果 */
        }
        .item4-title:hover {
          font-size: 4.9vw; /* 悬浮时字体变大 */
        }
        .item4-text{
          text-align: center;
          color: #15257b;
          font-size: 1.3vw;
        }
      }
      .Know-more{
        margin-top: 4vh;
        position: absolute;
      .know-btn {
                    background: none;
                    border: none;
                    color: blue;
                    font-size: 1.1vw;
                    font-weight: 600;
                    padding: 0;
                    cursor: pointer;
                    transition: color 0.3s;
                    margin-top: 7vh ;
                    margin-left: 81vw;
                    &:hover {
                        color: darken(#15257b, 10%);
                    }
                }
              }
    }
    .content-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 4vw;
       margin-top:1.5vh;
       border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
       box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
       border-radius: 9px;
       background-color: rgba(255,255,255,1);
        .text-content {
            flex: 1;
            .text-title{
              font-size:  1.8vw;
              font-weight:bold;
              margin-top:3vh;
              margin-left:2vw;
            }
            .main-description {
                font-size: 1.2vw;
                line-height: 1.8;
                margin-bottom: 3vh;
                // color: #000000;
                color: #555555;
                margin-top:3vh;
                margin-left:2vw;
            }

            .sub-description {
                font-size: 1.2vw;
                line-height: 1.8;
                // color: #000000;
                color: #616161;
                margin-bottom: 4vh;
                margin-left:2vw;
            }

            .discover-more {
                margin-top: 2vh;

                .discover-btn {
                    background: none;
                    border: none;
                    color: #15257b;
                    font-size: 1.1vw;
                    font-weight: 600;
                    padding: 0;
                    cursor: pointer;
                    transition: color 0.3s;

                    &:hover {
                        color: darken(#15257b, 10%);
                    }
                }
            }
        }

        .video-container {
            width: 50%;
            min-width: 30vw;
            margin-top:1.5vh;
            margin-right:0.5vw;
            video {
                width: 99%;
                height: 100%;
                // border-radius: 0.5vw;
            }
        }
    }

    @media (max-width: 768px) {
        padding: 8vh 5vw;
        .title {
            font-size: 6vw;
        }

        .content-container {
            flex-direction: column;
            gap: 4vh;

            .text-content {
                .main-description,
                .sub-description {
                    font-size: 3.5vw;
                }

                .discover-btn {
                    font-size: 3vw;
                }
            }

            .video-container {
                width: 100%;
                min-width: unset;
            }
        }
    }
}
.screennews5{
    width: 100%;
    height: fit-content;
    padding: 0.1vh 4.09763142692085vw 2vh 4.09763142692085vw;
    background-color: white;
    // background-color: #f8f8f8;
    position: relative;
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../assets/new/关于我们背景.png');
    .content-containernews5{
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .content-itemnew1{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 4vw;
        width: 90%;
    .photo-1{
       width: 45%;
       height: 500px;
      img{
        border-radius: 10px;
        width: 100%;
        height: fit-content;
      }
      }
      .title-content{
        width:50%;
         flex: 2;
        margin-left: 20px;
      .main-title{
                font-size: 1.3vw;
                line-height: 1.8;
                margin-bottom: 3vh;
                // color: #15257b;
                color:#123456;
                // color:black;
                font-weight: bold; /* 加粗字体 */
                transition: font-size 0.3s ease; /* 添加平滑过渡效果 */
      }
      .main-title:hover {
        font-size: 1.5vw;
      }
      .sub-text{
                font-size: 1.2vw;
                line-height: 1.8;
                // color: #15257b;
                color: #123456;
                // color:black;
                margin-bottom: 4vh;
      }

      .item1111 {
       position: relative;
      padding-left: 30px;  /* 给文字左边留出空间 */
       }

       .item1111::before {
        content: '';
        position: absolute;
        left: 0;
         top: 50%;
         transform: translateY(-50%);
        width: 10px;
        height: 10px;
       background-color:  #15257b;;  /* 圆圈的颜色 */
        border-radius: 50%;
}
    }

    }
    .content-itemnew2{
      display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 4vw;
        width: 90%;
        .photo-1{
       width: 45%;
       height: 300px;
       img{
        border-radius: 10px;
      }
      }
      .title-content{
         flex: 1;
        //  margin-left:5px;
      .main-title{
                font-size: 1.3vw;
                line-height: 1.8;
                margin-bottom: 3vh;
                color: #15257b;
                // color: #000000;
                font-weight: bold; /* 加粗字体 */
                transition: font-size 0.3s ease; /* 添加平滑过渡效果 */
      }
      .main-title:hover {
        font-size: 1.5vw;
      }
      .sub-text{
                font-size: 1.2vw;
                line-height: 1.8;
                color: #15257b;
                // color:#000000;
                margin-bottom: 4vh;
      }

      .item1111 {
       position: relative;
      padding-left: 30px;  /* 给文字左边留出空间 */
       }

       .item1111::before {
        content: '';
        position: absolute;
        left: 0;
         top: 50%;
         transform: translateY(-50%);
        width: 10px;
        height: 10px;
       background-color:  #15257b;;  /* 圆圈的颜色 */
        border-radius: 50%;
}
    }
    }
    .content-itemnew3{
        display: flex;
        justify-content: space-between;
        // align-items: center;
        gap: 4vw;
        width: 100%;
        .photo-1{
       width: 45%;
       height: 280px;
       img{
        border-radius: 10px;
      }
      }
      .title-content{
         flex: 1;
         width: 100%;
         margin-left: 20px;
         display: flex;
         border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
         box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
         border-radius: 9px;
         padding: 0.3vh 1vw;
         background-color: rgba(18,52,86,1);
        //  align-items: center;
         .section {
             width: 100%;
            padding: 2vh 1vw;
            height:fit-content;
            display:flex;
            flex-direction: column;
            align-items: center;
         }
          .vertical-line {
          width: 2px;
          background-color: rgba(255,255,255,1); /* 竖线颜色 */
          box-shadow: 0 0 5px rgba(0, 0, 0, 1); /* 为竖线添加明显的阴影 */
          margin: 1vh 2vw; /* 设置竖线的间距 */
}
      .main-title{
               font-size: 1.5vw;
               font-weight: 400;
                line-height: 1.8;
                margin-bottom: 2vh;
                // color: #15257b;
                // color:#123456;
                color:white;
                // color:black;
                transition: font-size 0.3s ease; /* 添加平滑过渡效果 */
      }
      .main-title:hover {
        font-size: 1.5vw;
      }
      .sub-text{
                font-size: 1vw;
                font-weight: 300;
                line-height: 1.8;
                // color: #15257b;
                // color:#123456;
                color:white;
                // color:black;
                // margin-bottom: 4vh;
      }
      .item1111 {
       position: relative;
      // padding-left: 30px;  /* 给文字左边留出空间 */
       }

//        .item1111::before {
//         content: '';
//         position: absolute;
//         left: 0;
//          top: 50%;
//          transform: translateY(-50%);
//         width: 10px;
//         height: 10px;
//        background-color:  #15257b;;  /* 圆圈的颜色 */
//         border-radius: 50%;
// }

    }
    }

    .content-itemnew4{
      display:grid;
      grid-template-columns: minmax(0, 1fr)  minmax(0, 1fr)  minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 3vw;
      width: 100%;
      margin-top: 4vh;
      position: relative; /* 为子元素定位提供参考 */
    }
      .item4{
        flex: 1;
      padding: 3vh 2vw;
      // background: white;
      background-color: transparent;
      border-radius: 8px;
      // box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
      transition: transform 0.3s ease;
      position: relative;   }
        .item4-title{
          color: #15257b;
         font-size: 4.7vw;
         font-weight: 600;
         text-align: center;
         transition: font-size 0.3s ease; /* 添加平滑过渡效果 */
        }
        .item4-title:hover {
          font-size: 5vw; /* 悬浮时字体变大 */
        }

      .Know-more{
        margin-top: 4vh;
        position: absolute;
      }
      .know-btn {
                    background: none;
                    border: none;
                    color: blue;
                    font-size: 1.1vw;
                    font-weight: 600;
                    padding: 0;
                    cursor: pointer;
                    transition: color 0.3s;
                    margin-top: 7vh ;
                    margin-left: 81vw;
                    &:hover {
                        color: darken(#15257b, 10%);
                    }
                }
  }

  .screenSupport {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 15vh 0 15vh 8.09763142692085vw;
    background-image: url("../assets/supportBack.png");
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    display: flex;
    position: relative;

    .title {
      height: fit-content;
      opacity: 1;
      margin-top: -2vh;
      width: 50vw;
      font-size: 3vw;
      color: white;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700;
      line-height: 10vh
    }

    .light {
      margin-top: 3vh;
      text-align: left;
      width: 45vw;
      height: fit-content;
      font-size: 1.8vw;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 400;
      color: white;
      line-height: 6vh;
    }

    .light2 {
      margin-top: 10vh;
      text-align: left;
      width: 45vw;
      height: fit-content;
      font-size: 1.5vw;
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 400;
      color:white;
      line-height: 8vh;
    }

    .login {
      width: fit-content;
      margin: 0 auto;
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 3vw;
      font-size: 1.3vw;

      .loginUp {
        cursor: pointer;
        padding: 1vh 3vw;
        border: 1px solid #192079;
        color: #192079;
        border-radius: 53px;
      }

      .loginUp:hover {
        background-color: #e0dddd;
      }

      .loginIn {
        cursor: pointer;
        padding: 1vh 3vw;
        background-color: #192079;
        color: white;
        border-radius: 53px;
        width: fit-content;
      }

      .loginIn:hover {
        background-color: #777996;
      }
    }

    .photo {
      top: 5vh;
      width: 45vw;
      position: absolute;
      right: 0vw;
      //height:50vh;
    }


  }

  ::v-deep .el-tooltip__content {
    font-size: 1.17094165222415vw !important; /* 你想要的字体大小 */
  }


}

.totalModel {
  // background: #bed8f9;
  background: #fff;
  overflow-x: hidden;
  width: 100vw;
  height: fit-content;
  overflow-y: auto;
  position: relative;
  .screenMap {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    background: #f3f3f3;
    position: relative;
    padding: 0 10px 30px 10px;

    .title {
      padding: 20px 0 10px 0;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      text-align: center;
      //color: white;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 15px auto 0 5px;
      text-align: justify;
      //width: 50vw;
      height: fit-content;
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }
    .top{
      margin:15px 0 0 0 ;
      border-top: rgba(185, 185, 185, 0.5) 1px solid;
      padding-top: 1vh;
      cursor: pointer;

      .high1{
        color:gray;
        font-size: 16px;
        //line-height: 5vh;
        text-align: center;

      }
      .high2{
        color: #fd8a08;
        font-size: 16px;
        height: 20px;
        font-weight: bold;
        width: fit-content;
        margin:5px auto;
        //line-height: 3vh;
        text-align: center;

      }
    }

  }
  .map {
    padding: 0 10px 20px 10px;

    .title {
      padding: 20px 5px 10px 5px;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      text-align: center;
      //color: #53A3F7;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 0px auto 0 auto;
      //text-align: justify;
      width: 90%;
      height: fit-content;
      font-size: 14px;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 400;
      color: #2C3E50;
      line-height: 24px;

    }
  }

  .GEGPTs {
    padding: 0 10px 20px 10px;

    .title {
      padding: 20px 0 10px 0;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      text-align: center;
      //color: #53A3F7;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700;
      line-height: 24px;
    }

    .light {
      margin: 0px auto 0 auto;
      //text-align: justify;
      width: 98%;
      height: fit-content;
      font-size: 14px;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 400;
      color: #2C3E50;
      line-height: 24px;

    }

    .pro2 {
      height: fit-content;
      width: 100%;
      margin-top: 55px;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-bottom-right-radius: 53px;
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
        border-bottom-left-radius: 9px;

        padding: 15px 20px 15px 20px;
        opacity: 1;
        position: relative;
        background-image: url("../assets/groundBack.png");
        background-position: left;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
        height: fit-content;

        .back {
          width: 100%;
          margin: -50px auto 0 0;
          height: fit-content;
          border-radius: 9px;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {

          width: 100%;
          height: fit-content;
          margin-top: 15px;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          padding: 10px 15px;
          justify-content: space-between;
        }

        .photo {
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 40vw;
          display: flex;
          margin: -20px 0 0 5%;
          border-top-right-radius: 83px;
          height: fit-content;
        }

        .photolist {
          font-size: 55px;
          color: #e3e8ed;
          margin: auto 0 0 15px;
          height: 100%;
          font-weight: bold;
        }

        .title1 {
          text-align: left;
          font-size: 16px;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          width: 60vw;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          overflow: hidden;
          height: fit-content;
          //line-height: 35px;
        }

        .title2 {
          display: flex;

          opacity: 4;
          width: fit-content;
          height: fit-content;
          // text-align: justify;
          font-size: 14px;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          color: white;
          margin-top: 10px;
          border-radius: 23px;
          padding: 5px 15px;
          background-color: #2168DB;
          //line-height: 35px;
        }
      }

    }

    .GEGPTs_back_card {
      height: 96px;
      background-color: #d9e6f6;
      margin: 5px;
      background-image: url("../assets/beijing.png");
      display: flex;

      .card_img {
        height: 30px;
        width: fit-content;
        object-fit: contain;
        padding: 0 5px 0 10px;
        margin: auto;
      }

      .title {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 20px;
        height: 40px;
        font-size: 14px;
        margin: auto 2px;
        font-weight: normal;
        padding: 0 2px;
        text-align: left;
      }
    }

  }


  .up {
    cursor: pointer;
    position: fixed;
    top: 90%;
    right: 3%;
    padding: 5px;
    width: 45px;
    height: 45px;
    z-index: 999999999;
    border-radius: 50%;
    //border: 5px solid #1295d9;
    background-image: url("../assets/up.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }

  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    // padding: 0 0 30px 0;
    // background-image: url("../assets/Group 22.png");
    background-image: url("../assets/home/back.png");
    background-position: center;
    // background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */

    .layer2 {
      .title1 {
        margin: 0 auto;
        width: fit-content;
        height: fit-content;
        font-size: 23px;
        font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: 500;
        color: #111111;
        line-height: 35px;
      }

      .title2 {
        margin: 35px auto 0 auto;
        width: fit-content;
        height: 17px;
        font-size: 14px;
        font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: 400;
        color: #111111;
        line-height: 24px;
      }

      .title3 {
        display: flex;
        text-align: center;
        margin: 20px auto 0 auto;
        width: fit-content;
        height: 35px;
        font-size: 8px;
        font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: bold;
        color: #2f6eff;
        line-height: 22px;
      }
    }

    .layer3 {
      position: absolute;
      display: flex;
      bottom: 15px;
      opacity: 1;
      width: 90%;
      justify-content: center;

      .communicity {
        z-index: 8;
        cursor: pointer;
        font-size: 14px;
        font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: 400;
        color: #2f6eff;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center; /* Vertically center the content */
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 10px 4.6875vw;
        height: 35px;
        border: #2f6eff 1px solid;
        overflow: hidden; /* Add overflow property */
      }

      .communicityGpt {
        z-index: 8;
        cursor: pointer;
        font-size: 14px;
        font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: 400;
        color: white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center; /* Vertically center the content */
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 10px 4.6875vw;
        height: 35px;
        background-color: #2f6eff;
        border: #2f6eff 1px solid;
        overflow: hidden; /* Add overflow property */
      }

    }
  }
  .screenFace {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    // background-image: url("../assets/faceBack.png");
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: white;
    padding: 0 10px 20px 10px;
    ::v-deep img{
      object-fit: contain;
    }
    .title {
      padding: 20px 0 10px 0;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      text-align: center;
      color: white;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 0px auto 0 auto;
      text-align: justify;
      width: 98%;
      height: fit-content;
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }

    .pro2 {
      height: fit-content;
      margin-top: 15px;
      overflow-x: hidden;
      //z-index: 89156;

      .grand1 {
        width: 100%;
        padding: 1vh 1vw 2vh 1vw;
        opacity: 1;
        position: relative;
        display: flex;
        height: fit-content;
        .back {
          width: 45px;
          //margin:-8vh auto 0 0;
          height:45px;
          border-radius: 9px;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          width: 100%;
          height:fit-content;
          display: flex;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          padding: 0 5%;
          justify-content: space-between;
        }

        .photo {
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 40px;
          display: flex;
          height: 40px;
          margin-left: 10px;
          position: relative;
        }
        .photohover{
          position: absolute;
          bottom: 0;
          left: 0; /* 初始位置在容器左侧外面 */
          width: 0%; /* 初始宽度为0 */
          height: 2vh;
          background-color: #2f6dfd;
          transition: width 0.5s ease; /* 平滑过渡效果 */
        }
        .photolist {
          font-size: 4.5vw;
          color: #e3e8ed;
          margin:auto 1vw 0 1vw;
          height: 100%;
          font-weight: bold;
        }

        .title1 {
          text-align: left;
          font-size: 16px;
          height:66px ;
          display: flex;
          align-items: center;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;
          overflow: hidden;
          line-height: 22px;
        }

        .title2 {
          display: flex;
          opacity: 4;
          width: fit-content;
          height: fit-content;
          // text-align: justify;
          font-size: 0.9vw;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          color: white;
          margin-top: 2vh;
          border-radius: 23px;
          padding: 5px 15px;
          background-color: #2168DB;
          //line-height: 35px;
        }
      }
      .grand1:hover{
        .photohover {
          width: 100%; /* 鼠标悬浮时宽度变为100% */
        }
      }

    }

    //.pro2 {
    //  height: fit-content;
    //  width: 100%;
    //  margin-top: 10vh;
    //  //z-index: 89156;
    //  display: grid;
    //  grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
    //  row-gap:10vh;
    //  column-gap: 1vw;
    //  .grand1 {
    //    cursor: pointer;
    //    width: 100%;
    //    border-bottom-right-radius: 53px;
    //    border-top-left-radius: 9px;
    //    border-top-right-radius: 9px;
    //    border-bottom-left-radius: 9px;
    //
    //    padding: 1vh 1vw;
    //    opacity: 1;
    //    position: relative;
    //    background-image: url("../assets/groundBack.png");
    //    background-position: left;
    //    background-size: cover;
    //    background-repeat: no-repeat;
    //    background-color: transparent;
    //    height: fit-content;
    //    .back {
    //      width: 100%;
    //      margin:-8vh auto 0 0;
    //      height:fit-content;
    //      border-radius: 9px;
    //      // border-radius:6px;
    //      // display: flex;
    //    }
    //
    //    .back1 {
    //      width: 100%;
    //      height:fit-content;
    //      margin-top:1vh;
    //      border-top: none;
    //      border-bottom-right-radius: 9px;
    //      border-bottom-left-radius: 9px;
    //      // border-radius:6px;
    //      padding: 1vh 1vw;
    //      justify-content: space-between;
    //    }
    //
    //    .photo {
    //      position: relative;
    //      //background-image: url("../assets/pro1.jpg");
    //      background-size: cover;
    //      background-position: center;
    //      background-repeat: no-repeat;
    //      background-color: transparent; /* 这里设置背景颜色为透明 */
    //      opacity: 1.25;
    //      width: 12vw;
    //      display: flex;
    //      margin:-5vh 0  0 5%;
    //      border-radius: 9px;
    //      height: fit-content;
    //    }
    //    .photohover{
    //      position: absolute;
    //      bottom: 0;
    //      left: 0; /* 初始位置在容器左侧外面 */
    //      width: 0%; /* 初始宽度为0 */
    //      height: 2vh;
    //      background-color: #2f6dfd;
    //      transition: width 0.5s ease; /* 平滑过渡效果 */
    //    }
    //    .photolist {
    //      font-size: 5vw;
    //      color: #e3e8ed;
    //      margin:auto 0 0 2.5vw;
    //      height: 100%;
    //      font-weight: bold;
    //    }
    //
    //    .title1 {
    //      text-align: left;
    //      font-size: 1vw;
    //      font-family: Roboto, sans-serif;
    //      font-weight: bold;
    //      width: 60vw;
    //      overflow: hidden;
    //      height: fit-content;
    //      //line-height: 35px;
    //    }
    //
    //    .title2 {
    //      display: flex;
    //
    //      opacity: 4;
    //      width: fit-content;
    //      height: fit-content;
    //      // text-align: justify;
    //      font-size: 0.9vw;
    //      font-family: Roboto, sans-serif;
    //      font-weight: 500;
    //      color: white;
    //      margin-top: 2vh;
    //      border-radius: 23px;
    //      padding: 5px 15px;
    //      background-color: #2168DB;
    //      //line-height: 35px;
    //    }
    //  }
    //  .grand1:hover{
    //    .photohover {
    //      width: 100%; /* 鼠标悬浮时宽度变为100% */
    //    }
    //  }
    //
    //}

  }
  .screenGet {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    background-image: url("../assets/getBack.png");
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    padding: 0 10px 20px 10px;
    ::v-deep img{
      object-fit: contain;
    }
    .title {
      padding: 20px 0 10px 0;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      text-align: center;
      color: white;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 0px auto 0 auto;
      text-align: justify;
      width: 98%;
      height: fit-content;
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }

    .pro2 {
      height: fit-content;
      margin-top: 15px;
      overflow-x: hidden;
      //z-index: 89156;

      .grand1 {
        width: 100%;
        padding: 1vh 1vw 2vh 1vw;
        opacity: 1;
        position: relative;
        display: flex;
        height: fit-content;
        .back {
          width: 45px;
          //margin:-8vh auto 0 0;
          height:45px;
          border-radius: 9px;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          width: 100%;
          height:fit-content;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          padding: 0 5%;
          justify-content: space-between;
        }

        .photo {
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 40px;
          display: flex;
          height: 40px;
          margin-left: 10px;
          position: relative;
        }
        .photohover{
          position: absolute;
          bottom: 0;
          left: 0; /* 初始位置在容器左侧外面 */
          width: 0%; /* 初始宽度为0 */
          height: 2vh;
          background-color: #2f6dfd;
          transition: width 0.5s ease; /* 平滑过渡效果 */
        }
        .photolist {
          font-size: 4.5vw;
          color: #e3e8ed;
          margin:auto 1vw 0 1vw;
          height: 100%;
          font-weight: bold;
        }

        .title1 {
          text-align: left;
          font-size: 16px;
          display: flex;
          align-items: center;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;
          overflow: hidden;
          line-height: 22px;
        }

        .title2 {
          text-align: left;
          margin-top: 15px;
          font-size: 14px;
          display: flex;
          align-items: center;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;
          overflow: hidden;
          line-height: 22px;
        }
      }
      .grand1:hover{
        .photohover {
          width: 100%; /* 鼠标悬浮时宽度变为100% */
        }
      }

    }

    //.pro2 {
    //  height: fit-content;
    //  width: 100%;
    //  margin-top: 10vh;
    //  //z-index: 89156;
    //  display: grid;
    //  grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
    //  row-gap:10vh;
    //  column-gap: 1vw;
    //  .grand1 {
    //    cursor: pointer;
    //    width: 100%;
    //    border-bottom-right-radius: 53px;
    //    border-top-left-radius: 9px;
    //    border-top-right-radius: 9px;
    //    border-bottom-left-radius: 9px;
    //
    //    padding: 1vh 1vw;
    //    opacity: 1;
    //    position: relative;
    //    background-image: url("../assets/groundBack.png");
    //    background-position: left;
    //    background-size: cover;
    //    background-repeat: no-repeat;
    //    background-color: transparent;
    //    height: fit-content;
    //    .back {
    //      width: 100%;
    //      margin:-8vh auto 0 0;
    //      height:fit-content;
    //      border-radius: 9px;
    //      // border-radius:6px;
    //      // display: flex;
    //    }
    //
    //    .back1 {
    //      width: 100%;
    //      height:fit-content;
    //      margin-top:1vh;
    //      border-top: none;
    //      border-bottom-right-radius: 9px;
    //      border-bottom-left-radius: 9px;
    //      // border-radius:6px;
    //      padding: 1vh 1vw;
    //      justify-content: space-between;
    //    }
    //
    //    .photo {
    //      position: relative;
    //      //background-image: url("../assets/pro1.jpg");
    //      background-size: cover;
    //      background-position: center;
    //      background-repeat: no-repeat;
    //      background-color: transparent; /* 这里设置背景颜色为透明 */
    //      opacity: 1.25;
    //      width: 12vw;
    //      display: flex;
    //      margin:-5vh 0  0 5%;
    //      border-radius: 9px;
    //      height: fit-content;
    //    }
    //    .photohover{
    //      position: absolute;
    //      bottom: 0;
    //      left: 0; /* 初始位置在容器左侧外面 */
    //      width: 0%; /* 初始宽度为0 */
    //      height: 2vh;
    //      background-color: #2f6dfd;
    //      transition: width 0.5s ease; /* 平滑过渡效果 */
    //    }
    //    .photolist {
    //      font-size: 5vw;
    //      color: #e3e8ed;
    //      margin:auto 0 0 2.5vw;
    //      height: 100%;
    //      font-weight: bold;
    //    }
    //
    //    .title1 {
    //      text-align: left;
    //      font-size: 1vw;
    //      font-family: Roboto, sans-serif;
    //      font-weight: bold;
    //      width: 60vw;
    //      overflow: hidden;
    //      height: fit-content;
    //      //line-height: 35px;
    //    }
    //
    //    .title2 {
    //      display: flex;
    //
    //      opacity: 4;
    //      width: fit-content;
    //      height: fit-content;
    //      // text-align: justify;
    //      font-size: 0.9vw;
    //      font-family: Roboto, sans-serif;
    //      font-weight: 500;
    //      color: white;
    //      margin-top: 2vh;
    //      border-radius: 23px;
    //      padding: 5px 15px;
    //      background-color: #2168DB;
    //      //line-height: 35px;
    //    }
    //  }
    //  .grand1:hover{
    //    .photohover {
    //      width: 100%; /* 鼠标悬浮时宽度变为100% */
    //    }
    //  }
    //
    //}

  }
  .screenSupport {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    background-image: url("../assets/supportBack.png");
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    position: relative;
    padding: 0 10px 20px 10px;

    .title {
      padding: 20px 0 10px 0;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      text-align: center;
      //color: white;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 20px;
      color: white;
    }

    .light {
      margin: 10px auto 0 0;
      //text-align: justify;
      width:60vw;
      height: fit-content;
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: white;
      line-height: 22px;

    }
    .photo{
      top:60px;
      width: 35vw;
      position: absolute;
      right:10px;
    }
    .light2 {
      margin-top: 45px;
      text-align: left;
      width:95vw;
      display: grid;
      grid-template-columns: minmax(0,1fr) minmax(0,1fr);
      height: fit-content;
      font-size: 13px;
      font-family: Roboto, sans-serif;
      font-weight: 400;
      color: white;
      line-height: 22px;
    }
    .login{
      width: fit-content;
      margin : 0 auto;
      display: grid;
      grid-template-columns: minmax(0,1fr) minmax(0,1fr);
      column-gap: 3vw;
      font-size: 1.3vw;
      .loginUp{
        cursor: pointer;
        padding:1vh 3vw;
        border:1px solid #192079;
        color:#192079;
        border-radius: 53px;
      }
      .loginUp:hover{
        background-color: #e0dddd;
      }
      .loginIn{
        cursor: pointer;
        padding:1vh 3vw;
        background-color:#192079;
        color: white;
        border-radius: 53px;
      }
      .loginIn:hover{
        background-color:  #777996;
      }
    }



  }
  .seriesService000 {
    background-color: white;
    padding: 0 10px 20px 10px;

    .titleti {
      padding: 20px 0 10px 0;
      height: fit-content;
      font-size: 20px;
      text-align: center;
      //color: #53A3F7;
       font-weight: 700;
      line-height: 20px;
    }
    .title {
      padding: 0 0 10px 0;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      text-align: center;
      //color: #53A3F7;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700;
      line-height: 24px;
    }


    .light {
      margin: 0px auto 0 auto;
      text-align: justify;
      width: 90%;
      height: fit-content;
      font-size: 14px;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }

    .box1 {
      box-shadow: 5px 0 14px 0px #D4E4FF;
      margin: 85px 5px 0 5px;
      padding: 10px 10px 20px 10px;
      height: 450px;
      border-bottom-right-radius: 33px;
      background-image: url("../assets/newback.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;

      .back1 {

        width: 100%;
        height: fit-content;
        margin-top: 15px;
        border-top: none;
        border-bottom-right-radius: 9px;
        border-bottom-left-radius: 9px;
        // border-radius:6px;
        padding: 10px 15px;
        justify-content: space-between;
      }

      .back {
        display: flex;
      }

      .photo {
        //background-image: url("../assets/pro1.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
        opacity: 1.25;
        width: 40vw;
        display: flex;
        margin: -75px 0 0 5%;
        border-radius: 9px;
        height: fit-content;
      }

      .photolist {

        //background-image: url("../assets/newicon1.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
        color: #e3e8ed;
        margin: auto auto 0 auto;
        height: 100%;
        width: 10vw;
        font-weight: bold;
      }

      .title {
        padding: 0;
        height: fit-content;
        opacity: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px;
        margin: 15px 0 0 5%;
        text-align: left;
        //color: #53A3F7;
        font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
        font-weight: 700;
        line-height: 20px;
      }

      .light {
        margin: 15px 0 0 5%;
        text-align: left;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
        overflow: hidden;
        text-overflow: ellipsis;
        height: fit-content;
        font-size: 12px;
        font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;

        .lightbehind {
          display: flex;
          border: #8faffa 1px solid;
          background-color: #e4ecfd;
          margin-top: 10px;
          border-radius: 53px;
          padding: 5px 5px;

        }

        .titleHeight {
          width: 80%;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .biaoti {
          width: 15px;
          height: auto;
          margin: auto 10px auto 5px;
        }

        .iconStyle {
          margin: auto 5px auto auto;
          //animation: pulse 2s infinite;
        }

        //@keyframes pulse { 0% { transform: translateX(0px); /* 原始大小 */ } 50% { transform: translateX(5px); /* 放大到120% */ } 100% { transform: translateX(-5px); /* 恢复到原始大小 */ } }
      }
    }


  }

  .screenConsultnew {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 25px 20px;
    background: white;

    .title {

      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 15px auto 0 auto;
      //text-align: justify;
      width: 90%;
      height: fit-content;
      font-size: 16px;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }


    .pro3 {
      //margin-top: -4vh;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      column-gap: 15px;
      row-gap: 0;

      .grand2 {
        cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 15px 0;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          height: 100%;
          background: #D4E4FF;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }


        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 0 auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 150px;
        }

        .title1 {
          opacity: 4;
          margin: 15px auto;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          font-size: 16px;
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-weight: bold;
          //color: #2168DB;
          line-height: 22px;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.9vw;
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-weight: 500;
          font-size: 0.9vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }


    }


  }

  .screenGpts {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    border-radius: 9px;
    padding: 25px 20px;
    background: transparent;

    .title {

      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 15px auto 0 auto;
      //text-align: justify;
      width: 90%;
      height: fit-content;
      font-size: 16px;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }


    .pro3 {
      //margin-top: -4vh;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      column-gap: 15px;
      row-gap: 0;

      .grand2 {
        cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 15px 0;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          height: 100%;
          background: white;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }


        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 0 auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 150px;
        }

        .title1 {
          opacity: 4;
          margin: 15px auto;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          font-size: 16px;
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-weight: bold;
          //color: #2168DB;
          line-height: 22px;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.9vw;
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-weight: 500;
          font-size: 0.9vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }


    }


  }

  .screen3 {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 25px 20px;
    background: white;

    .title {
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      text-align: center;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 15px auto 0 auto;
      //text-align: justify;
      width: 90%;
      height: fit-content;
      font-size: 16px;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }


    .pro3 {
      //margin-top: -4vh;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      column-gap: 15px;
      row-gap: 0;

      .grand2 {
        cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 15px 0;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          height: 100%;
          background: white;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }


        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 0 auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 150px;
        }

        .title1 {
          opacity: 4;
          margin: 15px auto;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          font-size: 16px;
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-weight: bold;
          //color: #2168DB;
          line-height: 22px;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.9vw;
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-weight: 500;
          font-size: 0.9vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }


    }


  }

  .screenConsult1 {
    //z-index: 10;
    width: 100vw;
    border-radius: 9px;
    padding: 25px 20px;
    background: transparent;

    .title {

      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 15px auto 0 auto;
      //text-align: justify;
      width: 90%;
      height: fit-content;
      font-size: 16px;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }


    .pro2 {
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr);
      column-gap: 0;
      row-gap: 0;

      .grand1 {
        transition: transform 0.5s ease;
        cursor: pointer;
        width: 100%;
        padding: 15px 0 0 0;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          background: white;;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          display: flex;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 15px 0 0 15px;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 125px;
          border-radius: 9px;
          height: 110px;
        }


        .title1 {
          opacity: 4;
          margin: 15px 15px 5px 15px;
          text-align: center;

          height: fit-content;
          font-size: 16px;
          white-space: nowrap; //换行
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-weight: bold;
          overflow: hidden;

          text-overflow: ellipsis;
          //color: #2168DB;
          line-height: 22px;
        }

        .title2 {
          opacity: 4;
          margin: 0 15px 15px 15px;
          //text-align: justify;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-weight: 500;
          //color: #2168DB;
          line-height: 22px;
          height: fit-content;
          max-height: 88px;
        }

        .consult {
          transition: transform 0.5s ease;
          position: absolute;
          display: none;
          font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
        }
      }


    }


  }

  .screen8 {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 25px 20px;
    background: transparent;

    .title {

      text-align: center;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 15px auto 0 auto;
      //text-align: justify;
      width: 90%;
      height: fit-content;
      font-size: 16px;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }

    .pro2 {
      transition: transform 0.5s ease;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      padding: 15px;
      display: grid;
      grid-template-columns:minmax(0, 1fr);
      //column-gap: 1vw;
    }


  }
  .our-section {
    // background-image: url('../assets/home/ourbg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    // min-height: 100vh;
    // padding: 20px 0;
}
.our-section1 {
    background-image: url('../assets/home/ourbg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    // min-height: 100vh;
    // padding: 20px 0;
}
}
</style>



