<!--
 * @Author: twy+1-at-831623936441 ISfIgyagCK159zOXbALBCYfPJsXX7JI+UcjxsoGvWhTuiwzjxNFOeYUELbg=
 * @Date: 2025-01-16 16:35:40
 * @LastEditors: twy+1-at-831623936441 ISfIgyagCK159zOXbALBCYfPJsXX7JI+UcjxsoGvWhTuiwzjxNFOeYUELbg=
 * @LastEditTime: 2025-04-03 15:22:24
 * @FilePath: \groundEn\src\views\component\Map.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<script>

import {defineComponent} from "vue";
import Personcard from "@/components/personcard.vue";

export default defineComponent({
  components: {Personcard},
  methods:{
    button1(){
      this.$emit("button1",true)
    },
    button2(){
      this.$emit("button2",true)
    },
  }
})
</script>

<template>
<div class="MapContainer">

  <div class="title">
    Global Shared Support Platform
  </div>
  <div class="title2">
    <!-- <span>
      Connect Team Intelligence, Empower Every Moment of Oil and Gas Development<br>
    </span> -->
    Bringing Together Global Talent to Find the Best Solutions for You
    <!-- Pool talents from around the world and provide online solutions for oil and gas field development, contributing to the production increase and efficiency improvement of oil and gas fields. -->
  </div>

  <personcard style="margin-top: 3vh"></personcard>
  <div class="mapback">
    <img src="../../assets/mapback.png">
  </div>
  <div class="mapbutton">
    <div class="button1" @click="button1">
      Know More
    </div>
    <div class="button1" @click="button2">
      login
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.MapContainer{
  .title{
    // color: white;
    color: #2c3e50;
    font-size: 6vw;
   padding: 4vh 0 2vh;
  }
  .title2{
    font-size: 3.5vw;
    line-height: 8vw;
    color:#2c3e50;
    span{
      font-size: 5vw;
      // letter-spacing: 0.5vw;
      //font-weight: bolder;
    }
  }
  .mapback{
    width: 100%;
    margin-top: 1vh;
  }
  .mapbutton{
    font-size: 4vw;
    color: #2c3e50;
    gap: 2vw;
    display: flex;
    padding: 3vh;
    .button1{
      margin: auto;
      padding: 0.3vh;
      border-radius: 14px;
      text-align: center;
      width: 30vw;
      border: black 1px solid;
    }
  }
}
</style>
