<template>
    <div class="contact-section">
        <h2 class="title">Contact us</h2>
        <!-- <p class="desc">Please use the following information if you have any questions<br />Send an email, call us, or visit our office</p> -->

        <div class="contact-items">
            <div class="contact-item">
                <div class="icon-wrapper">
                    <img :src="headphoneIcon" alt="headphone">
                </div>
                <div class="content">
                    <div class="item-title">Online Consultation</div>
                    <div class="item-desc">Online Customer Service</div>
                </div>
            </div>

            <div class="contact-item">
                <div class="icon-wrapper">
                    <img :src="mailIcon" alt="mail">
                </div>
                <div class="content">
                    <div class="item-title">Email</div>
                    <div class="item-desc">OGDRI@antonoil.com</div>
                </div>
            </div>
            <!-- <div class="contact-item">
                <div class="icon-wrapper">
                    <img :src="mailIcon" alt="mail">
                </div>
                <div class="content">
                    <div class="item-title">Contact Phone
                    </div>
                    <div class="item-desc">+86-18511006028</div>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
// 导入图片资源
import headphoneIcon from '../../../assets/home/contact/headphone.png'
import mailIcon from '../../../assets/home/contact/mail.png'

export default {
    name: 'ContactSection',
    data() {
        return {
            headphoneIcon,
            mailIcon
        }
    }
}
</script>
<style lang="scss" scoped>
.contact-section {

    padding: 30px 15px;
    color: #FFF;

    .title {
        font-size: 28px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 2.8px;
        margin-bottom: 15px;
    }

    .desc {
        color: #FFF;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 30px;
    }

    .contact-items {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .contact-item {
            display: flex;
            align-items: center;
            gap: 15px;

            .icon-wrapper {
                width: 40px;
                height: 40px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .content {
                width: 280px; 
                padding-bottom: 15px;
                border-bottom: 1px solid #4A83B4;  
                .item-title {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 40px;
                    /* 222.222% */
                    letter-spacing: 1.8px;
                    margin-bottom: 5px;
                }

                .item-desc {
                    color: #FFF;
                    font-size: 22px;
                    font-weight: 300;
                    line-height: 26px;
                }
            }
        }
    }
}
</style>
