<template>
  <div>
  <div class="scroll-container">
    <div class="scroll-content">
      <!-- 显示原始图片 -->
      <div class="back"  v-for="(element) in expert" :key="element.id">
<!--        <img :src="src" :alt="'Image ' + index" />-->
        <div class="card">
          <div class="imageDesign">

            <img :src="element.img">
          </div>
          <div class="titleDesign" >
            <!-- <div class="title1">
              {{ element.title }}
            </div> -->
            <div class="title2">
              {{ element.contend }}
            </div>
          </div>
        </div>
      </div>
      <div class="back"  v-for="(element) in expert" :key="element.id">
        <!--        <img :src="src" :alt="'Image ' + index" />-->
        <div class="card">
          <div class="imageDesign">

            <img :src="element.img">
          </div>
          <div class="titleDesign" >
            <!-- <div class="title1">
              {{ element.title }}
            </div> -->
            <div class="title2">
              {{ element.contend }}
            </div>
          </div>
        </div>
      </div>
<!--      <div class="image" v-for="(src, index) in images" :key="'original-' + index">-->
<!--&lt;!&ndash;        <img :src="src" :alt="'Image ' + index" />&ndash;&gt;-->
<!--      </div>-->
<!--       显示复制的图片，用于无缝滚动 -->
<!--      <div class="image" v-for="(src, index) in images" :key="'copy-' + index">-->
<!--        <img :src="src" :alt="'Image ' + index + ' copy'" />-->
<!--      </div>-->
    </div>
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expert: [
        {
          id:1,
          img: require("@/assets/expert/user1.jpg"),
          title: "Expert Chen",
          contend: "Construct Interpretation Expert"
        },
        {
          id:2,
          img: require("@/assets/expert/user2.png"),
          title: "Expert Chen",
          contend: "Stimulation Technology Expert"
        },
        {
          id:3,
          img: require("@/assets/expert/user3.jpg"),
          title: "Expert Cheng",
          contend: "Development Geology Specialists"
        },
        {
          id:4,
          img: require("@/assets/expert/user4.png"),
          title: "Expert Chen",
          contend: "Construct Interpretation Expert"
        },
        {
          id:5,
          img: require("@/assets/expert/user5.jpg"),
          title: "Expert Tian",
          contend: "Total Solution Specialist"
        },
        {
          id:6,
          img: require("@/assets/expert/user6.png"),
          title: "Expert Liang",
          contend: "Logging Interpretation Expert"
        },

        {
          id:7,
          img: require("@/assets/expert/user7.jpg"),
          title: "Expert Wang",
          contend: "Reservoir Engineering Expert"
        },
        {
          id:8,
          img: require("@/assets/expert/user8.jpg"),
          title: "Expert Liang",
          contend: "Reservoir Geology Expert"
        },
        {
          id:9,
          img: require("@/assets/expert/user9.jpg"),
          title: "Expert Liang",
          contend: "Reservoir Forecasting Specialist"
        },
        {
          id:10,
          img: require("@/assets/expert/user10.png"),
          title: "Expert Liu",
          contend: "Development Geology Specialists"
        },
        {
          id:11,
          img: require("@/assets/expert/user11.jpg"),
          title: "Expert Liu",
          contend: "Expert in Geomechanics"
        },
        {
          id:12,
          img: require("@/assets/expert/user12.png"),
          title: "Expert Wu",
          contend: "Reservoir Engineering Expert"
        },
      ],
      images: [
        'https://via.placeholder.com/150?text=Image+1',
        'https://via.placeholder.com/150?text=Image+2',
        'https://via.placeholder.com/150?text=Image+3',
        'https://via.placeholder.com/150?text=Image+4',
        'https://via.placeholder.com/150?text=Image+5'
      ],
      backWidth:110,
      backGap:10
    };
  },
};
</script>

<style scoped>
.scroll-container {
  width: 100%; /* 容器宽度 */
  overflow: hidden; /* 隐藏溢出的内容 */
  position: relative;
}

.scroll-content {
  display: flex;
  animation: scroll-left 30s linear infinite; /* 启动滚动动画，20秒一轮 */
}

.back{
  background-color: transparent;
  margin-left: 10px;
  display: flex;
  //border: 1px solid #909399;
  border-radius: 9px;
  height: fit-content;
  width: fit-content;
}
.card{
  margin: auto;
  width: 50vw;
  height: 15vw;
  //padding: 5px;
  background-color: rgba(233, 236, 244, 0.3);
  display: flex;
  border: white 1px solid;
  border-radius: 9px;
}
.imageDesign{
  margin: auto;
  width: 13vw;
  height: 13vw;
}
.titleDesign{
  //width: 100%;
  width:35vw;
  //height: 40%;
  color: white;
  display: flex;

  .title1{
    font-family: arial,sans-serif,Microsoft Yahei;
    font-weight: bolder;
    text-align: center;
    font-size: 15px;
  }
  .title2{
    font-family: arial,sans-serif,Microsoft Yahei;
    text-align: center;
    color: #2c3e50;
    font-size: 3.5vw;
    display: flex;
    align-content: center;
    margin:auto;
    //height:100%;
  }
}

.imageDesign img {
  width: 100%; /* 图片宽度 */
  height: 100%; /* 图片高度 */
  border-radius: 9px;
  object-fit: cover;
}

/* 定义滚动动画 */
@keyframes scroll-left {
  0% {
    transform: translateX(0); /* 从最左边开始 */
  }
  100% {
    transform: translateX(-1728px); /* N*backWidth+(N+1)*gap */
  }
}
</style>
