<template>
     <div class="aiUs">
        <div class="image-text_1">
          <img class="image_8" referrerpolicy="no-referrer" src="@/assets/home/robot.png" />
          <div class="text-group_1 flex-col">
            <span class="text_8">免费人工智能大模型</span>
            <span class="text_9">FREE AI LARGE MODEL </span>
          </div>
        </div>
        <div class="container-new10">
          <div class="contentphoto">
            <div class="title-top">Oil & Gas Knowledge GPT</div>
            <div class="title-bottom">Here we provide the most professional oil and gas industry application konwledges.</div>
            <div class="photonew10">
              <div slot="reference" class="search">
                <i class="el-icon-search" @click="handleSearchClick"
                   style="margin-left:1.1vw;z-index: 999999999;font-size: 1.1vw;color:gray;font-weight: bold;height:7vh;display: flex;align-items: center"></i>
                <el-input v-model="keyTitle" style="border: none" @input="getSearch" placeholder="Ask questions or enter the oil and gas knowledge you want to learn about."
                          clearable="true"  @keyup.enter.native="handleSearchClick"></el-input>
                </div>
              <!-- 弹出框部分 -->
              <el-dialog :visible.sync="isDialogVisible" width="600px" class="purple-dialog">
                <template #title>
                  <div style="display: flex; align-items: center; justify-content: center">
                    <!-- 使用警告感叹号图标 -->
                    <i class="el-icon-warning" style="font-size: 3vw;  "></i>
                  </div>
                  <i @click="closeDialog" style="position: absolute; top: 5px; right: 5px; cursor: pointer;"></i>
                </template>
                <div class="dialog-content">
                  <p>You are about to leave the Geological Cloud Platform and go to the Oil and Gas Large Model.</p>
                  <p>Please click the link below or continue to visit:</p>
                  <a :href="dynamicUrl" target="_blank">{{ dynamicUrl }}</a>
                </div>
                <div slot="footer" class="dialog-footer" style="text-align: center;">
                  <el-button @click="redirectToDynamicUrl">Continue to visit</el-button>
                </div>
              </el-dialog>
                </div>

          </div>
          <div class="consult10">
            <div class="title-top">AI Large Model for Oil and Gas Geological Research and Development</div>
            <div class="title-bottom">You can experience various AI applications in the oil and gas industry, or upload your AI models to share and co - create with industry experts. </div>
            <div class="zong">
              <div class="photonew11" @click="eur">
                <img src="@/assets/home/33.png" class="imagenew">
                <div class="title">GPTs for Fracturing Scheme Optimization</div>
              </div>
              <div class=" photonew11 photonew12" @click="fibernew">
                <img src="@/assets/home/22.png" class="imagenew">
                <div class="title">GPTs for Optical Fiber Leak Point Interpretation</div>
              </div>

              <div class="photonew11 photonew13" @click="recog">
                <img src="@/assets/home/11.png" class="imagenew">
                <div class="title">GPTs for Intelligent Reservoir Geological Interpretation</div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>
<script>
export default {
    name:'ai',
data(){
    return{
        keyTitle: '',
         isDialogVisible: false, // 控制弹框的显示与隐藏
         dynamicUrl: '', // 存储动态拼接的链接
}
    },
methods:{
             // 点击搜索图标时，显示弹框
             handleSearchClick() {
        if (this.keyTitle.trim()) {
          this.dynamicUrl = `https://www.oilgasinfoai.com/AIAnswer?question=${this.keyTitle}`;
          this.isDialogVisible = true; // 显示弹框
        } else {
         this.$message.error('Please enter search content');
        }
      },
          // 跳转到动态链接
    redirectToDynamicUrl() {
      window.location.href = this.dynamicUrl; // 跳转到动态链接
    },
      // 关闭弹框
      closeDialog() {
        this.isDialogVisible = false;
      },
      eur(){
      this.$emit("eur",true)
    },
    fibernew(){
      this.$emit("fibernew",true)
    },
    recog(){
      this.$emit("recog",true)
    }
}
}
</script>

<style lang="scss" scoped>
  .image-text_1 {
    display: flex;
    margin-bottom: 3vh;
    .image_8 {
      width: 3.5vw;
      height: 3.5vw;
    }

    .text-group_1 {
      position: relative;
      width: fit-content;
      height: 4vw;
      margin-left: 0.5vw;
    }

    .text_8 {
      height:4vw;
      overflow-wrap: break-word;
      color: rgba(118, 118, 118, 0.2);
      font-size: 1.8vw;
      text-transform: uppercase;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 700;
      text-align: center;
      white-space: nowrap;
      line-height: 5vw;

    }

    .text_9 {

      position: absolute;
      left: 0;
      top: 0;
      height: 4vw;
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 1.8vw;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 600;
      text-align: center;
      white-space: nowrap;
    }
  }
  .title-top{
    font-size: 1.5vw;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #333333;
    text-align: center;
    font-style: normal;
    text-transform: none;
    white-space: nowrap;
  }
  .title-bottom{
    font-size: 1.2vw;
    margin-top: 2vh;
    line-height: 4vh;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #909399;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .aiUs{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 5vh 10vw 0 10vw;
    background-position: center;
    background-size: cover;
    background-color: white;

.container-new10{
  display: flex;
  width:100%;
  height: fit-content;
  margin-top:5vh;
}
.contentphoto{
  position: relative;
  display:inline-block;
  //box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 阴影效果 */
  background-image: url("info.png");
  background-position: center;
  background-size: cover;

  padding:5vh 2vw;
  width:35%;
  //height:fit-content;

}

.photonew10{
  width: 100%;
  background-size: cover; /* 背景图填充容器 */
  // height: 30vh;       /* 设置容器高度 */
transition: background-image 0.3s ease-out; /* 添加图片透明度的过渡效果 */
border-radius: 25px;
margin-top:3vh;
}
/* 弹框内容部分居中显示，并且有间距 */
.dialog-content {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.dialog-content p {
  margin: 10px 0;
  font-size: 16px;
  font-weight: 400;
  color:white;
}

.dialog-content a {
  display: inline-block;
  margin-top: 10px;
  // color: blue;
  color:orange;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 400;
}
// /* 自定义紫色背景 */
// .purple-dialog .el-dialog {
//   background-color: #6a4c9c !important; /* 紫色背景 */
//   color: white !important; /* 白色文本 */
//   border-radius: 15px !important; /* 添加圆角效果 */
// }

// /* 自定义遮罩背景颜色 */
// .purple-dialog .el-dialog__wrapper {
//   background-color: rgba(106, 76, 156, 0.8) !important; /* 设置弹框背景色 */
// }
.search {
  position: relative;
  z-index:3;
  margin-top: 30.5vh;
        display: flex;
        border-radius: 10px;
        // width: 40vw;;
        // height:7vh;
        background-color: #e0e1e5;
    

        ::v-deep .el-input {
          border-radius: 0;
          // height:7vh;
          // width: 40vw;
          //width:20vw;
          font-size: 15px;
          font-weight: 400;
        }
        ::v-deep .el-input__inner {
          border-radius: 0;
          height:7vh !important;
          //border: white 1px solid !important;
          background-color: transparent !important;
        }

      }
.click-experience {
    color: red; /* 设置“点击体验”部分的文字为红色 */
    font-weight: bold;
}

.consult10{
  position: relative;
  display:inline-block;
  background-color: #F5F5F5;
  padding: 5vh 1vw 2vh 1vw;
  // margin-bottom: 100px; /* 使用负的 margin 使元素向上移动 */
  //border: 1px solid #E1E1E1; /* 2px 宽度，实线，黑色边框 */
  //box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 阴影效果 */
  margin-left:2vw;
  width:100%;

  height: 100%;
}
.zong{
  display: flex;
  gap: 1vw; /* 设定这两个元素之间的间距 */
  height: fit-content;
  width:100%;
   margin-top:4vh;
}
.photonew11{
  //background-image: url("../assets/home/11.png");
  //background-size: contain;
  width: 100%;
  height: fit-content;
  //margin-left: 1vw;
  margin-bottom:1vh;
  cursor: pointer;
  position: relative; /* 确保伪元素可以定位 */
  .title{
    position: relative; /* 确保可以设置z-index */
    z-index: 2; /* 确保文字在蒙版之上 */
    transition: opacity 0.5s ease-out;
    opacity: 0;
    margin:15vh auto;
    padding: 2vh 1vw;
    width: fit-content;
    height: fit-content;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 1vw;
    color: #FFFFFF;
    text-align: center;
    font-style: normal;
    background: #3366F2;
    border-radius: 11px;
  }
}
.photonew11:hover{
  position: relative; /* 确保伪元素可以定位 */
  .title{
    opacity: 1;

  }
}
    .photonew11:hover::before {
      content: ""; /* 伪元素需要content属性 */
      position: absolute; /* 绝对定位 */
      top: 0;
      left: 0;
      width: 100%; /* 蒙版覆盖整个元素 */
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5); /* 蒙版颜色和透明度 */
      z-index: 1; /* 确保蒙版在背景之上 */
    }
.photonew11 img{
  width: 100%;
  height: 100%;
  // object-fit: cover;
   position: absolute;
  //top: 0;
  //left: 0;
  //opacity: 0;  /* 让 img 标签中的图片不可见 */
}

.photonew12{
  //background-image: url("../assets/home/22.png");
}


.photonew13{
  //background-image: url("../assets/home/33.png");
  //background-size: cover; /* 背景图填充容器 */
}

  .screenConsult {
    //z-index: 10;
    width:60vw;
    height: fit-content;
    border-radius: 9px;
    padding: 5vh 10vw;
    background: transparent;
    color: #15257b;
    // background-color:white;
    margin-top: -7.5vh;
    .title {
      //height: 5vh;
      opacity: 1;
      font-size: 2vw;
      color: #15257b;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 10vh;
      text-align: left;
    }

    .light {
      // margin:3vh auto 4vh auto;
      text-align: justify;
      width: 100%;
      height: fit-content;
      font-size: 1.2vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #15257b;
      line-height: 3.94437577255871vh;
    }
    .pro2 {
      height: fit-content;
      padding-top: 2vh;
      overflow-x: hidden;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 9px;
        padding: 1vw;
        opacity: 1;
        position: relative;
        background-color: white;
        height: fit-content;
        .back {
          width: 100%;
          margin:0 auto 0 0;
          height:fit-content;
          border-radius: 9px;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          width: 100%;
          height:fit-content;
          margin-top:2vh;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          padding: 1vh 0;
          justify-content: space-between;
        }

        .photo {
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          display: flex;
          height:fit-content;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          position: relative;
        }
        .photohover{
          position: absolute;
          bottom: 0;
          left: 0; /* 初始位置在容器左侧外面 */
          width: 0%; /* 初始宽度为0 */
          height: 2vh;
          background-color: #2f6dfd;
          transition: width 0.5s ease; /* 平滑过渡效果 */
        }
        //.photolist {
        //  font-size: 5vw;
        //  color: #e3e8ed;
        //  margin:auto 1vw 0 2vw;
        //  height: 100%;
        //  width: fit-content;
        //  font-weight: bold;
        //}

        .title1 {
          text-align: left;
          font-size: 1.2vw;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          width: 60vw;
          overflow: hidden;
          height: fit-content;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          opacity: 4;
          width: fit-content;
          height: fit-content;
          // text-align: justify;
          font-size:1vw;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          color: white;
          margin-top: 2vh;
          border-radius: 23px;
          padding: 1vh 1vw;
          background-color: #2168DB;
          //line-height: 35px;
        }
      }
      .grand1:hover{
        .photohover {
          width: 100%; /* 鼠标悬浮时宽度变为100% */
        }
      }

    }

  }
  }
</style>