<!-- src/components/mobile/ServiceSection.vue -->
<template>
    <div class="service-section">
        <div class="title">Our Services</div>
        <!-- <div class="titlenew">Integration of engineering geology, deep exploration of production potential, and support for enhancing oil and gas field development efficiency.</div> -->
        <div class="titlenew">Integrating Engineering Geology to Deeply Explore Production Potential.</div>
        <div class="learnMore" @click="upkefu()">
            Customize your exclusive service.
            </div>
        <div class="service-list">
            <div v-for="service in serviceList" :key="service.id" class="service-card"
                @click="goToService(service.link)">
                <div class="card-content">
                    <div class="image-container">
                        <img :src="service.image" :alt="service.title">
                        <div class="service-title">{{ service.title }}</div>
                    </div>
                    <div class="service-info">
                        <div class="sub-services">
                            <div v-for="subService in service.subServices" :key="subService.id" class="sub-service-item"
                                @click.stop="goToService(subService.link)">
                                <span class="arrow">→</span>
                                <span class="sub-title">{{ subService.title }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import geologyResearchImg from '../../../assets/home/services/geology-research.png'
import engineeringImg from '../../../assets/home/services/engineering.png'
import productionImg from '../../../assets/home/services/production.png'
import monitoringImg from '../../../assets/home/services/monitoring.png'
import operationImg from '../../../assets/home/services/一体化.jpg'
// import operationImg from '../../../assets/home/services/operation.png'

export default {
    name: 'ServiceSection',

    data() {
        return {

            serviceList: [
                {
                    id: 1,
                    title: "Oil Reservoir Geology Detailed Research Services",
                    image: geologyResearchImg,

                    link: "",
                    subServices: [
                        {
                            id: 11,
                            title: "Reservoir Geology Evaluation Services",
                            link: "/geoevaluation"
                        },
                        {
                            id: 12,
                            title: "Development Solution Design Services",
                            link: "/schemedesign"
                        },
                        {
                            id: 13,
                            title: "Specialized Oil and Gas Reservoir Research Services",
                            link: "/featuretech"
                        }
                    ]
                },
                {
                    id: 2,
                    title: "Oil Reservoir Geology Detailed Research Services",//地质工程相结合的技术服务
                    image: engineeringImg,

                    link: "",
                    subServices: [
                        {
                            id: 21,
                            title: "The Intelligent Engineering Iterative Optimization GPTs",
                            link: "intelligentengineering"
                        },
                        {
                            id: 22,
                            title: "A Series of Techniques for Tapping The Potential of Old Wells Combined with Geological Engineering",
                            link: "/potentialtapping"
                        }
                    ]
                },
                {
                    id: 3,
                    title: "Technological services for enhancing the production of oil and gas reservoirs",
                    image: productionImg,

                    link: "",
                    subServices: [
                        {
                            id: 31,
                            title: "Integrated Production Technology Services and Supporting Technologies",
                            link: "/integratedstimulation"
                        },
                        {
                            id: 32,
                            title: "Characteristic Increasing Product",
                            link: "/featuredproducts"
                        }
                    ]
                },
                {
                    id: 4,
                    title: "Comprehensive monitoring service for oil and gas field development",//油气田开发综合监测服务
                    image: monitoringImg,

                    link: "",
                    subServices: [
                        {
                            id: 41,
                            title: "Distributed Optical Fiber Real-time Monitoring Technology",
                            link: "/FiberOptic"
                        },
                        {
                            id: 42,
                            title: "Digital Rock Debris",//数字油气田技术
                            link: "/digital"
                        },
                        {
                            id: 43,
                            title: "Reservoir Logging Technique",
                            link: "/logging"
                        }
                    ]
                },
                {
                    id: 5,
                    // title: "Integrated Production Enhancement Turnkey Services for Oil and Gas Fields",
                    image: operationImg,
                    link: "/increase",
                    subServices: []
                }
            ]
        }
    },

    methods: {
      upkefu(){
      window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3589007934247681&webPageTitle=在线咨询")
    },
        
        goToService(link) {
            this.$router.push(link);
        }
    }
}
</script>

<style lang="scss" scoped>
.service-section {
    padding: 25px 10px;
    background: #FFF;
    font-family: Sarial, sans-serif, "Microsoft Yahei" !important;
    .title {
        font-size: 22px;
        font-weight: bold;
        color: #333;
        text-align: left;
        margin: 20px 0;
    }
    .titlenew{
    color: #333;
    font-size: 4vw;
    padding: 0 0 1vh 0;
}
.learnMore{
    color:#0B71D6;
    font-size: 16px;
    padding: 1vh 0 2vh 0;
    }

    .service-list {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .service-card {
            background: #FFFFFF;
            border-radius: 8px;
            overflow: hidden;

            .card-content {
                .image-container {
                    width: 100%;
                    height: auto;
                    // border-radius: 15px;
                    overflow: hidden;
                    position: relative;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .service-title {
                        position: absolute;
                        top: 20px;
                        left: 15px;
                        color: #ffffff;
                        font-size: 16px;
                        font-weight: 500;
                    }
                }

                .service-info {
                    padding: 15px;

                    .sub-services {
                        display: flex;
                        flex-direction: column;
                        gap: 12px;

                        .sub-service-item {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            // color: #666;

                            .arrow {
                                color: #084E8A;
                                font-size: 16px;
                            }

                            .sub-title {
                                
                                color: #084E8A;
                                font-size: 14px;
                                line-height: 18px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>