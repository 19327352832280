<template>
    <footer class="mobile-footer">
        <!-- Logo -->
        <div class="logo-section">
            <img :src="logo.src" :alt="logo.alt">
        </div>

        <!-- 生态平台 -->
        <div class="footer-section">
            <h3 class="section-title">{{ ecoPlatform.title }}</h3>
            <div class="links">
                <a v-for="link in ecoPlatform.links" :key="link.name" :href="link.url">{{ link.name }}</a>
            </div>
        </div>

        <!-- 地质工程云平台 -->
        <!-- <div class="footer-section">
            <h3 class="section-title">{{ geoPlatform.title }}</h3>
            <div class="links">
                <a v-for="link in geoPlatform.links" :key="link.name" :href="link.url">{{ link.name }}</a>
            </div>
        </div> -->

        <!-- 服务反馈 -->
        <div class="footer-section">
            <h3 class="section-title">{{ feedback.title }}</h3>
            <div class="links">
                <a v-for="link in feedback.links" :key="link.name" :href="link.url">{{ link.name }}</a>
            </div>
        </div>

        <!-- 关注我们 -->
        <div class="footer-section follow-us">
            <h3 class="section-title">{{ followUs.title }}</h3>
            <div class="follow-buttons">
                <!-- ANTON 安东按钮 -->
                <a href="https://www.antonoil.com" class="follow-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="14" viewBox="0 0 100 14" fill="none">
                        <g clip-path="url(#clip0_116_523)">
                            <path
                                d="M69.8726 4.235H72.6421V2.60969H80.2954V4.235H83.067V2.60969V0.807187H77.9601V0H74.9775V0.807187H69.8726V2.60969V4.235ZM83.7082 6.94094V5.03344H76.7678L77.7409 3.67281L75.211 3.22875L73.9143 5.03344H69.2293V6.94094H72.5397L70.9583 9.13937L70.9173 9.17219C72.2038 9.62062 73.5353 10.1084 74.8197 10.5984C73.5537 11.1497 71.9313 11.5828 69.8685 11.7359V13.7987C70.6182 13.7419 74.3465 13.5428 77.4971 11.6375C80.6559 12.8909 83.0629 13.9234 83.0629 13.9234V11.7731C83.0629 11.7731 81.5859 11.1344 79.3244 10.2506C80.2257 9.38 80.9837 8.295 81.4856 6.93875H83.7041L83.7082 6.94094ZM76.9645 9.34937C76.139 9.04313 75.2561 8.72375 74.3445 8.40875L75.3974 6.94094H79.011C78.7897 7.40687 78.21 8.38906 76.9645 9.34937Z"
                                fill="white" />
                            <path
                                d="M100 2.61187V0.807187H94.0921V0H91.4311V0.807187H85.5211V2.61187H91.4311V3.24844H86.6622V9.72344H89.4973C88.344 10.6094 87.0493 11.2241 85.5806 11.6441L85.5211 11.6616V13.8272L85.6297 13.8009C87.6475 13.2912 89.7288 12.1122 91.429 10.6006V13.8162H94.0921V10.6006C95.7924 12.1122 97.8757 13.2912 99.8935 13.8009L100 13.8272V11.6616L99.9406 11.6441C98.4718 11.2241 97.1813 10.6094 96.0238 9.72344H98.861V3.24844H94.0921V2.61187H100ZM89.1183 4.93281H91.429V5.79469H89.1183V4.93281ZM89.1183 7.17937H91.429V8.04125H89.1183V7.17937ZM96.4049 8.04125H94.0921V7.17937H96.4049V8.04125ZM96.4049 4.93281V5.79469H94.0921V4.93281H96.4049Z"
                                fill="white" />
                            <path
                                d="M6.37906 11.815H4.29163L3.71804 14.0003H0L4.42888 0.78125H8.40093L12.836 14.0003H9.02573L8.44395 11.815H6.37906ZM6.37906 4.19812L5.07825 8.95813H7.67781L6.37906 4.19812Z"
                                fill="white" />
                            <path
                                d="M13.7946 0.780273H17.2013L21.6363 8.07996V0.780273H25.0778V13.9993H21.6363L17.2239 6.73684V13.9993H13.7946V0.780273Z"
                                fill="white" />
                            <path d="M26.4258 0.780273H37.502V4.02871H33.7922V13.9993H30.1438V4.02871H26.4258V0.780273Z"
                                fill="white" />
                            <path
                                d="M43.6066 0.780508C45.4933 0.780508 46.9498 1.35363 47.972 2.4977C48.9983 3.64613 49.5104 5.23645 49.5104 7.27738C49.5104 8.77363 49.2871 9.99863 48.8385 10.9568C48.3858 11.9149 47.7385 12.6586 46.8924 13.1946C46.4684 13.4571 45.9665 13.6561 45.3888 13.7918C44.8132 13.9296 44.2089 13.9974 43.58 13.9974C43.1047 13.9974 42.5701 13.9339 41.9658 13.8114C41.3635 13.6868 40.8841 13.5118 40.5298 13.2886C40.0852 13.013 39.694 12.7024 39.356 12.3546C39.018 12.0046 38.723 11.5911 38.473 11.1033C37.9507 10.1189 37.6926 8.88301 37.6926 7.38894C37.6926 5.31082 38.2129 3.68988 39.2515 2.52395C40.2901 1.36238 41.7343 0.77832 43.5841 0.77832H43.6128L43.6066 0.780508ZM43.6066 11.0311C44.3625 11.0311 44.9484 10.7577 45.3642 10.2108C45.778 9.66394 45.9829 8.66644 45.9829 7.22269C45.9829 6.60145 45.9337 6.07426 45.8313 5.64551C45.7268 5.21676 45.565 4.86457 45.3376 4.59113C45.1594 4.36801 44.9381 4.17332 44.678 4.00926C44.3093 3.7752 43.9528 3.65488 43.6066 3.65488C43.2604 3.65488 42.9142 3.77957 42.5209 4.02895C42.2177 4.21707 41.9965 4.40957 41.8551 4.60863C41.429 5.17082 41.216 6.10488 41.216 7.41082C41.216 8.71676 41.4249 9.60488 41.8469 10.1911C42.2833 10.7511 42.8691 11.0311 43.6087 11.0311H43.6066Z"
                                fill="white" />
                            <path
                                d="M50.4466 0.780273H53.8553L58.2883 8.07996V0.780273H61.7297V13.9993H58.2883L53.8758 6.73684V13.9993H50.4466V0.780273Z"
                                fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_116_523">
                                <rect width="100" height="14" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </a>
                <!-- ANTON ONLINE按钮 -->
                <a href="https://mall.antonoil.com" class="follow-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="28" viewBox="0 0 60 28" fill="none">
                        <path
                            d="M6.49742 10.8542H4.47939L3.92517 12.8703H0.333313L4.61054 0.666016H8.44777L12.7335 12.8703H9.05276L8.49008 10.8542H6.49742ZM6.49742 3.82176L5.24091 8.2165H7.75394L6.49742 3.82176Z"
                            fill="white" />
                        <path
                            d="M13.66 0.666016H16.9514L21.2371 7.4049V0.666016H24.5625V12.8703H21.2371L16.9726 6.16591V12.8703H13.66V0.666016Z"
                            fill="white" />
                        <path d="M25.8655 0.666016H36.5649V3.66635H32.9816V12.8703H29.4574V3.66635H25.8655V0.666016Z"
                            fill="white" />
                        <path
                            d="M42.4624 0.666016C44.2859 0.666016 45.6905 1.19701 46.6804 2.25036C47.6704 3.30804 48.1654 4.78014 48.1654 6.66237C48.1654 8.04382 47.9496 9.17488 47.5139 10.0599C47.0781 10.9449 46.452 11.6313 45.6312 12.1277C45.2209 12.3695 44.7386 12.5551 44.1801 12.6803C43.6217 12.8055 43.042 12.8703 42.4328 12.8703C41.9759 12.8703 41.4555 12.8141 40.8717 12.6976C40.2879 12.581 39.8267 12.4213 39.484 12.2141C39.0525 11.9594 38.676 11.6744 38.3502 11.3507C38.0245 11.0269 37.741 10.647 37.4956 10.1937C36.9922 9.28712 36.7426 8.14311 36.7426 6.76597C36.7426 4.8449 37.246 3.35121 38.2487 2.27627C39.2514 1.20564 40.6475 0.666016 42.4328 0.666016H42.4624ZM42.4624 10.1289C43.1944 10.1289 43.757 9.87855 44.1589 9.37346C44.5566 8.86837 44.7555 7.94884 44.7555 6.61488C44.7555 6.04071 44.7089 5.55721 44.6074 5.16004C44.5059 4.76287 44.3493 4.4391 44.1293 4.18871C43.9559 3.98149 43.7443 3.80449 43.4905 3.6534C43.1351 3.43755 42.7882 3.3253 42.4582 3.3253C42.1282 3.3253 41.7898 3.44186 41.409 3.67067C41.1171 3.84335 40.9013 4.02035 40.7659 4.20598C40.3556 4.72402 40.1483 5.58743 40.1483 6.79188C40.1483 7.99633 40.3513 8.81656 40.7575 9.36051C41.1805 9.87855 41.7475 10.1333 42.4582 10.1333L42.4624 10.1289Z"
                            fill="white" />
                        <path
                            d="M49.0709 0.666016H52.3624L56.6438 7.4049V0.666016H59.9691V12.8703H56.6438L52.3793 6.16591V12.8703H49.0667V0.666016H49.0709Z"
                            fill="white" />
                        <path
                            d="M6.07012 15.332C7.89778 15.332 9.30659 15.863 10.2966 16.9207C11.2908 17.9827 11.7858 19.4548 11.7858 21.3413C11.7858 22.7228 11.57 23.8582 11.1343 24.7475C10.6985 25.6325 10.0681 26.3232 9.24737 26.8196C8.83699 27.0614 8.35046 27.247 7.79201 27.3722C7.23356 27.4974 6.64972 27.5622 6.0405 27.5622C5.57936 27.5622 5.06322 27.506 4.47515 27.3895C3.89132 27.2729 3.42594 27.1132 3.08326 26.906C2.65173 26.6513 2.2752 26.362 1.9452 26.0426C1.61521 25.7188 1.33175 25.3346 1.0906 24.8856C0.587151 23.9747 0.333313 22.8307 0.333313 21.4493C0.333313 19.5239 0.836761 18.0259 1.84366 16.9466C2.85057 15.8717 4.2467 15.332 6.03628 15.332H6.06589H6.07012ZM6.07012 24.8165C6.80203 24.8165 7.36894 24.5662 7.77085 24.0567C8.17277 23.5517 8.37162 22.6278 8.37162 21.2938C8.37162 20.7197 8.32508 20.2319 8.22354 19.8347C8.122 19.4375 7.96547 19.1137 7.74547 18.859C7.57201 18.6518 7.36048 18.4748 7.10664 18.3194C6.75126 18.1036 6.40435 17.9913 6.07012 17.9913C5.7359 17.9913 5.39744 18.1079 5.01668 18.3367C4.72476 18.5094 4.509 18.6907 4.37361 18.872C3.95901 19.39 3.75594 20.2534 3.75594 21.4665C3.75594 22.6796 3.95901 23.4955 4.36516 24.0395C4.78823 24.5575 5.35514 24.8165 6.07012 24.8165Z"
                            fill="white" />
                        <path
                            d="M12.6954 15.332H15.9953L20.2895 22.0882V15.332H23.6232V27.5622H20.2895L16.0165 20.8449V27.5622H12.6954V15.332Z"
                            fill="white" />
                        <path
                            d="M38.0372 15.332H41.3371L45.6313 22.0882V15.332H48.965V27.5622H45.6313L41.3583 20.8449V27.5622H38.0372V15.332Z"
                            fill="white" />
                        <path d="M24.5286 15.332H27.8455V24.5834H33.0958V27.5622H24.5244V15.332H24.5286Z"
                            fill="white" />
                        <path d="M34.0054 15.332H37.3222V27.5622H34.0054V15.332Z" fill="white" />
                        <path
                            d="M50.0693 15.3438H59.3556V17.7958H53.3607V19.95H59.2795V22.6136H53.3607V25.0484H59.601V27.5005H50.0693V15.3438Z"
                            fill="white" />
                    </svg>
                </a>
            </div>
        </div>
    </footer>
</template>

<script>
import logoImage from '../assets/mobileFooter/logo.png'
import antonImage from '../assets/mobileFooter/anton.png'
import antonOnlineImage from '../assets/mobileFooter/anton-online.png'

export default {
    name: 'MobileFooter',
    data() {
        return {
            logo: {
                src: logoImage,
                alt: 'GPT'
            },
            ecoPlatform: {
                title: 'Ecosystem Platform',
                links: [
                    { name: 'Andong Online Service', url: 'https://mall.antonoil.com/ ' },
                    { name: 'Oil & Gas Link', url: 'https://www.oilgasgpts.com/  ' },
                    { name: 'Antong Oil & Gas Mal', url: 'https://www.atoilgas.com/ ' },
                ]
            },
            geoPlatform: {
                title: 'Geological Engineering Cloud Platform',
                links: [
                    { name: 'Global Online Expert Support Service', url: '#' },
                    { name: 'Engineering Geological GPTS', url: '#' },
                    { name: 'Oil and Gas Field Development Series Technical Services', url: '#' }
                ]
            },
            feedback: {
                title: 'Service Feedback',
                links: [
                    { name: 'Operation Project Evaluation Feedback', url: '/Feedback' },
                    { name: 'Product Evaluation Feedback', url: '/productFeedback' },
                    { name: 'User Feedback for Website', url: '/netFeedback' }
                ]
            },
            followUs: {
                // title: 'Follow us',
                buttons: [
                    {
                        name: 'ANTON 安东',
                        image: antonImage,
                        url: 'https://www.antonoil.com'
                    },
                    {
                        name: 'ANTON ONLINE',
                        image: antonOnlineImage,
                        url: 'https://mall.antonoil.com'
                    }
                ]
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.mobile-footer {
    background: #123456;
    padding: 30px 30px;
    color: #FFFFFF;
    font-family: Sarial, sans-serif, "Microsoft Yahei" !important;
    .logo-section {
        margin-bottom: 40px;

        img {
            height: 30px;
            width: auto;
        }
    }

    .footer-section {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }

        .section-title {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 15px;
            line-height: 26px;
        }

        .links {
            display: flex;
            flex-direction: column;
            gap: 12px;

            a {
                color: #E1F0FF;
                text-decoration: none;
                font-size: 14px;
                line-height: 26px;
                &:hover {
                    opacity: 1;
                }
            }
        }

        &.follow-us {
            .follow-buttons {
                display: flex;
                gap: 15px;

                .follow-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 8px 20px;
                    border-radius: 20px;
                    border: 1px solid #FFF;
                    color: #FFF;
                    text-decoration: none;
                    min-width: 120px;
                    height: 36px;

                    svg {
                        height: 14px;
                        width: auto;
                    }

                    &:last-child {
                        svg {
                            height: 28px;
                            width: auto;
                        }
                    }

                    &:hover {
                        background: rgba(255, 255, 255, 0.1);
                    }
                }
            }
        }
    }
}
</style>