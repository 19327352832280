<script >

export default {
  data() {
    return {
      showTooltip:false,
    }
  },
  computed:{
    // isPC() {
    //   let creenWidth=window.innerWidth
    //   console.log("this.creenWidth-------",creenWidth)
    //   return creenWidth>1000;
    // },
   
  },
  mounted() {

    // let creenWidth=window.innerWidth
    // console.log("-------------creenwidth",creenWidth)
    // // if(creenWidth<1000){
    // //
    // // }
  },
  methods:{
    lingying(){
      window.open("https://www.linkedin.com/company/104444080/admin/dashboard/")
    },
    toggleTooltip() {
      this.showTooltip = !this.showTooltip; // 切换二维码显示与隐藏
    },
    uptop(){
      console.log("zhiding11",this.isPC)
      this.$emit("uptop")
    },
    upkefu(){
      window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3589007934247681&webPageTitle=在线咨询")
    },
    showNet(){
      window.open("/netFeedback")
    },
    showProduct(){
      console.log("this.isPC",this.isPC)
      if(this.isPC){
        this.$emit("showProduct", true)
      }else{
        this.$emit("showProduct", false)
      }
      
    },
    hideNet() {
      localStorage.setItem("skip", 0)
      this.dialogNet = false;
    },
  }
}
</script>

<template>
  <div class="mobile-style">
    <!-- <div class="uptop"  @click="uptop"></div> -->
     <!-- <div class="photo1" style="background-color: #084e8a;border-top-left-radius: 20px;">
      <img src="@/assets/Frame.png" style="width: 80%;height: 80%;padding:  0 0.6vh ;">
      <span style="color: white;font-weight: 400;font-size: 12px;padding:  0 1vh 1vh 1vh;">Consult</span>
     </div> -->
     <!-- <div style="padding: 0.5vh 0 0 ;background-color: #084e8a;border-top-left-radius: 20px;"  @click="upkefu"> -->
      <!-- 原本的设计 -->
      <!-- <div style="padding: 0.5vh 0 0 ;" @click="upkefu">
      <div class="upkefu" ></div>
      <div class="title1" style="font-weight: 400;"> Consult</div>
    </div> -->
    <div class="mobile1">
    <div style="padding: 1vh 0;">
        <div class="wechaticon" >
          <img src="@/assets/home/wxattention.jpg" class="wechat-icon"/>
          <div class="title1" style="color: black" > Scan QR Code to Follow</div>
        </div>
      </div>
      </div>
      <div>
      <!--      <div class="uptop"  @click="uptop"></div>-->
      <div class="mobile2" style="padding: 5px ;" @click="upkefu">
        <div class="upkefu" ></div>
        <div class="title1" style="font-weight: 400;font-size: 10px;line-height: 12px"> Online<br>Consult</div>
      </div>
      <div class="mobile3" style="padding: 5px;" @click="showProduct">
        <div class="upkefu1"></div>
        <div class="title1" style="font-weight: 400;font-size: 10px;line-height: 12px" > Services<br>Feedback</div>
        </div>
      </div>
    </div>
          <!-- <div style="padding:  0 0 1vh;" @click="showProduct">
      <div class="upkefu2"></div>
      <div class="title1" style="font-weight: 400;"  > Feedback</div>
    </div> -->
    <!-- <div style="padding:  0 0 1vh;"> -->
      <!-- <div class="wechaticon" @mouseenter="showTooltip=true"
         @mouseleave="showTooltip=false"> -->
         <!-- <div class="wechaticon" @click="toggleTooltip" > -->
          <!-- <div class="wechaticon" @click="lingying()" >
    <img src="../assets/new/领英.png" class="wechat-icon"/>
    <div class="title2" style="font-weight: 400;"> Followus</div>
    </div>
    <div class="tooltip"
         v-if="showTooltip">
      <img src="../assets/new/lingying.png"/>
    </div>
    </div>
  </div> -->
</template>

<style scoped lang="scss">
.pc-style {
  z-index: 99;
  cursor: pointer;
  position: fixed;
  top: 40%;
  right: 0;
  padding: 5px;
  width: 3vw;
  height: 27vh;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  // background-color: rgb(50, 119, 220);
  background-color: rgba(8,78,138,1);
  //border-radius: 50%;
  //box-shadow: 5px 0 14px 0px #D4E4FF;

  .uptop {
    width: 2vw;
    height: 2vw;
    margin: 1vh auto;
    background-image: url("../assets/up.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }

  .upkefu {
    margin: 0.5vh auto;
    width: 2vw;
    height: 2vw;
    background-image: url("../assets/kefu2.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .upkefu2 {
    margin: 0.5vh auto;
    width: 2vw;
    height: 2vw;
    background-image: url("../assets/Frame (1).png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .upkefu3 {
    margin: 0.5vh auto;
    width: 2vw;
    height: 2vw;
    background-image: url("../assets/关注1.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .title1{
    font-size: 0.6vw;text-align: center;margin-top: 0.5vh;color: white;
    // word-break: break-all;
  }
  
  .wechaticon {
  height:3vh;
  width: 2.5vw;
  // display: flex;
  justify-content: center;
  text-align: center; 
  margin:0 auto;
  z-index: 99999
    // background-color: #f0f0f0;
    // transition: background-color 0.3s; /* 平滑过渡效果 */
  }
  .title2{
    font-size: 0.6vw;text-align: center;margin-top: 0.5vh;color: white;
    // word-break: break-all;
  }
  .wechat-icon {
    object-fit: contain;
    width: 25px;   /* 设置图片宽度 */
    height: 25px;  /* 设置图片高度 */
}
 .tooltip {
    /* 当showTooltip为true时显示提示框 */
    opacity: 1;
    width: 15vh;
    height: 15vh;
    position: fixed;
    right: 3.5vw;
    top: 55vh;
  }
  .feedback {
    padding: 0.5vh 0;
    margin: 1vh auto;
    font-size: 0.8vw;
    text-align: center;
    color: white;
  }

}

.mobile-style{
  // cursor: pointer;
  // position: fixed;
  // top:43%;
  // z-index: 98 ;
  // right:0;
  // width: 60px;
  // height:fit-content;
  // border-top-left-radius: 9px;
  // border-bottom-left-radius: 9px;
  // background-color: rgba(8,78,138,1);
  .mobile1{
    z-index: 9999  ;
    position: fixed;
    top:17vh;
    right:0;
    width: 20vw;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    background-color: white;
  }
  .mobile2{
    z-index: 9999  ;
    position: fixed;
    bottom:27vh;
    right:0;
    background-color: #121d71;
    display: flex;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    display: flex;
    align-content: center;
  }
  .mobile3{
    z-index: 9999  ;
    position: fixed;
    bottom:18vh;
    right:0;
    background-color: #121d71;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    display: flex;
    align-content: center;
  }
  .uptop{
    width:25px;
    height:25px;
    margin:5px auto;
    background-image: url("../assets/up.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .upkefu{
    margin:0 auto;
    width:20px;
    height:20px;
    background-image: url("../assets/Frame.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .upkefu1{
    margin:0 auto;
    width: 25px;
    height: 25px;
    background-image: url("../assets/Frame (1).png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .upkefu2{
    margin:0.2vh auto;
    width:9vw;
    height:9vw;
    background-image: url("../assets/Frame (1).png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .upkefu3 {
    margin: 0.5vh auto;
    width: 9vw;
    height: 9vw;
    background-image: url("../assets/关注1.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .title1{
    font-size: 7px;text-align: center;margin:auto 0 auto 0;color: white;
    display: flex;
    height: fit-content;
    align-content: center;
    // word-break: break-all;
  }
  // .wechaticon {
  //   width: 200px;
  //   height: 50px;
  //   background-color: #f0f0f0;
  //   transition: background-color 0.3s; /* 平滑过渡效果 */
  // }
  // .wechaticon.show-tooltip .tooltip {
  //   /* 当showTooltip为true时显示提示框 */
  //   opacity: 1;
  // }

  // .wechaticon:hover {
  //   background-color: #3498db; /* 鼠标悬停时的背景颜色 */
  // }
  .wechaticon {
    // height:9vh;
  width:14vw;
  // display: flex;
  justify-content: center;
  text-align: center; 
  margin:0 auto;
  z-index: 99999
  }
  .wechat-icon {
    object-fit: contain;
    width: 35px;   /* 设置图片宽度 */
    height: 35px;  /* 设置图片高度 */
}
.title2{
    font-size: 11px;text-align: center;margin-top: 0.5vh;color: white;margin-left: -1vw;
    // word-break: break-all;
  }
 .tooltip {
    /* 当showTooltip为true时显示提示框 */
    // opacity: 1;
    opacity: 1;
    width: 8vh;
    height: 8vh;
    position: fixed;
    right: 17vw;
    top: 59vh;
  }

  .feedback{
    cursor: pointer;
    width: fit-content;
    height:fit-content;
    color: white;
    font-size: 8px;
    margin:0 auto;
    text-align: center;
    //padding: 10px 0;
    display: flex;
    justify-content: center;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    //border: 5px solid #1295d9;
  }
}
</style>
